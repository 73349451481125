import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import Header from "../components/Header";
import "./Login.css";
import { Navigate } from 'react-router-dom';
import logo2 from "../assets/logo2.png";
import { AuthContext } from "../components/AuthContext";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const {isLoggedIn, setIsLoggedIn} = useContext(AuthContext);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://fact-finder.herokuapp.com/api/login', {
        email,
        password,
      });
      if (response.data.user) {
        localStorage.setItem('token', response.data.token); // store the token in localStorage
        setIsLoggedIn(true);
      } else {
        setErrorMessage('Invalid email or password');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = () => {
    console.log('Logout clicked');
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };
  

  if (isLoggedIn) {
    return <Navigate to='/factfinder' />;
  }

  return (
    <>
      <Header handleLogout={handleLogout} /> 
      <div className="login-page">
      <div className="main">
        <div className="login">
          <form className="loginform" onSubmit={handleSubmit}>
            <label htmlFor="chk" aria-hidden="true">
            <img src={logo2} alt="Logo" className="header__logo" />
              <h1>Login</h1>
            </label>
            <input
              className="loginemail"
              value={email}
              onChange={handleEmailChange}
              type="email"
              name="email"
              placeholder="Email"
              required
            />
            <input
              className="loginpassword"
              value={password}
              onChange={handlePasswordChange}
              type="password"
              name="pswd"
              placeholder="Password"
              required
            />
            <button className="loginbutton">Login</button>
          </form>
          {errorMessage && <p>{errorMessage}</p>}
        </div>
      </div>
      </div>
    </>
    
  );
}

export default Login;
