import React, { useState, useCallback, useEffect, useContext, useLayoutEffect } from "react";
import ResizableTextarea from "../components/ResizableTextarea";
import { useParams, useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import { ReactComponent as InfoIcon } from "../assets/info.svg";
import { AuthContext } from "../components/AuthContext";
import Header from "../components/Header";
import Popup from "../components/Popup";
import Sidebar from "../components/Sidebar/Sidebar";

function Update() {
  const { isLoggedIn } = useContext(AuthContext);
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);

  // We assume that id is stored in the factData
  const versionData = location.state ? location.state.data : {}; // get the fact data from the search result
  const id = versionData._id; // get the id from factData

  //Get the Version ID from the URL
  const { versionId } = useParams();
  const [versionDataUpdated, setVersionDataUpdated] = useState(versionData);

  //Not sure about the below code - if its needed or not

  useEffect(() => {
    const fetchData = async () => {
     // console.log(`Fetching data from:https://fact-finder.herokuapp.com/api/factversions/version/${versionId}`);
      try {
        const response = await fetch(
          `https://fact-finder.herokuapp.com/api/factversions/version/${versionId}`
        );
        const data = await response.json();

       // console.log("Fetched data: ", data); // Added console log here

        // update the state with the fetched data
        setVersionDataUpdated(data);
        setShowMortgageQuestions(data.showMortgageQuestions);
        setShowRentQuestions(data.showRentQuestions);
        setShowLivingWithFamilyQuestions(data.showLivingWithFamilyQuestions);
      } catch (err) {
        console.error("Failed to fetch factData:", err);
      }
    };

    fetchData();
  }, [versionId]);
  

  const [caseId, setCaseId] = useState("");
  const [lookingToCover, setLookingToCover] = useState("");
  const [lookingToCoverPartner, setLookingToCoverPartner] = useState("");
  const [regulatoryScriptRead, setRegulatoryScriptRead] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const convertToYYYYMMDD = (dateString) => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  //Stop Mouse Scroll for Number Fields
  useEffect(() => {
    const preventScroll = (e) => {
      e.preventDefault();
    };

    const inputs = document.querySelectorAll('input[type="number"]');
    inputs.forEach((input) => {
      input.addEventListener("wheel", preventScroll, { passive: false });
    });

    // Clean up function
    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("wheel", preventScroll);
      });
    };
  }, []);

  const [factFindDate, setFactFindDate] = useState(
    convertToYYYYMMDD(new Date(""))
  );

  const [updateFactFindDate, setUpdateFactFindDate] = useState(
    versionData.updateFactFindDate
      ? convertToYYYYMMDD(new Date(""))
      : ""
  );

  const [client1Title, setClient1Title] = useState("");
  const [client1FirstName, setClient1FirstName] = useState("");
  const [client1Surname, setClient1Surname] = useState("");
  const [client1DateOfBirth, setClient1DateOfBirth] = useState(
    convertToYYYYMMDD(new Date(""))
  );

  const [client1Age, setClient1Age] = useState("");
  const [client1SmokerStatus, setClient1SmokerStatus] = useState("");
  const [client1LastNicotineUse, setClient1LastNicotineUse] = useState("");

  const [client1CigarettesPerDay, setClient1CigarettesPerDay] = useState("");
  const [client1MaritalStatus, setClient1MaritalStatus] = useState("");
  const [hasChildren, setHasChildren] = useState("");
  const [numberOfChildren, setNumberOfChildren] = useState("");
  const [childrenAges, setChildrenAges] = useState("");

  const [
    childrenAgeOfFinancialIndependence,
    setChildrenAgeOfFinancialIndependence,
  ] = useState(""); //new

  //- Client 2 Section 1 -//
  const [client2FirstName, setClient2FirstName] = useState("");
  const [client2Surname, setClient2Surname] = useState("");
  const [client2DateOfBirth, setClient2DateOfBirth] = useState(
    convertToYYYYMMDD(new Date(""))
  );
  const [client2Age, setClient2Age] = useState("");
  const [client2Title, setClient2Title] = useState("");
  const [client2SmokerStatus, setClient2SmokerStatus] = useState("");
  const [client2LastNicotineUse, setClient2LastNicotineUse] = useState("");

  const [client2CigarettesPerDay, setClient2CigarettesPerDay] = useState("");

  //Client 1 - Section 2 //
  const [client1EmploymentStatus, setClient1EmploymentStatus] = useState("");

  const [client1CompanyType, setClient1CompanyType] = useState("");
  const [client1YearsEstablished, setClient1YearsEstablished] = useState("");
  const [client1GrossAnnualIncomePAYE, setClient1GrossAnnualIncomePAYE] = useState("");
  const [
    client1GrossAnnualIncomeDividends,
    setClient1GrossAnnualIncomeDividends,
  ] = useState("");

  const [client1GrossAnnualIncome, setClient1GrossAnnualIncome] = useState("");
  const [client1MonthlyBenefits, setClient1MonthlyBenefits] = useState("");
  const [client1NetMonthlyIncome, setClient1NetMonthlyIncome] = useState("");
  const [client1JobTitle, setClient1JobTitle] = useState("");
  const [client1SickPay, setClient1SickPay] = useState("");
  const [client1FullSickPayMonths, setClient1FullSickPayMonths] = useState("");
  const [client1HalfSickPayMonths, setClient1HalfSickPayMonths] = useState("");
  const [client1DeathInService, setClient1DeathInService] = useState("");
  const [client1YearsOfSalary, setClient1YearsOfSalary] = useState("");
  const [client1RetirementAge, setClient1RetirementAge] = useState("");
  const [client1OtherIncome, setClient1OtherIncome] = useState("");
  const [client1NotesSection2, setClient1NotesSection2] = useState("");

  const [additionalIncomes, setAdditionalIncomes] = useState("");
  
  const [client1AdditionalIncomes, setClient1AdditionalIncomes] = useState([{ amount: "", source: "" }]);


  

  //Client 2 - Section 2 //
  const [client2EmploymentStatus, setClient2EmploymentStatus] = useState("");

  const [client2CompanyType, setClient2CompanyType] = useState("");
  const [client2YearsEstablished, setClient2YearsEstablished] = useState("");
  const [client2GrossAnnualIncomePAYE, setClient2GrossAnnualIncomePAYE] = useState("");
  const [
    client2GrossAnnualIncomeDividends,
    setClient2GrossAnnualIncomeDividends,
  ] = useState("");

  const [client2GrossAnnualIncome, setClient2GrossAnnualIncome] = useState("");
  const [client2MonthlyBenefits, setClient2MonthlyBenefits] = useState("");
  const [client2NetMonthlyIncome, setClient2NetMonthlyIncome] = useState("");
  const [client2JobTitle, setClient2JobTitle] = useState("");
  const [client2SickPay, setClient2SickPay] = useState("");
  const [client2FullSickPayMonths, setClient2FullSickPayMonths] = useState("");
  const [client2HalfSickPayMonths, setClient2HalfSickPayMonths] = useState("");
  const [client2DeathInService, setClient2DeathInService] = useState("");
  const [client2YearsOfSalary, setClient2YearsOfSalary] = useState("");
  const [client2RetirementAge, setClient2RetirementAge] = useState("");
  const [client2OtherIncome, setClient2OtherIncome] = useState("");
  const [client2NotesSection2, setClient2NotesSection2] = useState("");
  const [client2AdditionalIncomes, setClient2AdditionalIncomes] = useState([
    {
      amount: "",
      source: "",
    },
  ]);

  // SECTION 3 CONST //

  const [showMortgageQuestions, setShowMortgageQuestions] = useState("");
  const [showRentQuestions, setShowRentQuestions] = useState("");
  const [showLivingWithFamilyQuestions, setShowLivingWithFamilyQuestions] = useState("");

  const [housingStatus, setHousingStatus] = useState("");
  const [mortgageType, setMortgageType] = useState("");
  const [outstandingMortgageAmount, setOutstandingMortgageAmount] =
    useState("");
  const [outstandingMortgageTerm, setOutstandingMortgageTerm] = useState("");
  const [monthlyMortgagePayment, setMonthlyMortgagePayment] = useState("");
  const [lender, setLender] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [rateType, setRateType] = useState("");
  const [rateDealEndDate, setRateDealEndDate] = useState("");
  const [monthlyRentalAmount, setMonthlyRentalAmount] = useState("");
  const [livingWithFamilyRentAmount, setLivingWithFamilyRentAmount] =
    useState("");

    useEffect(() => {
      if (versionDataUpdated) {
        setShowMortgageQuestions(versionDataUpdated.showMortgageQuestions === "true");
        setShowRentQuestions(versionDataUpdated.showRentQuestions === "true");
        setShowLivingWithFamilyQuestions(
          versionDataUpdated.showLivingWithFamilyQuestions === "true"
        );
        setHousingStatus(versionDataUpdated.housingStatus);
        setMortgageType(versionDataUpdated.mortgageType);
        setOutstandingMortgageAmount(versionDataUpdated.outstandingMortgageAmount);
        setOutstandingMortgageTerm(versionDataUpdated.outstandingMortgageTerm);
        setMonthlyMortgagePayment(versionDataUpdated.monthlyMortgagePayment);
        setLender(versionDataUpdated.lender);
        setInterestRate(versionDataUpdated.interestRate);
        setRateType(versionDataUpdated.rateType);
        setRateDealEndDate(versionDataUpdated.rateDealEndDate);
        setMonthlyRentalAmount(versionDataUpdated.monthlyRentalAmount);
        setLivingWithFamilyRentAmount(versionDataUpdated.livingWithFamilyRentAmount);
      }
    }, [versionDataUpdated]);
  

  // const [showMortgageQuestions, setShowMortgageQuestions] = useState(
  //   factData.showMortgageQuestions
  // );

  // const [showRentQuestions, setShowRentQuestions] = useState(
  //   factData.showRentalQuestions
  // );
  // const [showLivingWithFamilyQuestions, setShowLivingWithFamilyQuestions] =
  //   useState(factData.showLivingWithFamilyQuestions);

  const [notesSection3, setNotesSection3] = useState("");

  // SECTION 4 CONST //
  const [hasOtherMortgages, setHasOtherMortgages] = useState("");
  const [otherMortgages, setOtherMortgages] = useState([]);
  const [hasOtherLoans, setHasOtherLoans] = useState([]);
  const [otherLoans, setOtherLoans] = useState([]);

  const addOtherLoan = () => {
    setOtherLoans([
      ...otherLoans,
      {
        id: Date.now(),
        loanType: "",
        loanAmountOutstanding: "",
        loanTermOutstanding: "",
        monthlyLoanPayment: "",
      },
    ]);
  };

  const removeOtherLoan = (loanId) => {
    setOtherLoans(otherLoans.filter((loan) => loan.id !== loanId));
  };

  // Add and remove mortgage functions
  const addMortgage = () => {
    setOtherMortgages([
      ...otherMortgages,
      {
        id: Date.now(),
        mortgageType2: "",
        mortgageAmountOutstanding: "",
        monthlyMortgageTerm: "",
        monthlyMortgagePayment2: "",
        mortgageLender: "",
        mortgageInterestRate: "",
        mortgageRateType: "",
        mortgageRateDealEnd: "",
      },
    ]);
  };

  const removeMortgage = (id) => {
    setOtherMortgages(otherMortgages.filter((mortgage) => mortgage.id !== id));
  };

  // Use Effect for Additional Income and total Monthly Income Calc //
  useEffect(() => {
    const totalAdditionalIncomeClient1 = client1AdditionalIncomes?.reduce(
      (total, income) => total + parseFloat(income.amount || 0),
      0
    ) || 0;
    const totalAdditionalIncomeClient2 = client2AdditionalIncomes?.reduce(
      (total, income) => total + parseFloat(income.amount || 0),
      0
    ) || 0;

    const totalNetMonthlyIncome =
      parseFloat(client1NetMonthlyIncome || 0) +
      (lookingToCoverPartner === "Yes"
        ? parseFloat(client2NetMonthlyIncome || 0)
        : 0);

    const totalMonthlyIncome =
      totalNetMonthlyIncome +
      totalAdditionalIncomeClient1 +
      totalAdditionalIncomeClient2;

    setMonthlyHouseholdIncome(totalMonthlyIncome.toFixed(2));
  }, [
    client1NetMonthlyIncome,
    client2NetMonthlyIncome,
    lookingToCoverPartner,
    client1AdditionalIncomes,
    client2AdditionalIncomes,
  ]);

  //SECTION 5 CONST //
  const [monthlyHouseholdIncome, setMonthlyHouseholdIncome] = useState("");
  const [monthlyMortgageRentLoanAmount, setMonthlyMortgageRentLoanAmount] = useState("");
  const [totalBillsPerMonth, setTotalBillsPerMonth] = useState("");
  const [monthlyLivingCost, setMonthlyLivingCost] = useState("");

  const [howMuchInSavings, setHowMuchInSavings] = useState("");

  const [approxMonthlyDisposableIncome, setApproxMonthlyDisposableIncome] =
    useState("");
  const [combinedMonthlyAmount, setCombinedMonthlyAmount] = useState("");

  //SECTION 6 CONST //
  const [hasExistingCover, setHasExistingCover] = useState("");

  const [additionalCoverSections, setAdditionalCoverSections] = useState(
    versionDataUpdated.additionalCoverSections
      ? versionDataUpdated.additionalCoverSections.map((section) => ({
          ...section,
          whenSetUp: convertToYYYYMMDD(section.whenSetUp),
        }))
      : [
          {
            policyType: "",
            personCovered: "",
            coverAmount: "",
            coverTerm: "",
            insurer: "",
            whenSetUp: "",
            premium: "",
          },
        ]
  );

  const [section6details, setSection6Details] = useState("");

  //SECTION 7 CONST //
  const [covidStatus, setCovidStatus] = useState("");
  const [covidStatusDetails, setCovidStatusDetails] = useState("");
  const [seriousConditions, setSeriousConditions] = useState("");
  const [seriousConditionsDetails, setSeriousConditionsDetails] = useState("");
  const [otherConditions, setOtherConditions] = useState("");
  const [otherConditionsDetails, setOtherConditionsDetails] = useState("");
  const [section7FreeText, setSection7FreeText] = useState("");
  const [section7FreeText2, setSection7FreeText2] = useState("");
  const [physicalConditions, setPhysicalConditions] = useState("");
  const [physicalConditionsDetails, setPhysicalConditionsDetails] = useState("");
  const [mentalHealth, setMentalHealth] = useState("");
  const [mentalHealthDetails, setMentalHealthDetails] = useState("");
  const [medication, setMedication] = useState("");
  const [medicationDetails, setMedicationDetails] = useState("");
  const [familyHistory, setFamilyHistory] = useState("");
  const [familyHistoryDetails, setFamilyHistoryDetails] = useState("");
  const [hobbies, setHobbies] = useState("");
  const [hobbiesDetails, setHobbiesDetails] = useState("");
  const [travelHistory, setTravelHistory] = useState("");
  const [travelHistoryDetails, setTravelHistoryDetails] = useState("");
  const [bmi, setBmi] = useState("");
  const [bmiDetails, setBmiDetails] = useState("");
  const [travelPast5Years, setTravelPast5Years] = useState("");
  const [travelPast5YearsDetails, setTravelPast5YearsDetails] = useState("");
  const [rideBikes, setRideBikes] = useState("");
  const [rideBikesDetails, setRideBikesDetails] = useState("");
  const [currentSize, setCurrentSize] = useState("");
  const [potentiallyVulnerable, setPotentiallyVulnerable] = useState("");
  const [potentiallyVulnerableDetails, setPotentiallyVulnerableDetails] = useState("");

  const [partnerCovidStatus, setPartnerCovidStatus] = useState("");
  const [partnerCovidStatusDetails, setPartnerCovidStatusDetails] = useState("");
  const [partnerSeriousConditions, setPartnerSeriousConditions] = useState("");
  const [partnerSeriousConditionsDetails, setPartnerSeriousConditionsDetails] = useState("");
  const [partnerOtherConditions, setPartnerOtherConditions] = useState("");
  const [partnerOtherConditionsDetails, setPartnerOtherConditionsDetails] = useState("");
  const [partnerSection7FreeText, setPartnerSection7FreeText] = useState("");
  const [partnerSection7FreeText2, setPartnerSection7FreeText2] = useState("");
  const [partnerPhysicalConditions, setPartnerPhysicalConditions] = useState("");
  const [
    partnerPhysicalConditionsDetails,
    setPartnerPhysicalConditionsDetails,
  ] = useState("");
  const [partnerMentalHealth, setPartnerMentalHealth] = useState("");
  const [partnerMentalHealthDetails, setPartnerMentalHealthDetails] = useState("");
  const [partnerMedication, setPartnerMedication] = useState("");
  const [partnerMedicationDetails, setPartnerMedicationDetails] = useState("");
  const [partnerFamilyHistory, setPartnerFamilyHistory] = useState("");
  const [partnerFamilyHistoryDetails, setPartnerFamilyHistoryDetails] = useState("");
  const [partnerHobbies, setPartnerHobbies] = useState("");
  const [partnerHobbiesDetails, setPartnerHobbiesDetails] = useState("");
  const [partnerTravelHistory, setPartnerTravelHistory] = useState("");
  const [partnerTravelHistoryDetails, setPartnerTravelHistoryDetails] = useState("");
  const [partnerBmi, setPartnerBmi] = useState("");
  const [partnerBmiDetails, setPartnerBmiDetails] = useState("");
  const [partnerTravelPast5Years, setPartnerTravelPast5Years] = useState("");
  const [partnerTravelPast5YearsDetails, setPartnerTravelPast5YearsDetails] = useState("");
  const [partnerRideBikes, setPartnerRideBikes] = useState("");
  const [partnerRideBikesDetails, setPartnerRideBikesDetails] = useState("");
  const [partnerCurrentSize, setPartnerCurrentSize] = useState("");
  const [partnerPotentiallyVulnerable, setPartnerPotentiallyVulnerable] = useState("");
  const [
    partnerPotentiallyVulnerableDetails,
    setPartnerPotentiallyVulnerableDetails,
  ] = useState(""); //new

  const [section7details, setSection7Details] = useState("");

  // PDF Functionality
  const [isPrinting, setIsPrinting] = useState(false);

  const createAndDownloadPDF = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://fact-finder.herokuapp.com/api/create-pdf",
        {
          caseId,
          regulatoryScriptRead,
          factFindDate,
          lookingToCover,
          lookingToCoverPartner,
          client1Title,
          client1FirstName,
          client1Surname,
          client1DateOfBirth,
          client1Age,
          client1SmokerStatus,
          client1LastNicotineUse,
          client1CigarettesPerDay,
          client1MaritalStatus,
          client2Title,
          client2FirstName,
          client2Surname,
          client2DateOfBirth,
          client2Age,
          client2SmokerStatus,
          client2LastNicotineUse,
          client2CigarettesPerDay,
          hasChildren,
          numberOfChildren,
          childrenAges,
          childrenAgeOfFinancialIndependence,
          client1EmploymentStatus,
          client1GrossAnnualIncomePAYE,
          client1GrossAnnualIncomeDividends,
          client1CompanyType,
          client1YearsEstablished,
          client1GrossAnnualIncome,
          client1MonthlyBenefits,
          client1NetMonthlyIncome,
          client1JobTitle,
          client1SickPay,
          client1FullSickPayMonths,
          client1HalfSickPayMonths,
          client1DeathInService,
          client1YearsOfSalary,
          client1RetirementAge,
          client1OtherIncome,
          additionalCoverSections: additionalCoverSections.map((section) => ({
            policyType: section.policyType,
            personCovered: section.personCovered,
            coverAmount: section.coverAmount,
            coverTerm: section.coverTerm,
            insurer: section.insurer,
            whenSetUp: section.whenSetUp,
            premium: section.premium,
          })),
          client1AdditionalIncomes: client1AdditionalIncomes
            ? client1AdditionalIncomes.map((income) => ({
                amount: income.amount,
                source: income.source,
              }))
            : [],
          client1NotesSection2,
          client2EmploymentStatus,
          client2CompanyType,
          client2YearsEstablished,
          client2GrossAnnualIncomePAYE,
          client2GrossAnnualIncomeDividends,
          client2GrossAnnualIncome,
          client2MonthlyBenefits,
          client2NetMonthlyIncome,
          client2JobTitle,
          client2SickPay,
          client2FullSickPayMonths,
          client2HalfSickPayMonths,
          client2DeathInService,
          client2YearsOfSalary,
          client2RetirementAge,
          client2OtherIncome,
          client2AdditionalIncomes: client2AdditionalIncomes
            ? client2AdditionalIncomes.map((income) => ({
                amount: income.amount,
                source: income.source,
              }))
            : [],
          client2NotesSection2,
          housingStatus,
          mortgageType,
          outstandingMortgageAmount,
          outstandingMortgageTerm,
          monthlyMortgagePayment,
          lender,
          interestRate,
          rateType,
          rateDealEndDate,
          monthlyRentalAmount,
          livingWithFamilyRentAmount,
          showMortgageQuestions,
          showRentQuestions,
          showLivingWithFamilyQuestions,
          notesSection3,
          hasOtherMortgages,
          otherMortgages: otherMortgages
            ? otherMortgages.map((mortgage) => ({
                mortgageType2: mortgage.mortgageType2,
                mortgageAmountOutstanding: mortgage.mortgageAmountOutstanding,
                monthlyMortgageTerm: mortgage.monthlyMortgageTerm,
                monthlyMortgagePayment2: mortgage.monthlyMortgagePayment2,
                mortgageLender: mortgage.mortgageLender,
                mortgageInterestRate: mortgage.mortgageInterestRate,
                mortgageRateType: mortgage.mortgageRateType,
                mortgageRateDealEnd: mortgage.mortgageRateDealEnd,
              }))
            : [],
          hasOtherLoans,
          otherLoans: otherLoans
            ? otherLoans.map((loan) => ({
                loanType: loan.loanType,
                loanAmountOutstanding: loan.loanAmountOutstanding,
                loanTermOutstanding: loan.loanTermOutstanding,
                monthlyLoanPayment: loan.monthlyLoanPayment,
              }))
            : [],
          monthlyHouseholdIncome,
          monthlyMortgageRentLoanAmount,
          combinedMonthlyAmount,
          totalBillsPerMonth,
          monthlyLivingCost,
          approxMonthlyDisposableIncome,
          howMuchInSavings,
          hasExistingCover,
          aadditionalCoverSections: additionalCoverSections
            ? additionalCoverSections.map((section) => ({
                policyType: section.policyType,
                personCovered: section.personCovered,
                coverAmount: section.coverAmount,
                coverTerm: section.coverTerm,
                insurer: section.insurer,
                whenSetUp: section.whenSetUp,
                premium: section.premium,
              }))
            : [],
          section6details,
          covidStatus,
          covidStatusDetails,
          partnerCovidStatus,
          partnerCovidStatusDetails,
          seriousConditions,
          seriousConditionsDetails,
          partnerSeriousConditions,
          partnerSeriousConditionsDetails,
          otherConditions,
          otherConditionsDetails,
          partnerOtherConditions,
          partnerOtherConditionsDetails,
          physicalConditions,
          physicalConditionsDetails,
          partnerPhysicalConditions,
          partnerPhysicalConditionsDetails,
          mentalHealth,
          mentalHealthDetails,
          partnerMentalHealth,
          partnerMentalHealthDetails,
          medication,
          medicationDetails,
          partnerMedication,
          partnerMedicationDetails,
          familyHistory,
          familyHistoryDetails,
          partnerFamilyHistory,
          partnerFamilyHistoryDetails,
          hobbies,
          hobbiesDetails,
          partnerHobbies,
          partnerHobbiesDetails,
          travelHistory,
          travelHistoryDetails,
          partnerTravelHistory,
          partnerTravelHistoryDetails,
          bmi,
          bmiDetails,
          partnerBmi,
          partnerBmiDetails,
          travelPast5Years,
          travelPast5YearsDetails,
          partnerTravelPast5Years,
          partnerTravelPast5YearsDetails,
          rideBikes,
          rideBikesDetails,
          partnerRideBikes,
          partnerRideBikesDetails,
          currentSize,
          partnerCurrentSize,
          potentiallyVulnerable,
          potentiallyVulnerableDetails,
          partnerPotentiallyVulnerable,
          partnerPotentiallyVulnerableDetails,
        },
        { responseType: "blob" }
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      saveAs(pdfBlob, `FactFinder_${caseId}.pdf`);
    } catch (error) {
      console.error("Failed to create or download PDF:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = async (event) => {
    event.stopPropagation();
    setIsLoading(true);
    await createAndDownloadPDF();
    setIsLoading(false);
  };

   //UseLayout to get data from versionData
   useEffect(() => {
    const versionData = async () => {
     // console.log(`Fetching data from: https://fact-finder.herokuapp.com/api/factversions/version/${versionId}`);
      try {
        const response = await fetch(
          `https://fact-finder.herokuapp.com/api/factversions/version/${versionId}`
        );
        const data = await response.json();
      setCaseId(data.caseId);
      setLookingToCover(data.lookingToCover);
      setLookingToCoverPartner(data.lookingToCoverPartner);
      setRegulatoryScriptRead(data.regulatoryScriptRead);
      setFactFindDate(data.factFindDate);
      setUpdateFactFindDate(data.updateFactFindDate);
      setClient1Title(data.client1.title);
      setClient1FirstName(data.client1.firstname);
      setClient1Surname(data.client1.surname);
      setClient1DateOfBirth(data.client1.dateOfBirth);
      setClient1Age(data.client1.age);
      setClient1SmokerStatus(data.client1.smokerStatus);
      setClient1LastNicotineUse(data.client1.lastNicotineUse);
      setClient1CigarettesPerDay(data.client1.cigarettesPerDay);
      setClient1MaritalStatus(data.client1.maritalStatus);
      setHasChildren(data.client1.hasChildren);
      setChildrenAges(data.client1.childrenAges);
      setChildrenAgeOfFinancialIndependence(data.client1.childrenAgeOfFinancialIndependence);
      setClient2Title(data.client2.title)
      setClient2FirstName(data.client2.firstname);
      setClient2Surname(data.client2.surname);
      setClient2DateOfBirth(data.client2.dateOfBirth);
      setClient2Age(data.client2.age);
      setClient2SmokerStatus(data.client2.smokerStatus);
      setClient2LastNicotineUse(data.client2.lastNicotineUse);
      setClient2CigarettesPerDay(data.client2.cigarettesPerDay);
      setClient1EmploymentStatus(data.client1.employmentStatus);
      setClient1CompanyType(data.client1.companyType);
      setClient1YearsEstablished(data.client1.yearsEstablished);
      setClient1GrossAnnualIncomePAYE(data.client1.grossAnnualIncomePAYE);
      setClient1GrossAnnualIncomeDividends(data.client1.grossAnnualIncomeDividends);
      setClient1GrossAnnualIncome(data.client1.grossAnnualIncome);
      setClient1MonthlyBenefits(data.client1.monthlyBenefits);
      setClient1NetMonthlyIncome(data.client1.netMonthlyIncome);
      setClient1JobTitle(data.client1.jobTitle);
      setClient1SickPay(data.client1.sickPay);
      setClient1FullSickPayMonths(data.client1.fullSickPayMonths);
      setClient1HalfSickPayMonths(data.client1.halfSickPayMonths);
      setClient1DeathInService(data.client1.deathInService);
      setClient1YearsOfSalary(data.client1.yearsOfSalary);
      setClient1RetirementAge(data.client1.retirementAge);
      setClient1OtherIncome(data.client1.otherIncome);
      setClient1NotesSection2(data.client1.notesSection2);
      setAdditionalIncomes(data.additionalIncomes); //might not need
      setClient1AdditionalIncomes(data.client1.additionalIncomes);
      //setAdditionalIncomesClient2(versionData.additionalIncomesClient2); //might not need
      setClient2EmploymentStatus(data.client2.employmentStatus);
      setClient2CompanyType(data.client2.companyType);
      setClient2YearsEstablished(data.client2.yearsEstablished);
      setClient2GrossAnnualIncomePAYE(data.client2.grossAnnualIncomePAYE);
      setClient2GrossAnnualIncomeDividends(data.client2.grossAnnualIncomeDividends);
      setClient2GrossAnnualIncome(data.client2.grossAnnualIncome);
      setClient2MonthlyBenefits(data.client2.monthlyBenefits);
      setClient2NetMonthlyIncome(data.client2.netMonthlyIncome);
      setClient2JobTitle(data.client2.jobTitle);
      setClient2SickPay(data.client2.sickPay);
      setClient2FullSickPayMonths(data.client2.fullSickPayMonths);
      setClient2HalfSickPayMonths(data.client2.halfSickPayMonths);
      setClient2DeathInService(data.client2.deathInService);
      setClient2YearsOfSalary(data.client2.yearsOfSalary);
      setClient2RetirementAge(data.client2.retirementAge);
      setClient2OtherIncome(data.client2.otherIncome);
      setClient2NotesSection2(data.client2.notesSection2);
      setClient2AdditionalIncomes(data.client2.additionalIncomes);
      setShowMortgageQuestions(data.showMortgageQuestions);
      setShowRentQuestions(data.showRentQuestions);
      setShowLivingWithFamilyQuestions(data.showLivingWithFamilyQuestions);
      setHousingStatus(data.housingStatus);
      setMortgageType(data.mortgageType);
      setOutstandingMortgageAmount(data.outstandingMortgageAmount);
      setOutstandingMortgageTerm(data.outstandingMortgageTerm);
      setMonthlyMortgagePayment(data.monthlyMortgagePayment);
      setLender(data.lender);
      setInterestRate(data.interestRate);
      setRateType(data.rateType);
      setRateDealEndDate(data.rateDealEndDate);
      setMonthlyRentalAmount(data.monthlyRentalAmount);
      setLivingWithFamilyRentAmount(data.livingWithFamilyRentAmount);
      setNotesSection3(data.notesSection3);
      setHasOtherMortgages(data.hasOtherMortgages);
      setOtherMortgages(data.otherMortgages);
      setHasOtherLoans(data.hasOtherLoans);
      setOtherLoans(data.otherLoans);
      setMonthlyHouseholdIncome(data.monthlyHouseholdIncome);
      setMonthlyMortgageRentLoanAmount(data.monthlyMortgageRentLoanAmount);
      setTotalBillsPerMonth(data.totalBillsPerMonth);
      setHowMuchInSavings(data.howMuchInSavings);
      setApproxMonthlyDisposableIncome(data.approxMonthlyDisposableIncome);
      setCombinedMonthlyAmount(data.combinedMonthlyAmount);
      setHasExistingCover(data.hasExistingCover);
      setAdditionalCoverSections(data.additionalCoverSections);
      setSection6Details(data.section6Details);
      setCovidStatus(data.covidStatus);
      setSeriousConditions(data.seriousConditions);
      setSeriousConditionsDetails(data.seriousConditionsDetails);
      setOtherConditions(data.otherConditions);
      setOtherConditionsDetails(data.otherConditionsDetails);
      setSection7FreeText(data.section7FreeText);
      setSection7FreeText2(data.section7FreeText2);
      setPhysicalConditions(data.physicalConditions);
      setPhysicalConditionsDetails(data.physicalConditionsDetails);
      setMentalHealth(data.mentalHealth);
      setMentalHealthDetails(data.mentalHealthDetails);
      setMedication(data.medication);
      setMedicationDetails(data.medicationDetails);
      setFamilyHistory(data.familyHistory);
      setFamilyHistoryDetails(data.familyHistoryDetails);
      setHobbies(data.hobbies);
      setHobbiesDetails(data.hobbiesDetails);
      setTravelHistory(data.travelHistory);
      setTravelHistoryDetails(data.travelHistoryDetails);
      setBmi(data.bmi);
      setBmiDetails(data.bmiDetails);
      setTravelPast5Years(data.travelPast5Years);
      setTravelPast5YearsDetails(data.travelPast5YearsDetails);
      setRideBikes(data.rideBikes);
      setRideBikesDetails(data.rideBikesDetails);
      setCurrentSize(data.currentSize);
      setPotentiallyVulnerable(data.potentiallyVulnerable);
      setPotentiallyVulnerableDetails(data.potentiallyVulnerableDetails);
      setPartnerCovidStatus(data.partnerCovidStatus);
      setPartnerCovidStatusDetails(data.partnerCovidStatusDetails);
      setPartnerSeriousConditions(data.partnerSeriousConditions);
      setPartnerSeriousConditionsDetails(data.partnerSeriousConditionsDetails);
      setPartnerOtherConditions(data.partnerOtherConditions);
      setPartnerOtherConditionsDetails(data.partnerOtherConditionsDetails);
      setPartnerSection7FreeText(data.partnerSection7FreeText);
      setPartnerSection7FreeText2(data.partnerSection7FreeText2);
      setPartnerPhysicalConditions(data.partnerPhysicalConditions);
      setPartnerPhysicalConditionsDetails(data.partnerPhysicalConditionsDetails);
      setPartnerMentalHealth(data.partnerMentalHealth);
      setPartnerMentalHealthDetails(data.partnerMentalHealthDetails);
      setPartnerMedication(data.partnerMedication);
      setPartnerMedicationDetails(data.partnerMedicationDetails);
      setPartnerFamilyHistory(data.partnerFamilyHistory);
      setPartnerFamilyHistoryDetails(data.partnerFamilyHistoryDetails);
      setPartnerHobbies(data.partnerHobbies);
      setPartnerHobbiesDetails(data.partnerHobbiesDetails);
      setPartnerTravelHistory(data.partnerTravelHistory);
      setPartnerTravelHistoryDetails(data.partnerTravelHistoryDetails);
      setPartnerBmi(data.partnerBmi);
      setPartnerBmiDetails(data.partnerBmiDetails);
      setPartnerTravelPast5Years(data.partnerTravelPast5Years);
      setPartnerTravelPast5YearsDetails(data.partnerTravelPast5YearsDetails);
      setPartnerRideBikes(data.partnerRideBikes);
      setPartnerRideBikesDetails(data.partnerRideBikesDetails);
      setPartnerCurrentSize(data.partnerCurrentSize);
      setPartnerPotentiallyVulnerable(data.partnerPotentiallyVulnerable);
      setPartnerPotentiallyVulnerableDetails(data.partnerPotentiallyVulnerableDetails);
      setSection7Details(data.section7Details);
    } catch (err) {
      console.error("Failed to fetch factData:", err);
    }
  };

  versionData();
}, [versionId]);

  // HANDLES SECTION 1 //

  const handleRegulatoryScriptReadChange = (e) => {
    setRegulatoryScriptRead(e.target.value);
  };

  const handleFactFindDateChange = (e) => {
    setFactFindDate(e.target.value);
  };

  const handleClient1FirstNameChange = (e) => {
    setClient1FirstName(e.target.value);
  };

  const handleClient1TitleChange = (e) => {
    setClient1Title(e.target.value);
  };

  const handleClient1SurnameChange = (e) => {
    setClient1Surname(e.target.value);
  };

  // const handleClient1DateOfBirthChange = (e) => {
  //   setClient1DateOfBirth(e.target.value);
  // };

  const handleClient1DateOfBirthChange = (event) => {
    setClient1DateOfBirth(event.target.value);
    const birthDate = new Date(event.target.value);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      setClient1Age(age - 1);
    } else {
      setClient1Age(age);
    }
  };

  const handleChildrenAgeOfFinancialIndependenceChange = (e) => {
    setChildrenAgeOfFinancialIndependence(e.target.value);
  };

  const handleClient1AgeChange = (e) => {
    setClient1Age(e.target.value);
  };

  const handleClient1SmokerStatusChange = (e) => {
    setClient1SmokerStatus(e.target.value);
  };

  const handleClient1LastNicotineUseChange = (e) => {
    setClient1LastNicotineUse(e.target.value);
  };

  const handleClient1CigarettesPerDayChange = (e) => {
    setClient1CigarettesPerDay(e.target.value);
  };

  const handleClient2FirstNameChange = (e) => {
    setClient2FirstName(e.target.value);
  };

  const handleClient2TitleChange = (e) => {
    setClient2Title(e.target.value);
  };

  const handleClient2SurnameChange = (e) => {
    setClient2Surname(e.target.value);
  };

  const handleClient2DateOfBirthChange = (e) => {
    setClient2DateOfBirth(e.target.value);
  };

  const handleClient2DateOfBirthBlur = (event) => {
    setClient2DateOfBirth(event.target.value);
    const birthDate = new Date(event.target.value);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      setClient2Age(age - 1);
    } else {
      setClient2Age(age);
    }
  };

  const handleClient2AgeChange = (e) => {
    setClient2Age(e.target.value);
  };

  const handleClient2SmokerStatusChange = (e) => {
    setClient2SmokerStatus(e.target.value);
  };

  const handleClient2LastNicotineUseChange = (e) => {
    setClient2LastNicotineUse(e.target.value);
  };

  const handleClient2CigarettesPerDayChange = (e) => {
    setClient2CigarettesPerDay(e.target.value);
  };

  const handleClient1MaritalStatusChange = (e) => {
    setClient1MaritalStatus(e.target.value);
  };

  const handleHasChildrenChange = (e) => {
    setHasChildren(e.target.value);
  };

  const handleNumberOfChildrenChange = (e) => {
    setNumberOfChildren(e.target.value);
  };

  const handleChildrenAgesChange = (e) => {
    setChildrenAges(e.target.value);
  };

  const handleChildAgeChange = (event, index) => {
    const newAges = [...childrenAges];
    newAges[index] = parseInt(event.target.value);
    setChildrenAges(newAges);
  };

  // Handles Section 2 //

  const handleClient1EmploymentStatusChange = (e) => {
    setClient1EmploymentStatus(e.target.value);
  };

  const handleClient1CompanyTypeChange = (e) => {
    setClient1CompanyType(e.target.value);
  };

  const handleClient1YearsEstablishedChange = (e) => {
    setClient1YearsEstablished(e.target.value);
  };

  const handleClient1GrossAnnualIncomePAYEChange = (e) => {
    setClient1GrossAnnualIncomePAYE(e.target.value);
  };

  const handleClient1GrossAnnualIncomeDividendsChange = (e) => {
    setClient1GrossAnnualIncomeDividends(e.target.value);
  };

  const handleClient1GrossAnnualIncomeChange = (e) => {
    setClient1GrossAnnualIncome(e.target.value);
  };

  const handleClient1MonthlyBenefitsChange = (e) => {
    setClient1MonthlyBenefits(e.target.value);
  };

  const handleClient1NetMonthlyIncomeChange = (e) => {
    setClient1NetMonthlyIncome(e.target.value);
  };

  const handleClient1JobTitleChange = (e) => {
    setClient1JobTitle(e.target.value);
  };

  const handleClient1SickPayChange = (e) => {
    setClient1SickPay(e.target.value);
  };

  const handleClient1FullSickPayMonthsChange = (e) => {
    setClient1FullSickPayMonths(e.target.value);
  };

  const handleClient1HalfSickPayMonthsChange = (e) => {
    setClient1HalfSickPayMonths(e.target.value);
  };

  const handleClient1DeathInServiceChange = (e) => {
    setClient1DeathInService(e.target.value);
  };

  const handleClient1YearsOfSalaryChange = (e) => {
    setClient1YearsOfSalary(e.target.value);
  };

  const handleClient1RetirementAgeChange = (e) => {
    setClient1RetirementAge(e.target.value);
  };

  const handleClient1OtherIncomeChange = (e) => {
    setClient1OtherIncome(e.target.value);
  };

  const handleClient1NotesSection2Change = (e) => {
    setClient1NotesSection2(e.target.value);
  };

  const handleClient2EmploymentStatusChange = (e) => {
    setClient2EmploymentStatus(e.target.value);
  };

  const handleClient2CompanyTypeChange = (e) => {
    setClient2CompanyType(e.target.value);
  };

  const handleClient2YearsEstablishedChange = (e) => {
    setClient2YearsEstablished(e.target.value);
  };

  const handleClient2GrossAnnualIncomePAYEChange = (e) => {
    setClient2GrossAnnualIncomePAYE(e.target.value);
  };

  const handleClient2GrossAnnualIncomeDividendsChange = (e) => {
    setClient2GrossAnnualIncomeDividends(e.target.value);
  };

  const handleClient2GrossAnnualIncomeChange = (e) => {
    setClient2GrossAnnualIncome(e.target.value);
  };

  const handleClient2MonthlyBenefitsChange = (e) => {
    setClient2MonthlyBenefits(e.target.value);
  };

  const handleClient2NetMonthlyIncomeChange = (e) => {
    setClient2NetMonthlyIncome(e.target.value);
  };

  const handleClient2JobTitleChange = (e) => {
    setClient2JobTitle(e.target.value);
  };

  const handleClient2SickPayChange = (e) => {
    setClient2SickPay(e.target.value);
  };

  const handleClient2FullSickPayMonthsChange = (e) => {
    setClient2FullSickPayMonths(e.target.value);
  };

  const handleClient2HalfSickPayMonthsChange = (e) => {
    setClient2HalfSickPayMonths(e.target.value);
  };

  const handleClient2DeathInServiceChange = (e) => {
    setClient2DeathInService(e.target.value);
  };

  const handleClient2YearsOfSalaryChange = (e) => {
    setClient2YearsOfSalary(e.target.value);
  };

  const handleClient2RetirementAgeChange = (e) => {
    setClient2RetirementAge(e.target.value);
  };

  const handleClient2OtherIncomeChange = (e) => {
    setClient2OtherIncome(e.target.value);
  };

  const handleClient2NotesSection2Change = (e) => {
    setClient2NotesSection2(e.target.value);
  };

  const handleCaseIdChange = (e) => {
    setCaseId(e.target.value);
  };

  const handleLookingToCoverChange = (e) => {
    setLookingToCover(e.target.value);
  };

  const handleLookingToCoverPartnerChange = (e) => {
    setLookingToCoverPartner(e.target.value);
  };

  //Handles Section 2
  const handleClient1AdditionalIncomeAmountChange = (event, index) => {
    const newIncomes = [...client1AdditionalIncomes];
    newIncomes[index].amount = event.target.value;
    setClient1AdditionalIncomes(newIncomes);
  };

  const handleClient1AdditionalIncomeSourceChange = (event, index) => {
    const newIncomes = [...client1AdditionalIncomes];
    newIncomes[index].source = event.target.value;
    setClient1AdditionalIncomes(newIncomes);
  };

  const handleAddClient1AdditionalIncome = () => {
    setClient1AdditionalIncomes([
      ...client1AdditionalIncomes,
      {
        amount: "",
        source: "",
      },
    ]);
  };

  const handleRemoveClient1AdditionalIncome = (index) => {
    const newIncomes = [...client1AdditionalIncomes];
    newIncomes.splice(index, 1);
    setClient1AdditionalIncomes(newIncomes);
  };

  // Client 2 Section 2

  const handleClient2AdditionalIncomeAmountChange = (event, index) => {
    const newIncomes = [...client2AdditionalIncomes];
    newIncomes[index].amount = event.target.value;
    setClient2AdditionalIncomes(newIncomes);
  };

  const handleClient2AdditionalIncomeSourceChange = (event, index) => {
    const newIncomes = [...client2AdditionalIncomes];
    newIncomes[index].source = event.target.value;
    setClient2AdditionalIncomes(newIncomes);
  };

  const handleAddClient2AdditionalIncome = () => {
    setClient2AdditionalIncomes([
      ...client2AdditionalIncomes,
      {
        amount: "",
        source: "",
      },
    ]);
  };

  const handleRemoveClient2AdditionalIncome = (index) => {
    const newIncomes = [...client2AdditionalIncomes];
    newIncomes.splice(index, 1);
    setClient2AdditionalIncomes(newIncomes);
  };

  // SECTION 3 HANDLES //

  const handleHousingStatusChange = (event) => {
    const value = event.target.value;
    console.log("Housing status selected: ", value);
    setHousingStatus(value);

    if (value === "Mortgage") {
      setShowMortgageQuestions(true);
      setShowRentQuestions(false);
      setShowLivingWithFamilyQuestions(false);
    } else if (value === "Renting") {
      setShowMortgageQuestions(false);
      setShowRentQuestions(true);
      setShowLivingWithFamilyQuestions(false);
    } else if (value === "Living with family") {
      setShowMortgageQuestions(false);
      setShowRentQuestions(false);
      setShowLivingWithFamilyQuestions(true);
    } else {
      setShowMortgageQuestions(false);
      setShowRentQuestions(false);
      setShowLivingWithFamilyQuestions(false);
    }

    console.log("showMortgageQuestions: ", showMortgageQuestions);
    console.log("showRentQuestions: ", showRentQuestions);
    console.log(
      "showLivingWithFamilyQuestions: ",
      showLivingWithFamilyQuestions
    );
  };

  const handleMortgageTypeChange = (e) => {
    setMortgageType(e.target.value);
  };

  const handleOutstandingMortgageAmountChange = (e) => {
    setOutstandingMortgageAmount(e.target.value);
  };

  const handleOutstandingMortgageTermChange = (e) => {
    setOutstandingMortgageTerm(e.target.value);
  };

  const handleMonthlyMortgagePaymentChange = (e) => {
    setMonthlyMortgagePayment(e.target.value);
  };

  const handleLenderChange = (e) => {
    setLender(e.target.value);
  };

  const handleInterestRateChange = (e) => {
    setInterestRate(e.target.value);
  };

  const handleRateTypeChange = (e) => {
    setRateType(e.target.value);
  };

  const handleRateDealEndDateChange = (e) => {
    setRateDealEndDate(e.target.value);
  };

  const handleMonthlyRentalAmountChange = (e) => {
    setMonthlyRentalAmount(e.target.value);
  };

  const handleLivingWithFamilyRentAmountChange = (e) => {
    setLivingWithFamilyRentAmount(e.target.value);
  };

  const handleNotesSection3Change = (e) => {
    setNotesSection3(e.target.value);
  };

  //SECTION 4//

  const handleMortgageChange = (id, field, value) => {
    setOtherMortgages(
      otherMortgages.map((mortgage) =>
        mortgage.id === id ? { ...mortgage, [field]: value } : mortgage
      )
    );
  };

  const handleHasOtherMortgagesChange = (event) => {
    setHasOtherMortgages(event.target.value);
    if (event.target.value === "Yes" && otherMortgages.length === 0) {
      addMortgage();
    }
  };

  const handleOtherLoanChange = (id, field, value) => {
    setOtherLoans(
      otherLoans.map((loan) =>
        loan.id === id ? { ...loan, [field]: value } : loan
      )
    );
  };

  const handleHasOtherLoansChange = (event) => {
    setHasOtherLoans(event.target.value);
    if (event.target.value === "Yes" && otherLoans.length === 0) {
      addOtherLoan();
    }
  };

  // SECTION 5 //
  const handleMonthlyHouseholdIncomeChange = (e) => {
    setMonthlyHouseholdIncome(e.target.value);
  };

  const handleMonthlyMortgageRentLoanAmountChange = (e) => {
    setMonthlyMortgageRentLoanAmount(e.target.value);
  };

  const handleTotalBillsPerMonthChange = (e) => {
    setTotalBillsPerMonth(e.target.value);
  };

  const handleMonthlyLivingCostChange = (e) => {
    setMonthlyLivingCost(e.target.value);
  };

  const handleHowMuchInSavingsChange = (e) => {
    setHowMuchInSavings(e.target.value);
  };

  // Calculate combined monthly amount for Rent / Loans / Mortgages etc
  const updateCombinedMonthlyAmount = useCallback(() => {
    let mortgagePayment = parseFloat(monthlyMortgagePayment) || 0;
    let rentalAmount = parseFloat(monthlyRentalAmount) || 0;
    let familyRentAmount = parseFloat(livingWithFamilyRentAmount) || 0;
    let otherMortgagePayments = otherMortgages.reduce(
      (sum, mortgage) =>
        sum + (parseFloat(mortgage.monthlyMortgagePayment2) || 0),
      0
    );
    let otherLoanPayments = otherLoans.reduce(
      (sum, loan) => sum + (parseFloat(loan.monthlyLoanPayment) || 0),
      0
    );
    setCombinedMonthlyAmount(
      mortgagePayment +
        rentalAmount +
        familyRentAmount +
        otherMortgagePayments +
        otherLoanPayments
    );
  }, [
    monthlyMortgagePayment,
    monthlyRentalAmount,
    livingWithFamilyRentAmount,
    otherMortgages,
    otherLoans,
  ]);

  // Calculate Combined Monthly Amount
  useEffect(() => {
    updateCombinedMonthlyAmount();
  }, [
    monthlyMortgagePayment,
    monthlyRentalAmount,
    livingWithFamilyRentAmount,
    otherMortgages,
    otherLoans,
    updateCombinedMonthlyAmount,
  ]);

  useEffect(() => {
    const totalMonthlyHouseholdIncome =
      parseFloat(monthlyHouseholdIncome.replace(/£/g, "")) || 0;
    const monthlyMortgageRentLoan =
      parseFloat((combinedMonthlyAmount + "").replace(/£/g, "")) || 0; // Convert combinedMonthlyAmount to string before calling replace
    const totalBills = parseFloat(totalBillsPerMonth.replace(/£/g, "")) || 0;
    const livingCost = parseFloat(monthlyLivingCost.replace(/£/g, "")) || 0;
    const approxDisposableIncome =
      totalMonthlyHouseholdIncome -
      monthlyMortgageRentLoan -
      totalBills -
      livingCost;
    setApproxMonthlyDisposableIncome(`£${approxDisposableIncome.toFixed(2)}`);
  }, [
    monthlyHouseholdIncome,
    combinedMonthlyAmount, // Update the dependency to combinedMonthlyAmount
    totalBillsPerMonth,
    monthlyLivingCost,
  ]);

  // SECTION 6 //

  const handleHasExistingCoverChange = (e) => {
    setHasExistingCover(e.target.value);
  };

  const handlePolicyTypeChange = (event, index) => {
    const updatedCoverSections = [...additionalCoverSections];
    updatedCoverSections[index].policyType = event.target.value;
    setAdditionalCoverSections(updatedCoverSections);
  };

  const handlePersonCoveredChange = (event, index) => {
    const updatedCoverSections = [...additionalCoverSections];
    updatedCoverSections[index].personCovered = event.target.value;
    setAdditionalCoverSections(updatedCoverSections);
  };

  const handleCoverAmountChange = (event, index) => {
    const updatedCoverSections = [...additionalCoverSections];
    updatedCoverSections[index].coverAmount = event.target.value;
    setAdditionalCoverSections(updatedCoverSections);
  };

  const handleCoverTermChange = (event, index) => {
    const updatedCoverSections = [...additionalCoverSections];
    updatedCoverSections[index].coverTerm = event.target.value;
    setAdditionalCoverSections(updatedCoverSections);
  };

  const handleInsurerChange = (event, index) => {
    const updatedCoverSections = [...additionalCoverSections];
    updatedCoverSections[index].insurer = event.target.value;
    setAdditionalCoverSections(updatedCoverSections);
  };

  const handleWhenSetUpChange = (event, index) => {
    const updatedCoverSections = [...additionalCoverSections];
    updatedCoverSections[index].whenSetUp = event.target.value;
    setAdditionalCoverSections(updatedCoverSections);
  };

  const handlePremiumChange = (event, index) => {
    const updatedCoverSections = [...additionalCoverSections];
    updatedCoverSections[index].premium = event.target.value;
    setAdditionalCoverSections(updatedCoverSections);
  };

  const handleAddSection = () => {
    setAdditionalCoverSections([
      ...additionalCoverSections,
      {
        policyType: "",
        personCovered: "",
        coverAmount: "",
        coverTerm: "",
        insurer: "",
        whenSetUp: "",
        premium: "",
      },
    ]);
  };

  const handleSection6DetailsChange = (e) => {
    setSection6Details(e.target.value);
  };

  const handleRemoveSection = (index) => {
    setAdditionalCoverSections(
      additionalCoverSections.filter((_, i) => i !== index)
    );
  };

  // HANDLES SECTION 7 //

  const handleCovidStatusChange = (e) => setCovidStatus(e.target.value);
  const handleCovidStatusDetailsChange = (e) =>
    setCovidStatusDetails(e.target.value);
  const handleSeriousConditionsChange = (e) =>
    setSeriousConditions(e.target.value);
  const handleSeriousConditionsDetailsChange = (e) =>
    setSeriousConditionsDetails(e.target.value);
  const handleOtherConditionsChange = (e) => setOtherConditions(e.target.value);
  const handleOtherConditionsDetailsChange = (e) =>
    setOtherConditionsDetails(e.target.value);
  const handleSection7FreeTextChange = (e) =>
    setSection7FreeText(e.target.value);
  const handleSection7FreeText2Change = (e) =>
    setSection7FreeText2(e.target.value);
  const handlePhysicalConditionsChange = (e) =>
    setPhysicalConditions(e.target.value);
  const handlePhysicalConditionsDetailsChange = (e) =>
    setPhysicalConditionsDetails(e.target.value);
  const handleMentalHealthChange = (e) => setMentalHealth(e.target.value);
  const handleMentalHealthDetailsChange = (e) =>
    setMentalHealthDetails(e.target.value);
  const handleMedicationChange = (e) => setMedication(e.target.value);
  const handleMedicationDetailsChange = (e) =>
    setMedicationDetails(e.target.value);
  const handleFamilyHistoryChange = (e) => setFamilyHistory(e.target.value);
  const handleFamilyHistoryDetailsChange = (e) =>
    setFamilyHistoryDetails(e.target.value);
  const handleHobbiesChange = (e) => setHobbies(e.target.value);
  const handleHobbiesDetailsChange = (e) => setHobbiesDetails(e.target.value);
  const handleTravelHistoryChange = (e) => setTravelHistory(e.target.value);
  const handleTravelHistoryDetailsChange = (e) =>
    setTravelHistoryDetails(e.target.value);
  const handleBmiChange = (e) => setBmi(e.target.value);
  const handleBmiDetailsChange = (e) => setBmiDetails(e.target.value);
  const handleTravelPast5YearsChange = (e) =>
    setTravelPast5Years(e.target.value);
  const handleTravelPast5YearsDetailsChange = (e) =>
    setTravelPast5YearsDetails(e.target.value);
  const handleRideBikesChange = (e) => setRideBikes(e.target.value);
  const handleRideBikesDetailsChange = (e) =>
    setRideBikesDetails(e.target.value);
  const handleCurrentSizeChange = (e) => setCurrentSize(e.target.value);
  const handlePotentiallyVulnerableChange = (e) =>
    setPotentiallyVulnerable(e.target.value);
  const handlePotentiallyVulnerableDetailsChange = (e) =>
    setPotentiallyVulnerableDetails(e.target.value);

  const handlePartnerCovidStatusChange = (e) =>
    setPartnerCovidStatus(e.target.value);
  const handlePartnerCovidStatusDetailsChange = (e) =>
    setPartnerCovidStatusDetails(e.target.value);
  const handlePartnerSeriousConditionsChange = (e) =>
    setPartnerSeriousConditions(e.target.value);
  const handlePartnerSeriousConditionsDetailsChange = (e) =>
    setPartnerSeriousConditionsDetails(e.target.value);
  const handlePartnerOtherConditionsChange = (e) =>
    setPartnerOtherConditions(e.target.value);
  const handlePartnerOtherConditionsDetailsChange = (e) =>
    setPartnerOtherConditionsDetails(e.target.value);
  const handlePartnerSection7FreeTextChange = (e) =>
    setPartnerSection7FreeText(e.target.value);
  const handlePartnerSection7FreeText2Change = (e) =>
    setPartnerSection7FreeText2(e.target.value);
  const handlePartnerPhysicalConditionsChange = (e) =>
    setPartnerPhysicalConditions(e.target.value);
  const handlePartnerPhysicalConditionsDetailsChange = (e) =>
    setPartnerPhysicalConditionsDetails(e.target.value);
  const handlePartnerMentalHealthChange = (e) =>
    setPartnerMentalHealth(e.target.value);
  const handlePartnerMentalHealthDetailsChange = (e) =>
    setPartnerMentalHealthDetails(e.target.value);
  const handlePartnerMedicationChange = (e) =>
    setPartnerMedication(e.target.value);
  const handlePartnerMedicationDetailsChange = (e) =>
    setPartnerMedicationDetails(e.target.value);
  const handlePartnerFamilyHistoryChange = (e) =>
    setPartnerFamilyHistory(e.target.value);
  const handlePartnerFamilyHistoryDetailsChange = (e) =>
    setPartnerFamilyHistoryDetails(e.target.value);
  const handlePartnerHobbiesChange = (e) => setPartnerHobbies(e.target.value);
  const handlePartnerHobbiesDetailsChange = (e) =>
    setPartnerHobbiesDetails(e.target.value);
  const handlePartnerTravelHistoryChange = (e) =>
    setPartnerTravelHistory(e.target.value);
  const handlePartnerTravelHistoryDetailsChange = (e) =>
    setPartnerTravelHistoryDetails(e.target.value);
  const handlePartnerBmiChange = (e) => setPartnerBmi(e.target.value);
  const handlePartnerBmiDetailsChange = (e) =>
    setPartnerBmiDetails(e.target.value);
  const handlePartnerTravelPast5YearsChange = (e) =>
    setPartnerTravelPast5Years(e.target.value);
  const handlePartnerTravelPast5YearsDetailsChange = (e) =>
    setPartnerTravelPast5YearsDetails(e.target.value);
  const handlePartnerRideBikesChange = (e) =>
    setPartnerRideBikes(e.target.value);
  const handlePartnerRideBikesDetailsChange = (e) =>
    setPartnerRideBikesDetails(e.target.value);
  const handlePartnerCurrentSizeChange = (e) =>
    setPartnerCurrentSize(e.target.value);
  const handlePartnerPotentiallyVulnerableChange = (e) =>
    setPartnerPotentiallyVulnerable(e.target.value);
  const handlePartnerPotentiallyVulnerableDetailsChange = (e) =>
    setPartnerPotentiallyVulnerableDetails(e.target.value);

  const handleSection7DetailsChange = (e) => setSection7Details(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //await saveFactVersion();

    const newDate = new Date().toISOString().substring(0, 10);

    console.log("Updated client's data!:");

    const {
      data: { caseId },
    } = location.state;
    console.log("location.state", location.state);
    const updatedVersionData = {
      ...versionData,
      client1: {
        ...versionData.client1,
        firstname: client1FirstName,
        title: client1Title,
        surname: client1Surname,
        dateOfBirth: client1DateOfBirth,
        age: client1Age,
        smoker: client1SmokerStatus,
        client1MaritalStatus: client1MaritalStatus,
        lastnicotinused: client1LastNicotineUse,
        client1CigarettesPerDay: client1CigarettesPerDay,
        hasChildren: hasChildren,
        numberOfChildren: numberOfChildren,
        childrenAges: childrenAges,
        childrenAgeOfFinancialIndependence: childrenAgeOfFinancialIndependence,
        employmentStatus: client1EmploymentStatus,
        client1GrossAnnualIncomePAYE: client1GrossAnnualIncomePAYE,
        client1GrossAnnualIncomeDividends: client1GrossAnnualIncomeDividends,
        client1CompanyType: client1CompanyType,
        client1YearsEstablished: client1YearsEstablished,
        grossAnnualIncome: client1GrossAnnualIncome,
        monthlyBenefits: client1MonthlyBenefits,
        netMonthlyIncome: client1NetMonthlyIncome,
        jobTitle: client1JobTitle,
        sickPay: client1SickPay,
        client1FullSickPayMonths: client1FullSickPayMonths,
        client1HalfSickPayMonths: client1HalfSickPayMonths,
        client1DeathInService: client1DeathInService,
        client1YearsOfSalary: client1YearsOfSalary,
        client1RetirementAge: client1RetirementAge,
        client1OtherIncome: client1OtherIncome,
        client1AdditionalIncomes: client1AdditionalIncomes,
        client1NotesSection2: client1NotesSection2,
      },
      client2: {
        ...versionData.client2,
        firstname: client2FirstName,
        title: client2Title,
        surname: client2Surname,
        dateOfBirth: client2DateOfBirth,
        age: client2Age,
        smoker: client2SmokerStatus,
        lastnicotinused: client2LastNicotineUse,
        client2CigarettesPerDay: client2CigarettesPerDay,
        employmentStatus: client2EmploymentStatus,
        client2GrossAnnualIncomePAYE: client2GrossAnnualIncomePAYE,
        client2GrossAnnualIncomeDividends: client2GrossAnnualIncomeDividends,
        client2YearsEstablished: client2YearsEstablished,
        client2CompanyType: client2CompanyType,
        grossAnnualIncome: client2GrossAnnualIncome,
        monthlyBenefits: client2MonthlyBenefits,
        netMonthlyIncome: client2NetMonthlyIncome,
        jobTitle: client2JobTitle,
        sickPay: client2SickPay,
        client2FullSickPayMonths: client2FullSickPayMonths,
        client2HalfSickPayMonths: client2HalfSickPayMonths,
        client2DeathInService: client2DeathInService,
        client2YearsOfSalary: client2YearsOfSalary,
        client2RetirementAge: client2RetirementAge,
        client2OtherIncome: client2OtherIncome,
        client2AdditionalIncomes: client2AdditionalIncomes,
        client2NotesSection2: client2NotesSection2,
      },
      caseId: caseId,
      regulatoryScriptRead: regulatoryScriptRead,
      factFindDate: factFindDate,
      updateFactFindDate: newDate,
      lookingToCover: lookingToCover,
      lookingToCoverPartner: lookingToCoverPartner,
      housingStatus: housingStatus,
      mortgageType: mortgageType,
      outstandingMortgageAmount: outstandingMortgageAmount,
      outstandingMortgageTerm: outstandingMortgageTerm,
      monthlyMortgagePayment: monthlyMortgagePayment,
      lender: lender,
      interestRate: interestRate,
      rateType: rateType,
      rateDealEndDate: rateDealEndDate,
      monthlyRentalAmount: monthlyRentalAmount,
      livingWithFamilyRentAmount: livingWithFamilyRentAmount,
      showMortgageQuestions: showMortgageQuestions,
      notesSection3: notesSection3,
      hasOtherLoans: hasOtherLoans,
      hasOtherMortgages: hasOtherMortgages,
      otherLoans: otherLoans,
      otherMortgages: otherMortgages,
      monthlyHouseholdIncome: monthlyHouseholdIncome,
      approxMonthlyDisposableIncome: approxMonthlyDisposableIncome,
      howMuchInSavings: howMuchInSavings,
      totalBillsPerMonth: totalBillsPerMonth,
      monthlyLivingCost: monthlyLivingCost,
      hasExistingCover: hasExistingCover,
      additionalCoverSections: additionalCoverSections,
      section6details: section6details,
      covidStatus: covidStatus,
      seriousConditions: seriousConditions,
      otherConditions: otherConditions,
      physicalConditions: physicalConditions,
      mentalHealth: mentalHealth,
      medication: medication,
      familyHistory: familyHistory,
      hobbies: hobbies,
      travelHistory: travelHistory,
      bmi: bmi,
      partnerCovidStatus: partnerCovidStatus,
      partnerSeriousConditions: partnerSeriousConditions,
      partnerOtherConditions: partnerOtherConditions,
      partnerPhysicalConditions: partnerPhysicalConditions,
      partnerMentalHealth: partnerMentalHealth,
      partnerMedication: partnerMedication,
      partnerFamilyHistory: partnerFamilyHistory,
      partnerHobbies: partnerHobbies,
      partnerTravelHistory: partnerTravelHistory,
      partnerBmi: partnerBmi,
      section7details: section7details,
    };
    setUpdateFactFindDate(newDate);
    console.log("Updated Fact Data:", updatedVersionData);

    try {
      const response = await fetch(
        `https://fact-finder.herokuapp.com/api/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedVersionData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Data updated:", data);
        setVersionDataUpdated(data);
        setModalMessage("Fact Finder updated successfully!");
      } else {
        console.error(
          "Error updating data: Server responded with status",
          response.status
        );
        setModalMessage("Oops! Something went wrong.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const closeModal = () => {
    setModalMessage(null);
  };

  // // Version Manager

  // const factfinderData = {
  //   caseId,
  //   regulatoryScriptRead,
  //   factFindDate,
  //   lookingToCover,
  //   lookingToCoverPartner,
  //   client1Title,
  //   client1FirstName,
  //   client1Surname,
  //   client1DateOfBirth,
  //   client1Age,
  //   client1SmokerStatus,
  //   client1LastNicotineUse,
  //   client1CigarettesPerDay,
  //   client1MaritalStatus,
  //   client2Title,
  //   client2FirstName,
  //   client2Surname,
  //   client2DateOfBirth,
  //   client2Age,
  //   client2SmokerStatus,
  //   client2LastNicotineUse,
  //   client2CigarettesPerDay,
  //   hasChildren,
  //   numberOfChildren,
  //   childrenAges,
  //   childrenAgeOfFinancialIndependence,
  //   client1EmploymentStatus,
  //   client1GrossAnnualIncomePAYE,
  //   client1GrossAnnualIncomeDividends,
  //   client1CompanyType,
  //   client1YearsEstablished,
  //   client1GrossAnnualIncome,
  //   client1MonthlyBenefits,
  //   client1NetMonthlyIncome,
  //   client1JobTitle,
  //   client1SickPay,
  //   client1FullSickPayMonths,
  //   client1HalfSickPayMonths,
  //   client1DeathInService,
  //   client1YearsOfSalary,
  //   client1RetirementAge,
  //   client1OtherIncome,
  //   additionalCoverSections: additionalCoverSections.map((section) => ({
  //     policyType: section.policyType,
  //     personCovered: section.personCovered,
  //     coverAmount: section.coverAmount,
  //     coverTerm: section.coverTerm,
  //     insurer: section.insurer,
  //     whenSetUp: section.whenSetUp,
  //     premium: section.premium,
  //   })),
  //   client1AdditionalIncomes: client1AdditionalIncomes
  //     ? client1AdditionalIncomes.map((income) => ({
  //         amount: income.amount,
  //         source: income.source,
  //       }))
  //     : [],
  //   client1NotesSection2,
  //   client2EmploymentStatus,
  //   client2CompanyType,
  //   client2YearsEstablished,
  //   client2GrossAnnualIncomePAYE,
  //   client2GrossAnnualIncomeDividends,
  //   client2GrossAnnualIncome,
  //   client2MonthlyBenefits,
  //   client2NetMonthlyIncome,
  //   client2JobTitle,
  //   client2SickPay,
  //   client2FullSickPayMonths,
  //   client2HalfSickPayMonths,
  //   client2DeathInService,
  //   client2YearsOfSalary,
  //   client2RetirementAge,
  //   client2OtherIncome,
  //   client2AdditionalIncomes: client2AdditionalIncomes
  //     ? client2AdditionalIncomes.map((income) => ({
  //         amount: income.amount,
  //         source: income.source,
  //       }))
  //     : [],
  //   client2NotesSection2,
  //   housingStatus,
  //   mortgageType,
  //   outstandingMortgageAmount,
  //   outstandingMortgageTerm,
  //   monthlyMortgagePayment,
  //   lender,
  //   interestRate,
  //   rateType,
  //   rateDealEndDate,
  //   monthlyRentalAmount,
  //   livingWithFamilyRentAmount,
  //   showMortgageQuestions,
  //   showRentQuestions,
  //   showLivingWithFamilyQuestions,
  //   notesSection3,
  //   hasOtherMortgages,
  //   otherMortgages: otherMortgages
  //     ? otherMortgages.map((mortgage) => ({
  //         mortgageType2: mortgage.mortgageType2,
  //         mortgageAmountOutstanding: mortgage.mortgageAmountOutstanding,
  //         monthlyMortgageTerm: mortgage.monthlyMortgageTerm,
  //         monthlyMortgagePayment2: mortgage.monthlyMortgagePayment2,
  //         mortgageLender: mortgage.mortgageLender,
  //         mortgageInterestRate: mortgage.mortgageInterestRate,
  //         mortgageRateType: mortgage.mortgageRateType,
  //         mortgageRateDealEnd: mortgage.mortgageRateDealEnd,
  //       }))
  //     : [],
  //   hasOtherLoans,
  //   otherLoans: otherLoans
  //     ? otherLoans.map((loan) => ({
  //         loanType: loan.loanType,
  //         loanAmountOutstanding: loan.loanAmountOutstanding,
  //         loanTermOutstanding: loan.loanTermOutstanding,
  //         monthlyLoanPayment: loan.monthlyLoanPayment,
  //       }))
  //     : [],
  //   monthlyHouseholdIncome,
  //   monthlyMortgageRentLoanAmount,
  //   combinedMonthlyAmount,
  //   totalBillsPerMonth,
  //   monthlyLivingCost,
  //   approxMonthlyDisposableIncome,
  //   howMuchInSavings,
  //   hasExistingCover,
  //   aadditionalCoverSections: additionalCoverSections
  //     ? additionalCoverSections.map((section) => ({
  //         policyType: section.policyType,
  //         personCovered: section.personCovered,
  //         coverAmount: section.coverAmount,
  //         coverTerm: section.coverTerm,
  //         insurer: section.insurer,
  //         whenSetUp: section.whenSetUp,
  //         premium: section.premium,
  //       }))
  //     : [],
  //   section6details,
  //   covidStatus,
  //   covidStatusDetails,
  //   partnerCovidStatus,
  //   partnerCovidStatusDetails,
  //   seriousConditions,
  //   seriousConditionsDetails,
  //   partnerSeriousConditions,
  //   partnerSeriousConditionsDetails,
  //   otherConditions,
  //   otherConditionsDetails,
  //   partnerOtherConditions,
  //   partnerOtherConditionsDetails,
  //   physicalConditions,
  //   physicalConditionsDetails,
  //   partnerPhysicalConditions,
  //   partnerPhysicalConditionsDetails,
  //   mentalHealth,
  //   mentalHealthDetails,
  //   partnerMentalHealth,
  //   partnerMentalHealthDetails,
  //   medication,
  //   medicationDetails,
  //   partnerMedication,
  //   partnerMedicationDetails,
  //   familyHistory,
  //   familyHistoryDetails,
  //   partnerFamilyHistory,
  //   partnerFamilyHistoryDetails,
  //   hobbies,
  //   hobbiesDetails,
  //   partnerHobbies,
  //   partnerHobbiesDetails,
  //   travelHistory,
  //   travelHistoryDetails,
  //   partnerTravelHistory,
  //   partnerTravelHistoryDetails,
  //   bmi,
  //   bmiDetails,
  //   partnerBmi,
  //   partnerBmiDetails,
  //   travelPast5Years,
  //   travelPast5YearsDetails,
  //   partnerTravelPast5Years,
  //   partnerTravelPast5YearsDetails,
  //   rideBikes,
  //   rideBikesDetails,
  //   partnerRideBikes,
  //   partnerRideBikesDetails,
  //   currentSize,
  //   partnerCurrentSize,
  //   potentiallyVulnerable,
  //   potentiallyVulnerableDetails,
  //   partnerPotentiallyVulnerable,
  //   partnerPotentiallyVulnerableDetails,
  // };

  // // Make the POST request for version manager
  // const saveFactVersion = async () => {
  //   try {
  //     const response = await axios.post(
  //       "https://fact-finder.herokuapp.com/api/factversions",
  //       factfinderData
  //     );
  //     console.log("FactVersion saved successfully:", response.data);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  return (
    <>
      <Header isLoggedIn={isLoggedIn} />
      <Sidebar expanded={expanded} setExpanded={setExpanded} caseId={caseId} />
      <div
        style={{
          marginLeft: expanded ? 200 : 50,
          transition: "margin-left 0.3s ease",
        }}
      >
        <div className="App">
          <form onSubmit={handleSubmit}>
            {/*  <==Section 1 code==> */}
            <div className="form-section">
              <div>
                <label htmlFor="caseid">Case Id?</label>
                <input
                  type="text"
                  name="caseid"
                  id="caseid"
                  value={caseId}
                  onChange={handleCaseIdChange}
                />
              </div>
              <div>
                <label htmlFor="regulatoryScriptRead">
                  Was regulatory script read to client?
                  <InfoIcon
                    style={{ width: "20px", height: "20px", marginLeft: "5px" }}
                    data-tip
                    data-for="regulatoryScriptRead"
                  />
                  <ReactTooltip
                    id="regulatoryScriptRead"
                    className="custom-tooltip"
                    effect="solid"
                    multiline={true}
                  >
                    <ul>
                      <p>
                        When you made your enquiry, you were sent a link to our
                        Privacy Policy and Terms of Business. I’m not sure if
                        you’ve managed to have a look at these, so just in case
                        you haven’t I’ll make you aware of a few important
                        things!
                      </p>
                      <li>
                        As explained, we are Blue Light Insurance Solutions, a
                        trading name of Lifetime Financial Management who are
                        authorised and regulated by the Financial Conduct
                        Authority.
                      </li>
                      <li>
                        We specialise in helping NHS, Emergency Services, Civil
                        Service and Public Sector staff, and are regulated to
                        provide you with insurance advice.
                      </li>
                      <li>
                        This means we’re not just a call centre that gives you
                        quotes and then calls you ten times a day, we’re a
                        dedicated team of advisers who take the time to
                        understand your personal circumstances and then provide
                        bespoke insurance recommendations based on that.
                      </li>
                      <li>
                        {" "}
                        We do not charge fees for our service, however if you do
                        decide to take out cover through us, we are paid
                        commission by the insurer.
                      </li>
                      <li>
                        {" "}
                        We offer products from a very large number of insurers,
                        and unlike many of our competitors we are not tied to
                        offering cover from one insurer. This means we’ll always
                        recommend the insurer that is best for YOU!
                      </li>
                      <li>
                        Lastly, I hope this won’t be the case, but if you ever
                        did want to make a complaint about our service, then the
                        information you need to do this can be found on our
                        terms of business document.
                      </li>
                      <p>
                        Does that all make sense, or do you have any questions?
                      </p>
                    </ul>
                  </ReactTooltip>
                </label>
                <select
                  id="regulatoryScriptRead"
                  value={regulatoryScriptRead}
                  onChange={handleRegulatoryScriptReadChange}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div>
                <label htmlFor="factFindDate">Fact Find Date</label>
                <input
                  type="date"
                  id="factFindDate"
                  value={factFindDate}
                  readOnly
                />
              </div>
              <div>
                <label htmlFor="updateFactFindDate">
                  Update Fact Find Date
                </label>
                <input
                  type="date"
                  id="updateFactFindDate"
                  value={updateFactFindDate}
                  onChange={(e) => setUpdateFactFindDate(e.target.value)}
                />
              </div>
            </div>
            <div className="form-section">
              <h2>Section 1 - Basic Info / Family</h2>
              <div>
                <label htmlFor="lookingToCover">
                  What are you looking to cover?
                </label>
                <ResizableTextarea
                  type="text"
                  name="lookingtocover"
                  id="lookingToCover"
                  value={lookingToCover}
                  onChange={handleLookingToCoverChange}
                />
              </div>
              <div>
                <label htmlFor="lookingToCoverPartner">
                  Looking to cover partner?
                </label>
                <select
                  id="lookingToCoverPartner"
                  value={lookingToCoverPartner}
                  onChange={handleLookingToCoverPartnerChange}
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
              <div
                className={lookingToCoverPartner === "Yes" ? "client-info" : ""}
              >
                <h3>Client 1</h3>
                <div>
                  <label htmlFor="client1Title">Title</label>
                  <select
                    id="client1Title"
                    value={client1Title}
                    onChange={handleClient1TitleChange}
                  >
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Ms">Ms</option>
                    <option value="Dr">Dr</option>
                    <option value="Prof">Prof</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="client1FirstName">First Name</label>
                  <input
                    type="text"
                    id="client1FirstName"
                    value={client1FirstName}
                    onChange={handleClient1FirstNameChange}
                  />
                </div>
                <div>
                  <label htmlFor="client1Surname">Surname</label>
                  <input
                    type="text"
                    id="client1Surname"
                    value={client1Surname}
                    onChange={handleClient1SurnameChange}
                  />
                </div>
                <div>
                  <label htmlFor="client1DateOfBirth">Date of Birth</label>
                  <input
                    type="date"
                    id="client1DateOfBirth"
                    value={client1DateOfBirth}
                    onChange={handleClient1DateOfBirthChange}
                  />
                </div>
                <div>
                  <label htmlFor="client1Age">Age</label>
                  <input
                    type="number"
                    id="client1Age"
                    value={client1Age}
                    onChange={handleClient1AgeChange}
                  />
                </div>
                <div>
                  <label htmlFor="client1SmokerStatus">Smoker Status</label>
                  <select
                    id="client1SmokerStatus"
                    value={client1SmokerStatus}
                    onChange={handleClient1SmokerStatusChange}
                  >
                    <option value="Smoker">Smoker</option>
                    <option value="Non Smoker">Non Smoker</option>
                    <option value="Ex Smoker">Ex Smoker</option>
                    <option value="Vaper">Vaper</option>
                  </select>
                </div>
                {client1SmokerStatus === "Ex Smoker" && (
                  <div>
                    <label htmlFor="client1LastNicotineUse">
                      When did you last use nicotine?
                    </label>
                    <input
                      type="date"
                      id="client1LastNicotineUse"
                      value={client1LastNicotineUse}
                      onChange={handleClient1LastNicotineUseChange}
                    />
                  </div>
                )}
                {client1SmokerStatus === "Smoker" && (
                  <div>
                    <label htmlFor="client1CigarettesPerDay">
                      How many cigarettes per day?
                    </label>
                    <input
                      type="text"
                      id="client1CigarettesPerDay"
                      value={client1CigarettesPerDay}
                      onChange={handleClient1CigarettesPerDayChange}
                    />
                  </div>
                )}
                <div>
                  <label htmlFor="client1MaritalStatus">Marital Status</label>
                  <select
                    id="client1MaritalStatus"
                    value={client1MaritalStatus}
                    onChange={handleClient1MaritalStatusChange}
                  >
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Cohabiting">Cohabiting</option>
                    <option value="Civil Partnership">Civil Partnership</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Separated">Separated</option>
                    <option value="Engaged">Engaged</option>
                    <option value="Widowed">Widowed</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              {lookingToCoverPartner === "Yes" && (
                <div className="client-info">
                  <h3>Client 2</h3>
                  <div>
                    <label htmlFor="client2Title">Title</label>
                    <select
                      id="client2Title"
                      value={client2Title}
                      onChange={handleClient2TitleChange}
                    >
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                      <option value="Ms">Ms</option>
                      <option value="Dr">Dr</option>
                      <option value="Prof">Prof</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="client2FirstName">First Name</label>
                    <input
                      type="text"
                      id="client2FirstName"
                      value={client2FirstName}
                      onChange={handleClient2FirstNameChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="client2Surname">Surname</label>
                    <input
                      type="text"
                      id="client2Surname"
                      value={client2Surname}
                      onChange={handleClient2SurnameChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="client2DateOfBirth">Date of Birth</label>
                    <input
                      type="date"
                      id="client2DateOfBirth"
                      value={client2DateOfBirth}
                      onChange={handleClient2DateOfBirthChange}
                      onBlur={handleClient2DateOfBirthBlur}
                    />
                  </div>
                  <div>
                    <label htmlFor="client2Age">Age</label>
                    <input
                      type="number"
                      id="client2Age"
                      value={client2Age}
                      onChange={handleClient2AgeChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="client2SmokerStatus">Smoker Status</label>
                    <select
                      id="client2SmokerStatus"
                      value={client2SmokerStatus}
                      onChange={handleClient2SmokerStatusChange}
                    >
                      <option value="Smoker">Smoker</option>
                      <option value="Non Smoker">Non Smoker</option>
                      <option value="Ex Smoker">Ex Smoker</option>
                      <option value="Vaper">Vaper</option>
                    </select>
                  </div>
                  {client2SmokerStatus === "Ex Smoker" && (
                    <div>
                      <label htmlFor="client2LastNicotineUse">
                        When did you last use nicotine?
                      </label>
                      <input
                        type="date"
                        id="client2LastNicotineUse"
                        value={client2LastNicotineUse}
                        onChange={handleClient2LastNicotineUseChange}
                      />
                    </div>
                  )}
                  {client2SmokerStatus === "Smoker" && (
                    <div>
                      <label htmlFor="client2CigarettesPerDay">
                        How many cigarettes per day?
                      </label>
                      <input
                        type="text"
                        id="client2CigarettesPerDay"
                        value={client2CigarettesPerDay}
                        onChange={handleClient2CigarettesPerDayChange}
                      />
                    </div>
                  )}
                </div>
              )}
              <div
                className={lookingToCoverPartner === "Yes" ? "client-info" : ""}
              >
                <div>
                  <label htmlFor="hasChildren">Do you have children?</label>
                  <select
                    id="hasChildren"
                    value={hasChildren}
                    onChange={handleHasChildrenChange}
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                    <option value="Expecting">Expecting</option>
                  </select>
                </div>
                {hasChildren === "Yes" && (
                  <div>
                    <label htmlFor="numberOfChildren">How many?</label>
                    <select
                      value={numberOfChildren}
                      onChange={handleNumberOfChildrenChange}
                    >
                      <option value="0">No</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                    {Array.from(
                      { length: parseInt(numberOfChildren) },
                      (_, index) => (
                        <div key={`child-${index + 1}`}>
                          <label htmlFor={`childAge${index + 1}`}>
                            Child {index + 1} Age:
                          </label>
                          <input
                            type="number"
                            id={`childAge${index + 1}`}
                            name={`childAge${index + 1}`}
                            value={childrenAges[index] || ""}
                            min="0"
                            max="99"
                            onChange={(event) =>
                              handleChildAgeChange(event, index)
                            }
                            required
                          />
                        </div>
                      )
                    )}
                    {(hasChildren === "Yes" || hasChildren === "Expecting") && (
                      <div>
                        <label htmlFor="childrenAgeOfFinancialIndependence">
                          Children age of financial independence
                        </label>
                        <input
                          type="text"
                          id="childrenAgeOfFinancialIndependence"
                          value={childrenAgeOfFinancialIndependence}
                          onChange={
                            handleChildrenAgeOfFinancialIndependenceChange
                          }
                          required
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/*  <==Section 2 code==> */}
              <div className="form-section">
                <h2>Section 2 - Job / Income</h2>
                <div>
                  <div
                    className={
                      lookingToCoverPartner === "Yes" ? "client-info" : ""
                    }
                  >
                    <h3>Client 1</h3>
                    <div>
                      <label htmlFor="client1JobTitle">Job Title</label>
                      <input
                        type="text"
                        id="client1JobTitle"
                        value={client1JobTitle}
                        onChange={handleClient1JobTitleChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="client1EmploymentStatus">
                        Employment Status
                      </label>
                      <select
                        id="client1EmploymentStatus"
                        value={client1EmploymentStatus}
                        onChange={handleClient1EmploymentStatusChange}
                      >
                        <option value="Employed (Full Time)">
                          Employed (Full Time)
                        </option>
                        <option value="Employed (Part Time)">
                          Employed (Part Time)
                        </option>
                        <option value="Self Employed">Self Employed</option>
                        <option value="Employed (Own Business)">
                          Employed (Own Business)
                        </option>
                        <option value="Unemployed">Unemployed</option>
                        <option value="Retired">Retired</option>
                        <option value="Medically Retired">
                          Medically Retired
                        </option>
                      </select>
                    </div>
                    {client1EmploymentStatus === "Employed (Full Time)" ||
                    client1EmploymentStatus === "Employed (Part Time)" ||
                    client1EmploymentStatus === "Self Employed" ||
                    client1EmploymentStatus === "Employed (Own Business)" ? (
                      <>
                        {client1EmploymentStatus === "Self Employed" && (
                          <>
                            <div>
                              <label htmlFor="client1CompanyType">
                                Ltd company or sole trader?
                              </label>
                              <input
                                type="text"
                                id="client1CompanyType"
                                value={client1CompanyType}
                                onChange={handleClient1CompanyTypeChange}
                              />
                            </div>
                            <div>
                              <label htmlFor="client1yearsEstablished">
                                How many years established
                              </label>
                              <input
                                type="number"
                                id="client1YearsEstablished"
                                value={client1YearsEstablished}
                                onChange={handleClient1YearsEstablishedChange}
                              />
                            </div>
                          </>
                        )}
                        {client1EmploymentStatus !==
                          "Employed (Own Business)" && (
                          <div>
                            <label htmlFor="client1GrossAnnualIncome">
                              {client1EmploymentStatus === "Self Employed"
                                ? "Gross Annual Income (per tax return)"
                                : "Gross Annual Income"}
                            </label>
                            <input
                              type="number"
                              id="client1GrossAnnualIncome"
                              value={client1GrossAnnualIncome}
                              onChange={handleClient1GrossAnnualIncomeChange}
                            />
                          </div>
                        )}

                        {client1EmploymentStatus ===
                          "Employed (Own Business)" && (
                          <>
                            <div>
                              <label htmlFor="client1GrossAnnualIncomePAYE">
                                Gross Annual Income (PAYE)
                              </label>
                              <input
                                type="number"
                                id="client1GrossAnnualIncomePAYE"
                                value={client1GrossAnnualIncomePAYE}
                                onChange={
                                  handleClient1GrossAnnualIncomePAYEChange
                                }
                              />
                            </div>
                            <div>
                              <label htmlFor="client1GrossAnnualIncomeDividends">
                                Gross Annual Income (Dividends)
                              </label>
                              <input
                                type="number"
                                id="client1GrossAnnualIncomeDividends"
                                value={client1GrossAnnualIncomeDividends}
                                onChange={
                                  handleClient1GrossAnnualIncomeDividendsChange
                                }
                              />
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div>
                        <label htmlFor="client1MonthlyBenefits">
                          Approx. monthly benefits
                        </label>
                        <input
                          type="number"
                          id="client1MonthlyBenefits"
                          value={client1MonthlyBenefits}
                          onChange={handleClient1MonthlyBenefitsChange}
                        />
                      </div>
                    )}
                    <div>
                      <label htmlFor="client1NetMonthlyIncome">
                        Net Monthly Income
                      </label>
                      <input
                        type="number"
                        id="client1NetMonthlyIncome"
                        value={client1NetMonthlyIncome}
                        onChange={handleClient1NetMonthlyIncomeChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="client1SickPay">
                        Do you get sick pay?
                      </label>
                      <select
                        id="client1SickPay"
                        value={client1SickPay}
                        onChange={handleClient1SickPayChange}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Not Sure">Not Sure</option>
                      </select>
                      {client1SickPay === "Yes" && (
                        <>
                          <div>
                            <label htmlFor="client1FullSickPayMonths">
                              How many months of full sick pay do you get?
                            </label>
                            <input
                              type="number"
                              id="client1FullSickPayMonths"
                              value={client1FullSickPayMonths}
                              onChange={handleClient1FullSickPayMonthsChange}
                            />
                          </div>
                          <div>
                            <label htmlFor="client1HalfSickPayMonths">
                              How many months of half sick pay do you get?
                            </label>
                            <input
                              type="number"
                              id="client1HalfSickPayMonths"
                              value={client1HalfSickPayMonths}
                              onChange={handleClient1HalfSickPayMonthsChange}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <label htmlFor="client1DeathInService">
                        Do you get death in service?
                      </label>
                      <select
                        id="client1DeathInService"
                        value={client1DeathInService}
                        onChange={handleClient1DeathInServiceChange}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Not Sure">Not Sure</option>
                      </select>
                    </div>
                    {client1DeathInService === "Yes" && (
                      <div>
                        <label htmlFor="client1YearsOfSalary">
                          How many years of salary?
                        </label>
                        <input
                          type="number"
                          id="client1YearsOfSalary"
                          value={client1YearsOfSalary}
                          onChange={handleClient1YearsOfSalaryChange}
                        />
                      </div>
                    )}
                    <div>
                      <label htmlFor="client1RetirementAge">
                        Intended retirement age?
                      </label>
                      <input
                        type="number"
                        id="client1RetirementAge"
                        value={client1RetirementAge}
                        onChange={handleClient1RetirementAgeChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="client1OtherIncome">
                        Do you earn income from anywhere else?
                      </label>
                      <select
                        id="client1OtherIncome"
                        value={client1OtherIncome}
                        onChange={handleClient1OtherIncomeChange}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Not Sure">Not Sure</option>
                      </select>
                      {client1OtherIncome === "Yes" && (
                        <div>
                          {client1AdditionalIncomes.map((income, index) => (
                            <div key={index}>
                              <h3>Additional Income {index + 1}</h3>

                              <div className="input-group">
                                <label>Enter amount</label>
                                <input
                                  type="number"
                                  value={income.amount}
                                  onChange={(event) =>
                                    handleClient1AdditionalIncomeAmountChange(
                                      event,
                                      index
                                    )
                                  }
                                  placeholder="£"
                                />
                              </div>
                              <div className="input-group">
                                <label>What is the source?</label>
                                <input
                                  type="text"
                                  value={income.source}
                                  onChange={(event) =>
                                    handleClient1AdditionalIncomeSourceChange(
                                      event,
                                      index
                                    )
                                  }
                                />
                              </div>

                              {index ===
                                client1AdditionalIncomes.length - 1 && (
                                <button
                                  onClick={handleAddClient1AdditionalIncome}
                                >
                                  Add another
                                </button>
                              )}

                              {index !== 0 && (
                                <button
                                  onClick={() =>
                                    handleRemoveClient1AdditionalIncome(index)
                                  }
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="input-group">
                      <label>Notes </label>
                      <ResizableTextarea
                        id="client1NotesSection2"
                        value={client1NotesSection2}
                        onChange={handleClient1NotesSection2Change}
                      />
                    </div>
                  </div>

                  {lookingToCoverPartner === "Yes" && (
                    <div className="client-info">
                      <h3>Client 2</h3>
                      {/* Client 2 questions */}
                      <div>
                        <label htmlFor="client2JobTitle">Job Title</label>
                        <input
                          type="text"
                          id="client2JobTitle"
                          value={client2JobTitle}
                          onChange={handleClient2JobTitleChange}
                        />
                      </div>
                      <div>
                        <div>
                          <label htmlFor="client2EmploymentStatus">
                            Employment Status
                          </label>
                          <select
                            id="client2EmploymentStatus"
                            value={client2EmploymentStatus}
                            onChange={handleClient2EmploymentStatusChange}
                          >
                            <option value="Employed (Full Time)">
                              Employed (Full Time)
                            </option>
                            <option value="Employed (Part Time)">
                              Employed (Part Time)
                            </option>
                            <option value="Self Employed">Self Employed</option>
                            <option value="Employed (Own Business)">
                              Employed (Own Business)
                            </option>
                            <option value="Unemployed">Unemployed</option>
                            <option value="Retired">Retired</option>
                            <option value="Medically Retired">
                              Medically Retired
                            </option>
                          </select>
                        </div>
                        {client2EmploymentStatus === "Employed (Full Time)" ||
                        client2EmploymentStatus === "Employed (Part Time)" ||
                        client2EmploymentStatus === "Self Employed" ||
                        client2EmploymentStatus ===
                          "Employed (Own Business)" ? (
                          <>
                            {client2EmploymentStatus === "Self Employed" && (
                              <>
                                <div>
                                  <label htmlFor="client2CompanyType">
                                    Ltd company or sole trader?
                                  </label>
                                  <input
                                    type="text"
                                    id="client2CompanyType"
                                    value={client2CompanyType}
                                    onChange={handleClient2CompanyTypeChange}
                                  />
                                </div>
                                <div>
                                  <label htmlFor="client2yearsEstablished">
                                    How many years established?
                                  </label>
                                  <input
                                    type="number"
                                    id="client2yearsEstablished"
                                    value={client2YearsEstablished}
                                    onChange={
                                      handleClient2YearsEstablishedChange
                                    }
                                  />
                                </div>
                              </>
                            )}
                            {client2EmploymentStatus !==
                              "Employed (Own Business)" && (
                              <div>
                                <label htmlFor="client2GrossAnnualIncome">
                                  {client2EmploymentStatus === "Self Employed"
                                    ? "Gross Annual Income (per tax return)"
                                    : "Gross Annual Income"}
                                </label>
                                <input
                                  type="number"
                                  id="client2GrossAnnualIncome"
                                  value={client2GrossAnnualIncome}
                                  onChange={
                                    handleClient2GrossAnnualIncomeChange
                                  }
                                />
                              </div>
                            )}

                            {client2EmploymentStatus ===
                              "Employed (Own Business)" && (
                              <>
                                <div>
                                  <label htmlFor="client2GrossAnnualIncomePAYE">
                                    Gross Annual Income (PAYE)
                                  </label>
                                  <input
                                    type="number"
                                    id="client2GrossAnnualIncomePAYE"
                                    value={client2GrossAnnualIncomePAYE}
                                    onChange={
                                      handleClient2GrossAnnualIncomePAYEChange
                                    }
                                  />
                                </div>
                                <div>
                                  <label htmlFor="client2GrossAnnualIncomeDividends">
                                    Gross Annual Income (Dividends)
                                  </label>
                                  <input
                                    type="number"
                                    id="client2GrossAnnualIncomeDividends"
                                    value={client2GrossAnnualIncomeDividends}
                                    onChange={
                                      handleClient2GrossAnnualIncomeDividendsChange
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <div>
                            <label htmlFor="client2MonthlyBenefits">
                              Approx. monthly benefits
                            </label>
                            <input
                              type="number"
                              id="client2MonthlyBenefits"
                              value={client2MonthlyBenefits}
                              onChange={handleClient2MonthlyBenefitsChange}
                            />
                          </div>
                        )}
                        <div>
                          <label htmlFor="client2NetMonthlyIncome">
                            Net Monthly Income
                          </label>
                          <input
                            type="number"
                            id="client2NetMonthlyIncome"
                            value={client2NetMonthlyIncome}
                            onChange={handleClient2NetMonthlyIncomeChange}
                          />
                        </div>
                        <div>
                          <label htmlFor="client2SickPay">
                            Do you get sick pay?
                          </label>
                          <select
                            id="client2SickPay"
                            value={client2SickPay}
                            onChange={handleClient2SickPayChange}
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="Not Sure">Not Sure</option>
                          </select>
                          {client2SickPay === "Yes" && (
                            <>
                              <div>
                                <label htmlFor="client2FullSickPayMonths">
                                  How many months of full sick pay do you get?
                                </label>
                                <input
                                  type="number"
                                  id="client2FullSickPayMonths"
                                  value={client2FullSickPayMonths}
                                  onChange={
                                    handleClient2FullSickPayMonthsChange
                                  }
                                />
                              </div>
                              <div>
                                <label htmlFor="client2HalfSickPayMonths">
                                  How many months of half sick pay do you get?
                                </label>
                                <input
                                  type="number"
                                  id="client2HalfSickPayMonths"
                                  value={client2HalfSickPayMonths}
                                  onChange={
                                    handleClient2HalfSickPayMonthsChange
                                  }
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div>
                          <label htmlFor="client2DeathInService">
                            Do you get death in service?
                          </label>
                          <select
                            id="client2DeathInService"
                            value={client2DeathInService}
                            onChange={handleClient2DeathInServiceChange}
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="Not Sure">Not Sure</option>
                          </select>
                        </div>
                        {client2DeathInService === "Yes" && (
                          <div>
                            <label htmlFor="client2YearsOfSalary">
                              How many years of salary?
                            </label>
                            <input
                              type="number"
                              id="client2YearsOfSalary"
                              value={client2YearsOfSalary}
                              onChange={handleClient2YearsOfSalaryChange}
                            />
                          </div>
                        )}
                        <div>
                          <label htmlFor="client2RetirementAge">
                            Intended retirement age?
                          </label>
                          <input
                            type="number"
                            id="client2RetirementAge"
                            value={client2RetirementAge}
                            onChange={handleClient2RetirementAgeChange}
                          />
                        </div>
                        <div>
                          <label htmlFor="client2OtherIncome">
                            Do you earn income from anywhere else?
                          </label>
                          <select
                            id="client2OtherIncome"
                            value={client2OtherIncome}
                            onChange={handleClient2OtherIncomeChange}
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="Not Sure">Not Sure</option>
                          </select>
                          {client2OtherIncome === "Yes" && (
                            <div>
                              {client2OtherIncome === "Yes" && (
                                <div>
                                  {client2AdditionalIncomes.map(
                                    (income, index) => (
                                      <div key={index}>
                                        <h3>Additional Income {index + 1}</h3>

                                        <div className="input-group">
                                          <label>Enter amount</label>
                                          <input
                                            type="number"
                                            value={income.amount}
                                            onChange={(event) =>
                                              handleClient2AdditionalIncomeAmountChange(
                                                event,
                                                index
                                              )
                                            }
                                            placeholder="£"
                                          />
                                        </div>
                                        <div className="input-group">
                                          <label>What is the source?</label>
                                          <input
                                            type="text"
                                            value={income.source}
                                            onChange={(event) =>
                                              handleClient2AdditionalIncomeSourceChange(
                                                event,
                                                index
                                              )
                                            }
                                          />
                                        </div>

                                        {index ===
                                          client2AdditionalIncomes.length -
                                            1 && (
                                          <button
                                            onClick={
                                              handleAddClient2AdditionalIncome
                                            }
                                          >
                                            Add another
                                          </button>
                                        )}

                                        {index !== 0 && (
                                          <button
                                            onClick={() =>
                                              handleRemoveClient2AdditionalIncome(
                                                index
                                              )
                                            }
                                          >
                                            Remove
                                          </button>
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="input-group">
                        <label>Notes </label>
                        <ResizableTextarea
                          id="client2NotesSection2"
                          value={client2NotesSection2}
                          onChange={handleClient2NotesSection2Change}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/*  <==Section 3 code==> */}
              <div className="form-section">
                <h2>Section 3 - Rent / Mortgage</h2>
                <div className="input-group">
                  <label htmlFor="housingStatus">
                    Do you own a home or rent?
                  </label>
                  <select
                    id="housingStatus"
                    value={housingStatus}
                    onChange={handleHousingStatusChange}
                  >
                    <option value="">Please select</option>
                    <option value="Mortgage">Mortgage</option>
                    <option value="Renting">Renting</option>
                    <option value="Own Outright">Own Outright</option>
                    <option value="Living with family">
                      Living with family
                    </option>
                  </select>
                </div>
                {showMortgageQuestions && (
                  <div className="mortgage-questions">
                    <div className="input-group">
                      <label htmlFor="mortgageType">Mortgage type</label>
                      <select
                        id="mortgageType"
                        value={mortgageType}
                        onChange={handleMortgageTypeChange}
                      >
                        <option value="">Please select</option>
                        <option value="Repayment">Repayment</option>
                        <option value="Interest only">Interest only</option>
                        <option value="help to buy">Help to buy</option>
                      </select>
                    </div>
                    <div className="input-group">
                      <label htmlFor="outstandingMortgageAmount">
                        Outstanding Mortgage Amount
                      </label>
                      <input
                        id="outstandingMortgageAmount"
                        type="number"
                        placeholder="£"
                        value={outstandingMortgageAmount}
                        onChange={handleOutstandingMortgageAmountChange}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="outstandingMortgageTerm">
                        Outstanding Mortgage Term
                      </label>
                      <input
                        id="outstandingMortgageTerm"
                        type="number"
                        placeholder="years"
                        value={outstandingMortgageTerm}
                        onChange={handleOutstandingMortgageTermChange}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="monthlyMortgagePayment">
                        Monthly Mortgage Payment
                      </label>
                      <input
                        id="monthlyMortgagePayment"
                        type="number"
                        value={monthlyMortgagePayment}
                        onChange={handleMonthlyMortgagePaymentChange}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="lender">Lender</label>
                      <input
                        id="lender"
                        type="text"
                        value={lender}
                        onChange={handleLenderChange}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="interestRate">Interest Rate</label>
                      <input
                        id="interestRate"
                        type="number"
                        value={interestRate}
                        onChange={handleInterestRateChange}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="rateType">Rate type</label>
                      <select
                        id="rateType"
                        value={rateType}
                        onChange={handleRateTypeChange}
                      >
                        <option value="">Please select</option>
                        <option value="Fixed">Fixed</option>
                        <option value="Variable">Variable</option>
                      </select>
                    </div>
                    <div className="input-group">
                      <label htmlFor="rateDealEndDate">
                        When does rate-deal end?
                      </label>
                      <input
                        id="rateDealEndDate"
                        type="date"
                        value={rateDealEndDate}
                        onChange={handleRateDealEndDateChange}
                      />
                    </div>
                  </div>
                )}
                {showRentQuestions && (
                  <div className="rent-questions">
                    <div className="input-group">
                      <label htmlFor="monthlyRentalAmount">
                        Monthly Rental Amount
                      </label>
                      <input
                        id="monthlyRentalAmount"
                        type="number"
                        value={monthlyRentalAmount}
                        onChange={handleMonthlyRentalAmountChange}
                      />
                      <span className="pound-sign">£</span>
                    </div>
                  </div>
                )}
                {showLivingWithFamilyQuestions && (
                  <div className="living-with-family-questions">
                    <div className="input-group">
                      <label htmlFor="livingWithFamilyRentAmount">
                        Do you pay rent? How much?
                      </label>
                      <input
                        id="livingWithFamilyRentAmount"
                        type="number"
                        value={livingWithFamilyRentAmount}
                        onChange={handleLivingWithFamilyRentAmountChange}
                      />
                      <span className="pound-sign">£</span>
                    </div>
                  </div>
                )}
                <div className="input-group">
                  <label>Notes </label>
                  <ResizableTextarea
                    id="notesSection3"
                    value={notesSection3}
                    onChange={handleNotesSection3Change}
                  />
                </div>
              </div>
              {/* Section 4 */}
              <div className="form-section">
                <h2>Section 4 - Other Financial liabilities</h2>
                <div>
                  <div className="input-group">
                    <label>
                      Do you have any other mortgages (e.g. buy to let)?
                    </label>
                    <select
                      value={hasOtherMortgages}
                      onChange={handleHasOtherMortgagesChange}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Not Sure">Not Sure</option>
                    </select>
                  </div>
                  {hasOtherMortgages === "Yes" && (
                    <div>
                      {otherMortgages.map((mortgage, index) => (
                        <div key={mortgage.id}>
                          <h3>Mortgage {index + 1}</h3>
                          <div className="input-group">
                            <label>Mortgage Type</label>
                            <select
                              value={mortgage.mortgageType2}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "mortgageType2",
                                  e.target.value
                                )
                              }
                            >
                              <option value="Repayment">Repayment</option>
                              <option value="Interest Only">
                                Interest Only
                              </option>
                            </select>
                          </div>
                          <div className="input-group">
                            <label>Mortgage Amount Outstanding</label>
                            <input
                              type="number"
                              value={mortgage.mortgageAmountOutstanding}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "mortgageAmountOutstanding",
                                  e.target.value
                                )
                              }
                              placeholder="£"
                            />
                          </div>
                          <div className="input-group">
                            <label>Monthly Mortgage Term</label>
                            <input
                              type="number"
                              value={mortgage.monthlyMortgageTerm}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "monthlyMortgageTerm",
                                  e.target.value
                                )
                              }
                              placeholder="years"
                            />
                          </div>
                          <div className="input-group">
                            <label>Monthly Mortgage Payment</label>
                            <input
                              type="number"
                              value={mortgage.monthlyMortgagePayment2}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "monthlyMortgagePayment2",
                                  e.target.value
                                )
                              }
                              placeholder="£"
                            />
                          </div>
                          <div className="input-group">
                            <label>Lender</label>
                            <input
                              type="text"
                              value={mortgage.mortgageLender}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "mortgageLender",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="input-group">
                            <label>Interest rate</label>
                            <input
                              type="number"
                              value={mortgage.mortgageInterestRate}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "mortgageInterestRate",
                                  e.target.value
                                )
                              }
                              placeholder="%"
                            />
                          </div>
                          <div className="input-group">
                            <label>Rate type</label>
                            <select
                              value={mortgage.mortgageRateType}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "mortgageRateType",
                                  e.target.value
                                )
                              }
                            >
                              <option value="Fixed">Fixed</option>
                              <option value="Variable">Variable</option>
                            </select>
                          </div>
                          <div className="input-group">
                            <label>When does rate-deal end?</label>
                            <input
                              type="date"
                              value={mortgage.mortgageRateDealEnd}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "mortgageRateDealEnd",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          {/* Moved the Remove Mortgage button inside the map function */}
                          <button
                            type="button"
                            onClick={() => removeMortgage(mortgage.id)}
                          >
                            Remove Mortgage
                          </button>
                        </div>
                      ))}
                      <button type="button" onClick={addMortgage}>
                        Add Mortgage
                      </button>
                    </div>
                  )}
                  <div className="input-group">
                    <label>Do you have any other loans?</label>
                    <select
                      value={hasOtherLoans}
                      onChange={handleHasOtherLoansChange}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Not Sure">Not Sure</option>
                    </select>
                  </div>
                  {hasOtherLoans === "Yes" && (
                    <div>
                      {otherLoans.map((loan, index) => (
                        <div key={loan.id}>
                          <h3>Other Loan {index + 1}</h3>
                          <div className="input-group">
                            <label>Loan Type</label>
                            <input
                              type="text"
                              value={loan.loanType}
                              onChange={(e) =>
                                handleOtherLoanChange(
                                  loan.id,
                                  "loanType",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="input-group">
                            <label>Loan Amount Outstanding</label>
                            <input
                              type="number"
                              value={loan.loanAmountOutstanding}
                              onChange={(e) =>
                                handleOtherLoanChange(
                                  loan.id,
                                  "loanAmountOutstanding",
                                  e.target.value
                                )
                              }
                              placeholder="£"
                            />
                          </div>
                          <div className="input-group">
                            <label>Loan Term Outstanding</label>
                            <input
                              type="number"
                              value={loan.loanTermOutstanding}
                              onChange={(e) =>
                                handleOtherLoanChange(
                                  loan.id,
                                  "loanTermOutstanding",
                                  e.target.value
                                )
                              }
                              placeholder="years"
                            />
                          </div>
                          <div className="input-group">
                            <label>Monthly Loan Payment</label>
                            <input
                              type="number"
                              value={loan.monthlyLoanPayment}
                              onChange={(e) =>
                                handleOtherLoanChange(
                                  loan.id,
                                  "monthlyLoanPayment",
                                  e.target.value
                                )
                              }
                              placeholder="£"
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => removeOtherLoan(loan.id)}
                          >
                            Remove Loan
                          </button>
                        </div>
                      ))}
                      <button type="button" onClick={addOtherLoan}>
                        Add Loan
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {/* Section 5 */}
              <div className="form-section">
                <h2>Section 5 - Monthly Outgoing Calculations</h2>
                <div className="input-group">
                  <label htmlFor="monthlyHouseholdIncome">
                    Total Monthly Household Income
                  </label>
                  <input
                    type="text"
                    id="monthlyHouseholdIncome"
                    value={monthlyHouseholdIncome}
                    onChange={handleMonthlyHouseholdIncomeChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="monthlyMortgageRentLoans">
                    Monthly Mortgage/Rent Amount/Loans
                  </label>
                  <input
                    id="monthlyMortgageRentLoans"
                    type="number"
                    value={combinedMonthlyAmount}
                    placeholder="£"
                    readOnly
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="totalBillsPerMonth">
                    Approx Total Bills per Month
                  </label>
                  <input
                    type="text"
                    id="totalBillsPerMonth"
                    value={totalBillsPerMonth}
                    onChange={handleTotalBillsPerMonthChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="monthlyLivingCost">
                    Monthly Living Costs (e.g. Food, Fuel, Travel etc)
                  </label>
                  <input
                    type="text"
                    id="monthlyLivingCost"
                    value={monthlyLivingCost}
                    onChange={handleMonthlyLivingCostChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="approxMonthlyDisposableIncome">
                    Approx Monthly Disposable Income
                  </label>
                  <input
                    type="text"
                    id="approxMonthlyDisposableIncome"
                    value={approxMonthlyDisposableIncome}
                    disabled
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="howMuchInSavings">
                    How much do you have in savings?
                  </label>
                  <input
                    type="text"
                    id="howMuchInSavings"
                    value={howMuchInSavings}
                    onChange={handleHowMuchInSavingsChange}
                  />
                </div>
              </div>
              {/* Section 6 */}
              <div className="form-section">
                <h2>Section 6 - Existing Cover</h2>
                <div></div>
                <div className="input-group">
                  <label>
                    Do you have any existing Life Insurance, Critical Illness
                    Cover, or Income Protection?
                  </label>
                  <select
                    value={hasExistingCover}
                    onChange={handleHasExistingCoverChange}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Sure">Not Sure</option>
                  </select>
                </div>

                {hasExistingCover === "Yes" && (
                  <div>
                    {additionalCoverSections.map((section, index) => (
                      <div key={index}>
                        <h4>Additional Cover {index + 1}</h4>

                        <div className="input-group">
                          <label>Policy type</label>
                          <input
                            type="text"
                            value={section.policyType}
                            onChange={(event) =>
                              handlePolicyTypeChange(event, index)
                            }
                          />
                        </div>
                        <div className="input-group">
                          <label>Person Covered</label>
                          <input
                            type="text"
                            value={section.personCovered}
                            onChange={(event) =>
                              handlePersonCoveredChange(event, index)
                            }
                          />
                        </div>

                        <div className="input-group">
                          <label>Cover Amount</label>
                          <input
                            type="number"
                            value={section.coverAmount}
                            onChange={(event) =>
                              handleCoverAmountChange(event, index)
                            }
                            placeholder="£"
                          />
                        </div>
                        <div className="input-group">
                          <label>Cover term</label>
                          <input
                            type="number"
                            value={section.coverTerm}
                            onChange={(event) =>
                              handleCoverTermChange(event, index)
                            }
                            placeholder="years"
                          />
                        </div>
                        <div className="input-group">
                          <label>Insurer</label>
                          <input
                            type="text"
                            value={section.insurer}
                            onChange={(event) =>
                              handleInsurerChange(event, index)
                            }
                          />
                        </div>
                        <div className="input-group">
                          <label>When set up</label>
                          <input
                            type="date"
                            value={additionalCoverSections[index].whenSetUp}
                            onChange={(event) =>
                              handleWhenSetUpChange(event, index)
                            }
                          />
                        </div>
                        <div className="input-group">
                          <label>Premium</label>
                          <input
                            type="number"
                            value={section.premium}
                            onChange={(event) =>
                              handlePremiumChange(event, index)
                            }
                            placeholder="£"
                          />
                        </div>
                        {index === additionalCoverSections.length - 1 && (
                          <button onClick={handleAddSection}>
                            Add another
                          </button>
                        )}

                        {index !== 0 && (
                          <button onClick={() => handleRemoveSection(index)}>
                            Remove
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="form-section-details">
                <ResizableTextarea
                  value={section6details}
                  onChange={handleSection6DetailsChange}
                  placeholder="Enter info here..."
                />
              </div>
              {/* Section 7 */}
              <div>
                <div className="form-section">
                  <h2>Section 7 - Health Questions</h2>
                  <div
                    className={`client-info-wrapper ${
                      lookingToCoverPartner === "No" ? "single-client" : ""
                    }`}
                  >
                    <div
                      className={`client ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <h3>Client 1</h3>
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Have you been diagnosed with COVID-19, or suffered
                          symptoms, or had to self-isolate in last 30 days?
                        </label>
                        <select
                          value={covidStatus}
                          onChange={handleCovidStatusChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {covidStatus === "Yes" && (
                        <div
                          className={`client-row ${
                            lookingToCoverPartner === "No"
                              ? "full-width-client"
                              : ""
                          }`}
                        >
                          <ResizableTextarea
                            value={covidStatusDetails}
                            onChange={handleCovidStatusDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Ever had, cancer, strokes, heart conditions or
                          diabetes?
                        </label>
                        <select
                          value={seriousConditions}
                          onChange={handleSeriousConditionsChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {seriousConditions === "Yes" && (
                        <div
                          className={`client-row ${
                            lookingToCoverPartner === "No"
                              ? "full-width-client"
                              : ""
                          }`}
                        >
                          <ResizableTextarea
                            value={seriousConditionsDetails}
                            onChange={handleSeriousConditionsDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Ever had raised blood pressure, or raised cholesterol?
                        </label>
                        <select
                          value={otherConditions}
                          onChange={handleOtherConditionsChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {otherConditions === "Yes" && (
                        <div
                          className={`client-row ${
                            lookingToCoverPartner === "No"
                              ? "full-width-client"
                              : ""
                          }`}
                        >
                          <ResizableTextarea
                            value={otherConditionsDetails}
                            onChange={handleOtherConditionsDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Ever had anaemia, blood clots, blood conditions,
                          thyroid problems, or liver or kidney problems?
                        </label>
                        <select
                          value={physicalConditions}
                          onChange={handlePhysicalConditionsChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {physicalConditions === "Yes" && (
                        <div
                          className={`client-row ${
                            lookingToCoverPartner === "No"
                              ? "full-width-client"
                              : ""
                          }`}
                        >
                          <ResizableTextarea
                            value={physicalConditionsDetails}
                            onChange={handlePhysicalConditionsDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Ever had Crohn’, IBS, or other digestive issues?
                        </label>
                        <select
                          value={mentalHealth}
                          onChange={handleMentalHealthChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {mentalHealth === "Yes" && (
                        <div
                          className={`client-row ${
                            lookingToCoverPartner === "No"
                              ? "full-width-client"
                              : ""
                          }`}
                        >
                          <ResizableTextarea
                            value={mentalHealthDetails}
                            onChange={handleMentalHealthDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Ever had gout, arthritis, asthma, or sleep apnoea?
                        </label>
                        <select
                          value={medication}
                          onChange={handleMedicationChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {medication === "Yes" && (
                        <div
                          className={`client-row ${
                            lookingToCoverPartner === "No"
                              ? "full-width-client"
                              : ""
                          }`}
                        >
                          <ResizableTextarea
                            value={medicationDetails}
                            onChange={handleMedicationDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Ever had depression, stress, anxiety or other mental
                          health issue (regardless of whether you saw a doctor)
                        </label>
                        <select
                          value={familyHistory}
                          onChange={handleFamilyHistoryChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {familyHistory === "Yes" && (
                        <div
                          className={`client-row ${
                            lookingToCoverPartner === "No"
                              ? "full-width-client"
                              : ""
                          }`}
                        >
                          <ResizableTextarea
                            value={familyHistoryDetails}
                            onChange={handleFamilyHistoryDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Any outstanding medical tests or investigations? (e.g.
                          doctor visits, referrals, consultations, operations)
                        </label>
                        <select value={hobbies} onChange={handleHobbiesChange}>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {hobbies === "Yes" && (
                        <div
                          className={`client-row ${
                            lookingToCoverPartner === "No"
                              ? "full-width-client"
                              : ""
                          }`}
                        >
                          <ResizableTextarea
                            value={hobbiesDetails}
                            onChange={handleHobbiesDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Apart from what you’ve already mentioned, any other
                          health issues, or anything else that requires
                          medication or treatment?
                        </label>
                        <select
                          value={travelHistory}
                          onChange={handleTravelHistoryChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {travelHistory === "Yes" && (
                        <div
                          className={`client-row ${
                            lookingToCoverPartner === "No"
                              ? "full-width-client"
                              : ""
                          }`}
                        >
                          <ResizableTextarea
                            value={travelHistoryDetails}
                            onChange={handleTravelHistoryDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Mum, Dad, Brothers & Sisters before age 65. Had any
                          heart attacks, angina, cardiomyopathy, cancer,
                          multiple sclerosis, motor neurone disease etc?
                        </label>
                        <select value={bmi} onChange={handleBmiChange}>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {bmi === "Yes" && (
                        <div
                          className={`client-row ${
                            lookingToCoverPartner === "No"
                              ? "full-width-client"
                              : ""
                          }`}
                        >
                          <ResizableTextarea
                            value={bmiDetails}
                            onChange={handleBmiDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Have you lived worked or travelled outside of the EU
                          or USA in the last 5 years? (not including holidays up
                          to 30 days)?
                        </label>
                        <select
                          value={travelPast5Years}
                          onChange={handleTravelPast5YearsChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {travelPast5Years === "Yes" && (
                        <div
                          className={`client-row ${
                            lookingToCoverPartner === "No"
                              ? "full-width-client"
                              : ""
                          }`}
                        >
                          <ResizableTextarea
                            value={travelPast5YearsDetails}
                            onChange={handleTravelPast5YearsDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Ride a motorbike on the road? Do any other dangerous
                          hobbies such as motorsport, diving, private flying
                          etc?
                        </label>
                        <select
                          value={rideBikes}
                          onChange={handleRideBikesChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {rideBikes === "Yes" && (
                        <div
                          className={`client-row ${
                            lookingToCoverPartner === "No"
                              ? "full-width-client"
                              : ""
                          }`}
                        >
                          <ResizableTextarea
                            value={rideBikesDetails}
                            onChange={handleRideBikesDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Current height, weight and trouser/dress size?
                        </label>
                        <div style={{ width: "350px" }}>
                          <ResizableTextarea
                            value={currentSize}
                            onChange={handleCurrentSizeChange}
                          ></ResizableTextarea>
                        </div>
                      </div>
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <label>
                          Is the client potentially vulnerable (do not ask this
                          question)
                        </label>
                        <select
                          value={potentiallyVulnerable}
                          onChange={handlePotentiallyVulnerableChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {potentiallyVulnerable === "Yes" && (
                        <div
                          className={`client-row ${
                            lookingToCoverPartner === "No"
                              ? "full-width-client"
                              : ""
                          }`}
                        >
                          <ResizableTextarea
                            value={potentiallyVulnerableDetails}
                            onChange={handlePotentiallyVulnerableDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                    </div>
                    {lookingToCoverPartner === "Yes" && (
                      <div className="client1">
                        <h3>Client 2</h3>
                        <div className="client-row1">
                          <label>
                            Have you been diagnosed with COVID-19, or suffered
                            symptoms, or had to self-isolate in last 30 days?
                          </label>
                          <select
                            value={partnerCovidStatus}
                            onChange={handlePartnerCovidStatusChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {partnerCovidStatus === "Yes" && (
                          <div className="client-row1">
                            <ResizableTextarea
                              value={partnerCovidStatusDetails}
                              onChange={handlePartnerCovidStatusDetailsChange}
                              placeholder="Enter info here..."
                            />
                          </div>
                        )}
                        <div className="client-row1">
                          <label>
                            Ever had, cancer, strokes, heart conditions or
                            diabetes?
                          </label>
                          <select
                            value={partnerSeriousConditions}
                            onChange={handlePartnerSeriousConditionsChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {partnerSeriousConditions === "Yes" && (
                          <div className="client-row1">
                            <ResizableTextarea
                              value={partnerSeriousConditionsDetails}
                              onChange={
                                handlePartnerSeriousConditionsDetailsChange
                              }
                              placeholder="Enter info here..."
                            />
                          </div>
                        )}
                        <div className="client-row1">
                          <label>
                            {" "}
                            Ever had raised blood pressure, or raised
                            cholesterol?{" "}
                          </label>
                          <select
                            value={partnerOtherConditions}
                            onChange={handlePartnerOtherConditionsChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {partnerOtherConditions === "Yes" && (
                          <div className="client-row1">
                            <ResizableTextarea
                              value={partnerOtherConditionsDetails}
                              onChange={
                                handlePartnerOtherConditionsDetailsChange
                              }
                              placeholder="Enter info here..."
                            />
                          </div>
                        )}
                        <div className="client-row1">
                          <label>
                            {" "}
                            Ever had anaemia, blood clots, blood conditions,
                            thyroid problems, or liver or kidney problems?
                          </label>
                          <select
                            value={partnerPhysicalConditions}
                            onChange={handlePartnerPhysicalConditionsChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {partnerPhysicalConditions === "Yes" && (
                          <div className="client-row1">
                            <ResizableTextarea
                              value={partnerPhysicalConditionsDetails}
                              onChange={
                                handlePartnerPhysicalConditionsDetailsChange
                              }
                              placeholder="Enter info here..."
                            />
                          </div>
                        )}
                        <div className="client-row1">
                          <label>
                            Ever had Crohn’, IBS, or other digestive issues?{" "}
                          </label>
                          <select
                            value={partnerMentalHealth}
                            onChange={handlePartnerMentalHealthChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {partnerMentalHealth === "Yes" && (
                          <div className="client-row1">
                            <ResizableTextarea
                              value={partnerMentalHealthDetails}
                              onChange={handlePartnerMentalHealthDetailsChange}
                              placeholder="Enter info here..."
                            />
                          </div>
                        )}
                        <div className="client-row1">
                          <label>
                            Ever had gout, arthritis, asthma, or sleep apnoea?
                          </label>
                          <select
                            value={partnerMedication}
                            onChange={handlePartnerMedicationChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {partnerMedication === "Yes" && (
                          <div className="client-row1">
                            <ResizableTextarea
                              value={partnerMedicationDetails}
                              onChange={handlePartnerMedicationDetailsChange}
                              placeholder="Enter info here..."
                            />
                          </div>
                        )}

                        <div className="client-row1">
                          <label>
                            Ever had depression, stress, anxiety or other mental
                            health issue (regardless of whether you saw a
                            doctor)
                          </label>
                          <select
                            value={partnerFamilyHistory}
                            onChange={handlePartnerFamilyHistoryChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {partnerFamilyHistory === "Yes" && (
                          <div className="client-row1">
                            <ResizableTextarea
                              value={partnerFamilyHistoryDetails}
                              onChange={handlePartnerFamilyHistoryDetailsChange}
                              placeholder="Enter info here..."
                            />
                          </div>
                        )}

                        <div className="client-row1">
                          <label>
                            {" "}
                            Any outstanding medical tests or investigations?
                            (e.g. doctor visits, referrals, consultations,
                            operations)
                          </label>
                          <select
                            value={partnerHobbies}
                            onChange={handlePartnerHobbiesChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {partnerHobbies === "Yes" && (
                          <div className="client-row1">
                            <ResizableTextarea
                              value={partnerHobbiesDetails}
                              onChange={handlePartnerHobbiesDetailsChange}
                              placeholder="Enter info here..."
                            />
                          </div>
                        )}
                        <div className="client-row1">
                          <label>
                            Apart from what you’ve already mentioned, any other
                            health issues, or anything else that requires
                            medication or treatment?{" "}
                          </label>
                          <select
                            value={partnerTravelHistory}
                            onChange={handlePartnerTravelHistoryChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {partnerTravelHistory === "Yes" && (
                          <div className="client-row1">
                            <ResizableTextarea
                              value={partnerTravelHistoryDetails}
                              onChange={handlePartnerTravelHistoryDetailsChange}
                              placeholder="Enter info here..."
                            />
                          </div>
                        )}
                        <div className="client-row1">
                          <label>
                            Mum, Dad, Brothers & Sisters before age 65. Had any
                            heart attacks, angina, cardiomyopathy, cancer,
                            multiple sclerosis, motor neurone disease etc?{" "}
                          </label>
                          <select
                            value={partnerBmi}
                            onChange={handlePartnerBmiChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {partnerBmi === "Yes" && (
                          <div className="client-row1">
                            <ResizableTextarea
                              value={partnerBmiDetails}
                              onChange={handlePartnerBmiDetailsChange}
                              placeholder="Enter info here..."
                            />
                          </div>
                        )}
                        <div className="client-row1">
                          <label>
                            Have you lived worked or travelled outside of the EU
                            or USA in the last 5 years? (not including holidays
                            up to 30 days)?
                          </label>
                          <select
                            value={partnerTravelPast5Years}
                            onChange={handlePartnerTravelPast5YearsChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {partnerTravelPast5Years === "Yes" && (
                          <div className="client-row1">
                            <ResizableTextarea
                              value={partnerTravelPast5YearsDetails}
                              onChange={
                                handlePartnerTravelPast5YearsDetailsChange
                              }
                              placeholder="Enter info here..."
                            />
                          </div>
                        )}
                        <div className="client-row1">
                          <label>
                            {" "}
                            Ride a motorbike on the road? Do any other dangerous
                            hobbies such as motorsport, diving, private flying
                            etc?
                          </label>
                          <select
                            value={partnerRideBikes}
                            onChange={handlePartnerRideBikesChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {partnerRideBikes === "Yes" && (
                          <div className="client-row1">
                            <ResizableTextarea
                              value={partnerRideBikesDetails}
                              onChange={handlePartnerRideBikesDetailsChange}
                              placeholder="Enter info here..."
                            />
                          </div>
                        )}
                        <div className="client-row1">
                          <label>
                            Current height, weight and trouser/dress size?
                          </label>
                          <div style={{ width: "350px" }}>
                            <ResizableTextarea
                              value={partnerCurrentSize}
                              onChange={handlePartnerCurrentSizeChange}
                            />
                          </div>
                        </div>
                        <div className="client-row1">
                          <label>
                            Is the client potentially vulnerable (do not ask
                            this question)
                          </label>
                          <select
                            value={partnerPotentiallyVulnerable}
                            onChange={handlePartnerPotentiallyVulnerableChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {partnerPotentiallyVulnerable === "Yes" && (
                          <div className="client-row1">
                            <ResizableTextarea
                              value={partnerPotentiallyVulnerableDetails}
                              onChange={
                                handlePartnerPotentiallyVulnerableDetailsChange
                              }
                              placeholder="Enter info here..."
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Section 7 Details */}
            <div className="form-section">
              <div className="single-client">
                <h2>Notes & Quotes</h2>
                <div>
                  <ResizableTextarea
                    style={{ width: "50%" }}
                    value={section7details}
                    onChange={handleSection7DetailsChange}
                    placeholder="Enter info here..."
                  />
                </div>
              </div>
            </div>
          { /* <button type="submit">Submit</button> */}
          </form>
          <button type="button1" onClick={() => window.print()}>
            Browser PDF
          </button>
          { /*  <button type="button1" onClick={createAndDownloadPDF}>
            PDF
          </button>*/}
          {modalMessage && (
            <Popup message={modalMessage} onClose={closeModal} />
          )}
        </div>
      </div>
      // {isLoading && <div className="loading-screen">Generating PDF...</div>}
    </>
  );
}

export default Update;
