import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import "./Search.css";
import "./App.css";
import { useNavigate } from "react-router-dom";

function Search(props) {

 const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const handleEdit =  (fact) => {
    navigate("/update", { state: { data: fact, id: fact._id } });

//  const handleEdit =  (id) => {
// let ids=props.id
// window.location.href = "/update";
    // try {
    //   const editres = await axios.get(`https://fact-finder.herokuapp.com/api/Search/edit/${id}`);
    //   const data = editres.data;
    //   history.push({
    //     pathname: "/update",
    //     state: { data }
    //   });
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
       const response = await axios.get(
      `https://fact-finder.herokuapp.com/api/search?q=${query}`
    );
    setResults(
      response.data.filter(
        (fact) =>
          fact.caseId.toLowerCase().includes(query.toLowerCase().trim()) ||
          fact.FactId.toLowerCase().includes(query.toLowerCase().trim()) ||
          fact.client1.firstname.toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  useEffect(() => {
    const fetchLatestFacts = async () => {
      try {
        const response = await axios.get('https://fact-finder.herokuapp.com/api/latest-facts');
        setResults(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchLatestFacts();
  }, []);
  
  

  return (
    <>
      <Header />
      <div className="search-container">
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="search-query">Search:</label>
          <input
            type="text"
            id="search-query"
            name="search-query"
            value={query}
            onChange={handleInputChange}
          />
          <button type="submit">Submit</button>
        </form>
        {results.length > 0 && (
          <table className="search-table">
            <thead>
              <tr>
                <th>Case ID</th>
                <th>Fact ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th> Date of Birth</th>
                <th>Age</th>
                <th>Title</th>
                <th>Smoker</th>
                <th>Employment Status</th>
                <th>Gross Annual Income</th>
                <th>Monthly Benefits</th>
                <th>Net Monthly Income</th>
                <th>Job Title</th>
                <th>Sick Pay</th>
                <th>Death in Service</th>
                <th>Years of Salary</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {results.map((fact) => (
                <tr key={fact._id}>
                  <td>{fact.caseId}</td>
                  <td>{fact.FactId}</td>
                  <td>{fact.client1.firstname}</td>
                  <td>{fact.client1.surname}</td>
                  <td>
                    {new Date(fact.client1.dateOfBirth).toLocaleDateString(
                      "en-GB"
                    )}
                  </td>
                  <td>{fact.client1.age}</td>
                  <td>{fact.client1.title}</td>
                  <td>{fact.client1.smoker}</td>
                  <td>{fact.client1.employmentStatus}</td>
                  <td>{fact.client1.grossAnnualIncome}</td>
                  <td>{fact.client1.monthlyBenefits}</td>
                  <td>{fact.client1.netMonthlyIncome}</td>
                  <td>{fact.client1.jobTitle}</td>
                  <td>{fact.client1.sickpay}</td>
                  <td>{fact.client1.client1DeathInService}</td>
                  <td>{fact.client1.client1YearsOfSalary}</td>
                  <td>
                    <button onClick={() => handleEdit(fact)}>
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}
export default Search;
