import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { AuthContext } from "./AuthContext";
import "./Header.css";

const Header = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleCreateNew = () => {
    navigate("/");
  };

  // const handleCreateNew = () => {
  //   navigate(`/factfinder/${Math.random()}`);
  // };
  



  const handleSearch = () => {
    navigate("/search");
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token from local storage
    setIsLoggedIn(false); // Set isLoggedIn to false in the AuthContext
    navigate("/login"); // Navigate to the login page
  };

  return (
    <header className="header">
      <div className="header__left">
        <Link to="/">
          <img src={logo} alt="Logo" className="header__logo" />
        </Link>
      </div>
      <div className="header__middle">
        <h1 className="header__title">Fact Finder</h1>
      </div>
      <div className="header__right">
        <nav className="header__menu">
          <button className="header__menu-btn" onClick={handleCreateNew}>
            Create a new
          </button>
          <button className="header__menu-btn" onClick={handleSearch}>
            Search Existing
          </button>
          {isLoggedIn && (
            <button className="header__menu-btn" onClick={handleLogout}>
              Logout
            </button>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;
