import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../components/AuthContext';
import axios from 'axios';
import Header from '../components/Header';
import './Register.css';
import Popup from '../components/Popup';

function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { isLoggedIn } = useContext(AuthContext);

  function registerUser(event) {
    event.preventDefault();

    axios.post('https://fact-finder.herokuapp.com/api/register', {
      name: name,
      email: email,
      password: password
    })
      .then(response => {
        console.log(response.data);
        setShowSuccessPopup(true);
        setShowErrorPopup(false);
        setErrorMessage('');
      })
      .catch(error => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.error === 'Email already in use') {
          setErrorMessage('Error - Email already in use');
        } else {
          setErrorMessage('Error - Something went wrong');
        }
        setShowErrorPopup(true);
        setShowSuccessPopup(false);
      })
  }

  useEffect(() => {
    const form = document.querySelector('.registerform');

    function handleScroll() {
      if (window.scrollY >= form.offsetTop - window.innerHeight / 2) {
        form.classList.add('animate');
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Header isLoggedIn={isLoggedIn} />
      <div className='register-page'>
        <div className='register'>
          <h1>Register</h1>
          <form className='registerform' onSubmit={registerUser}>
            <input
              className='registername'
              value={name}
              onChange={(e) => setName(e.target.value)}
              type='text'
              placeholder='Name'
              required
            />
            <input
              className='registeremail'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type='email'
              placeholder='Email'
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
            <input
              className='registerpassword'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type='password'
              placeholder='Password'
              required
            />
            <input className='registerbutton' type='submit' value='Register' />
          </form>
          {showSuccessPopup && <Popup message="User created successfully" onClose={() => setShowSuccessPopup(false)} />}
          setName('');
          setEmail('');
          setPassword('');

          {showErrorPopup && <Popup message={errorMessage} onClose={() => setShowErrorPopup(false)} />}
        </div>
      </div>
    </>
  );
}

export default Register;
