import React from 'react';
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import FactFinder from './pages/FactFinder';
import Search from './pages/Search';
import Update from './pages/Update';
import Versions from './pages/Versions';
import { AuthContextProvider } from './components/AuthContext';

const PrivateRoute = ({ children }) => {
  const isLoggedIn = localStorage.getItem('token') !== null;
  
  return isLoggedIn ? children : <Navigate to="/login" replace />;
};

const App = () => {
  return (
    <AuthContextProvider>
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/factfinder" element={<PrivateRoute><FactFinder /></PrivateRoute>} />
            <Route path="/search" element={<PrivateRoute><Search /></PrivateRoute>} />
            <Route path="/update" element={<PrivateRoute><Update /></PrivateRoute>} />
            <Route path="/update/:versionId" element={<PrivateRoute><Versions /></PrivateRoute>} />
          </Routes>
        </BrowserRouter>
      </div>
    </AuthContextProvider>
  );
}

export default App;
