import React, { useEffect, useRef, useState } from 'react';

const ResizableTextarea = ({ value, onChange, printMode }) => {
  const textareaRef = useRef();
  const [printableValue, setPrintableValue] = useState(value);

  useEffect(() => {
    textareaRef.current.style.height = 'auto';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    setPrintableValue(value ? value.split('\n').join('<br/>') : '');
}, [value]);

  return printMode ? (
    <div dangerouslySetInnerHTML={{ __html: printableValue }} />
  ) : (
    <textarea ref={textareaRef} value={value} onChange={onChange} />
  );
};

export default ResizableTextarea;
