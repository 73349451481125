import React from "react";
import "./Popup.css";

function Popup(props) {
  if (props.type === "confirm") {
    return (
      <div className="popup-container">
        <div className="popup">
          <p>{props.message}</p>
          <button onClick={props.onConfirm}>Submit</button>
          <button onClick={props.onCancel}>Cancel</button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="popup-container">
        <div className="popup">
          <p>{props.message}</p>
          <button onClick={props.onClose}>Close</button>
        </div>
      </div>
    );
  }
}

export default Popup;
