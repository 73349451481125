import React, { useState, useEffect } from "react";
import {
  BsArrowLeftRight,
  BsSearch,
  BsPlusCircle,
  BsClockHistory,
} from "react-icons/bs";
import axios from "axios";
import { Link } from "react-router-dom";

const Sidebar = ({ expanded, setExpanded, caseId }) => {
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    fetchVersions(caseId);
  }, [caseId]);

  const fetchVersions = async (caseId) => {
    try {
      const res = await axios.get(
        `https://fact-finder.herokuapp.com/api/factversions/${caseId}`
      );
      setVersions(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: expanded ? "200px" : "50px",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        background: "#333",
        color: "#fff",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "20px 0",
        zIndex: 1000,
        transition: "width 0.3s ease",
      }}
    >
      <div>
        <Link
          to="/"
          style={{
            color: "inherit",
            textDecoration: "none",
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <BsPlusCircle size="1.5em" />
          {expanded && <span style={{ marginLeft: "10px" }}>Create New</span>}
        </Link>
        <Link
          to="/search"
          style={{
            color: "inherit",
            textDecoration: "none",
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <BsSearch size="1.5em" />
          {expanded && <span style={{ marginLeft: "10px" }}>Search</span>}
        </Link>
        <div>
          <BsClockHistory size="1.5em" />
          {expanded && <span style={{ marginLeft: "10px" }}>Versions</span>}
          {expanded && (
            <ul>
              {versions.map((version, index) => (
                <li key={index}>
                  <Link to={`/update/${version._id}`} style={{ color: 'white' }}>
                    V{version.version}: {version.text}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div></div>
      <div
        onClick={() => setExpanded(!expanded)}
        style={{ cursor: "pointer", color: "yellow" }}
      >
        <BsArrowLeftRight size="2em" />
      </div>
    </div>
  );
};

export default Sidebar;
