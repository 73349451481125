import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { AuthContext } from "../components/AuthContext";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import ReactTooltip from "react-tooltip";
import { ReactComponent as InfoIcon } from "../assets/info.svg";
import ResizableTextarea from "../components/ResizableTextarea";
import Header from "../components/Header";
import Popup from "../components/Popup";
import "jspdf-autotable";
import ReactMarkdown from "react-markdown";
import CookieBanner from "../components/Cookies/CookieBanner";
import "./App.css";

function FactFinder() {
  const { isLoggedIn } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  // PDF Functionality
  const [isPrinting, setIsPrinting] = useState(false);

  const createAndDownloadPDF = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://fact-finder.herokuapp.com/api/create-pdf",
        {
          caseId,
          regulatoryScriptRead,
          factFindDate,
          lookingToCover,
          lookingToCoverPartner,
          client1Title,
          client1FirstName,
          client1Surname,
          client1DateOfBirth,
          client1Age,
          client1SmokerStatus,
          client1LastNicotineUse,
          client1CigarettesPerDay,
          client1MaritalStatus,
          client2Title,
          client2FirstName,
          client2Surname,
          client2DateOfBirth,
          client2Age,
          client2SmokerStatus,
          client2CigarettesPerDay,
          client2LastNicotineUse,
          hasChildren,
          numberOfChildren,
          childrenAges,
          childrenAgeOfFinancialIndependence,
          client1EmploymentStatus,
          client1GrossAnnualIncomePAYE,
          client1GrossAnnualIncomeDividends,
          client1CompanyType,
          client1YearsEstablished,
          client1GrossAnnualIncome,
          client1MonthlyBenefits,
          client1NetMonthlyIncome,
          client1JobTitle,
          client1SickPay,
          client1FullSickPayMonths,
          client1HalfSickPayMonths,
          client1DeathInService,
          client1YearsOfSalary,
          client1RetirementAge,
          client1OtherIncome,
          client1AdditionalIncomes: additionalIncomes.map((income) => ({
            amount: income.amount,
            source: income.source,
          })),
          client1NotesSection2,
          client2EmploymentStatus,
          client2CompanyType,
          client2YearsEstablished,
          client2GrossAnnualIncomePAYE,
          client2GrossAnnualIncomeDividends,
          client2GrossAnnualIncome,
          client2MonthlyBenefits,
          client2NetMonthlyIncome,
          client2JobTitle,
          client2SickPay,
          client2FullSickPayMonths,
          client2HalfSickPayMonths,
          client2DeathInService,
          client2YearsOfSalary,
          client2RetirementAge,
          client2OtherIncome,
          client2AdditionalIncomes: additionalIncomesClient2.map((income) => ({
            amount: income.amount,
            source: income.source,
          })),
          client2NotesSection2,
          housingStatus,
          mortgageType,
          outstandingMortgageAmount,
          outstandingMortgageTerm,
          monthlyMortgagePayment,
          lender,
          interestRate,
          rateType,
          rateDealEndDate,
          monthlyRentalAmount,
          livingWithFamilyRentAmount,
          showMortgageQuestions,
          showRentQuestions,
          showLivingWithFamilyQuestions,
          notesSection3,
          hasOtherMortgages,
          otherMortgages: otherMortgages.map((mortgage) => ({
            mortgageType2: mortgage.mortgageType2,
            mortgageAmountOutstanding: mortgage.mortgageAmountOutstanding,
            monthlyMortgageTerm: mortgage.monthlyMortgageTerm,
            monthlyMortgagePayment2: mortgage.monthlyMortgagePayment2,
            mortgageLender: mortgage.mortgageLender,
            mortgageInterestRate: mortgage.mortgageInterestRate,
            mortgageRateType: mortgage.mortgageRateType,
            mortgageRateDealEnd: mortgage.mortgageRateDealEnd,
          })),
          hasOtherLoans,
          otherLoans: otherLoans.map((loan) => ({
            loanType: loan.loanType,
            loanAmountOutstanding: loan.loanAmountOutstanding,
            loanTermOutstanding: loan.loanTermOutstanding,
            monthlyLoanPayment: loan.monthlyLoanPayment,
          })),
          monthlyHouseholdIncome,
          monthlyMortgageRentLoanAmount,
          combinedMonthlyAmount,
          totalBillsPerMonth,
          monthlyLivingCost,
          approxMonthlyDisposableIncome,
          hasExistingCover,
          howMuchInSavings,
          additionalCoverSections: additionalCoverSections.map((section) => ({
            policyType: section.policyType,
            personCovered: section.personCovered,
            coverAmount: section.coverAmount,
            coverTerm: section.coverTerm,
            insurer: section.insurer,
            whenSetUp: section.whenSetUp,
            premium: section.premium,
          })),
          covidStatus,
          covidStatusDetails,
          partnerCovidStatus,
          partnerCovidStatusDetails,
          seriousConditions,
          seriousConditionsDetails,
          partnerSeriousConditions,
          partnerSeriousConditionsDetails,
          otherConditions,
          otherConditionsDetails,
          partnerOtherConditions,
          partnerOtherConditionsDetails,
          physicalConditions,
          physicalConditionsDetails,
          partnerPhysicalConditions,
          partnerPhysicalConditionsDetails,
          mentalHealth,
          mentalHealthDetails,
          partnerMentalHealth,
          partnerMentalHealthDetails,
          medication,
          medicationDetails,
          partnerMedication,
          partnerMedicationDetails,
          familyHistory,
          familyHistoryDetails,
          partnerFamilyHistory,
          partnerFamilyHistoryDetails,
          hobbies,
          hobbiesDetails,
          partnerHobbies,
          partnerHobbiesDetails,
          travelHistory,
          travelHistoryDetails,
          partnerTravelHistory,
          partnerTravelHistoryDetails,
          bmi,
          bmiDetails,
          partnerBmi,
          partnerBmiDetails,
          travelPast5Years,
          travelPast5YearsDetails,
          partnerTravelPast5Years,
          partnerTravelPast5YearsDetails,
          rideBikes,
          rideBikesDetails,
          partnerRideBikes,
          partnerRideBikesDetails,
          currentSize,
          partnerCurrentSize,
          potentiallyVulnerable,
          potentiallyVulnerableDetails,
          partnerPotentiallyVulnerable,
          partnerPotentiallyVulnerableDetails,
        },
        { responseType: "blob" }
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      saveAs(pdfBlob, `FactFinder_${caseId}.pdf`);
    } catch (error) {
      console.error("Failed to create or download PDF:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = async (event) => {
    event.stopPropagation();
    setIsLoading(true);
    await createAndDownloadPDF();
    setIsLoading(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => setIsPrinting(true),
    onAfterPrint: () => setIsPrinting(false),
  });

  const componentRef = useRef();

  const [modalMessage, setModalMessage] = useState(null);
  // Section 1
  const [lookingToCover, setLookingToCover] = useState("");
  const [lookingToCoverPartner, setLookingToCoverPartner] = useState("No");
  const [regulatoryScriptRead, setRegulatoryScriptRead] = useState("Yes");
  const [factFindDate, setFactFindDate] = useState(
    new Date().toISOString().substring(0, 10)
  );

  const [client1Title, setClient1Title] = useState("Mr");
  const [client1FirstName, setClient1FirstName] = useState("");
  const [client1Surname, setClient1Surname] = useState("");
  const [client1DateOfBirth, setClient1DateOfBirth] = useState("");
  const [client1Age, setClient1Age] = useState("");
  const [client1SmokerStatus, setClient1SmokerStatus] = useState("Non Smoker");
  const [client1CigarettesPerDay, setClient1CigarettesPerDay] = useState("");
  const [client1MaritalStatus, setClient1MaritalStatus] = useState("Single");
  const [client1LastNicotineUse, setClient1LastNicotineUse] = useState("");
  const [client2Title, setClient2Title] = useState("Mr");
  const [client2FirstName, setClient2FirstName] = useState("");
  const [client2Surname, setClient2Surname] = useState("");
  const [client2DateOfBirth, setClient2DateOfBirth] = useState("");
  const [client2Age, setClient2Age] = useState("");
  const [client2SmokerStatus, setClient2SmokerStatus] = useState("Non Smoker");
  const [client2CigarettesPerDay, setClient2CigarettesPerDay] = useState("");
  const [client2LastNicotineUse, setClient2LastNicotineUse] = useState("");
  const [hasChildren, setHasChildren] = useState("No");
  const [numberOfChildren, setNumberOfChildren] = useState("0");
  const [childrenAges, setChildrenAges] = useState([]);
  const [
    childrenAgeOfFinancialIndependence,
    setChildrenAgeOfFinancialIndependence,
  ] = useState("");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  //Section 2
  const [client1EmploymentStatus, setClient1EmploymentStatus] = useState(
    "Employed (Full Time)"
  );
  const [client1CompanyType, setClient1CompanyType] = useState("");
  const [client1YearsEstablished, setClient1YearsEstablished] = useState("");
  const [client1GrossAnnualIncome, setClient1GrossAnnualIncome] = useState(0);
  const [client1GrossAnnualIncomePAYE, setClient1GrossAnnualIncomePAYE] =
    useState(0);
  const [
    client1GrossAnnualIncomeDividends,
    setClient1GrossAnnualIncomeDividends,
  ] = useState(0);
  const [client1MonthlyBenefits, setClient1MonthlyBenefits] = useState(0);
  const [client1NetMonthlyIncome, setClient1NetMonthlyIncome] = useState(0);
  const [client1JobTitle, setClient1JobTitle] = useState("");
  const [client1SickPay, setClient1SickPay] = useState("Not Sure");
  const [client1DeathInService, setClient1DeathInService] =
    useState("Not Sure");
  const [client1YearsOfSalary, setClient1YearsOfSalary] = useState("");
  const [client1RetirementAge, setClient1RetirementAge] = useState("");
  const [client1OtherIncome, setClient1OtherIncome] = useState("");
  const [client1NotesSection2, setClient1NotesSection2] = useState("");
  const [client2EmploymentStatus, setClient2EmploymentStatus] = useState(
    "Employed (Full Time)"
  );
  const [client2CompanyType, setClient2CompanyType] = useState("");
  const [client2YearsEstablished, setClient2YearsEstablished] = useState(0);
  const [client2GrossAnnualIncomePAYE, setClient2GrossAnnualIncomePAYE] =
    useState(0);
  const [
    client2GrossAnnualIncomeDividends,
    setClient2GrossAnnualIncomeDividends,
  ] = useState(0);
  const [additionalIncomes, setAdditionalIncomes] = useState([
    {
      amount: "",
      source: "",
    },
  ]);
  const [client1FullSickPayMonths, setClient1FullSickPayMonths] = useState("");
  const [client1HalfSickPayMonths, setClient1HalfSickPayMonths] = useState("");
  const [client2GrossAnnualIncome, setClient2GrossAnnualIncome] = useState(0);
  const [client2MonthlyBenefits, setClient2MonthlyBenefits] = useState(0);
  const [client2NetMonthlyIncome, setClient2NetMonthlyIncome] = useState(0);
  const [client2JobTitle, setClient2JobTitle] = useState("");
  const [client2SickPay, setClient2SickPay] = useState("");
  const [client2DeathInService, setClient2DeathInService] = useState("");
  const [client2YearsOfSalary, setClient2YearsOfSalary] = useState(0);
  const [client2RetirementAge, setClient2RetirementAge] = useState(0);
  const [client2NotesSection2, setClient2NotesSection2] = useState("");
  const [client2FullSickPayMonths, setClient2FullSickPayMonths] = useState("");
  const [client2HalfSickPayMonths, setClient2HalfSickPayMonths] = useState("");
  const [client2OtherIncome, setClient2OtherIncome] = useState("");
  const [additionalIncomesClient2, setAdditionalIncomesClient2] = useState([
    {
      amount: "",
      source: "",
    },
  ]);

  useEffect(() => {
    const totalAdditionalIncomeClient1 = additionalIncomes.reduce(
      (total, income) => total + parseFloat(income.amount || 0),
      0
    );
    const totalAdditionalIncomeClient2 = additionalIncomesClient2.reduce(
      (total, income) => total + parseFloat(income.amount || 0),
      0
    );

    const totalNetMonthlyIncome =
      parseFloat(client1NetMonthlyIncome || 0) +
      (lookingToCoverPartner === "Yes"
        ? parseFloat(client2NetMonthlyIncome || 0)
        : 0);

    const totalMonthlyIncome =
      totalNetMonthlyIncome +
      totalAdditionalIncomeClient1 +
      totalAdditionalIncomeClient2;

    setMonthlyHouseholdIncome(totalMonthlyIncome.toFixed(2));
  }, [
    client1NetMonthlyIncome,
    client2NetMonthlyIncome,
    lookingToCoverPartner,
    additionalIncomes,
    additionalIncomesClient2,
  ]);

  // Section 3
  const [housingStatus, setHousingStatus] = useState("");
  const [mortgageType, setMortgageType] = useState("");
  const [outstandingMortgageTerm, setOutstandingMortgageTerm] = useState("");
  const [outstandingMortgageAmount, setOutstandingMortgageAmount] =
    useState("");
  const [monthlyMortgagePayment, setMonthlyMortgagePayment] = useState("");
  const [lender, setLender] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [rateType, setRateType] = useState("");
  const [rateDealEndDate, setRateDealEndDate] = useState("");
  const [monthlyRentalAmount, setMonthlyRentalAmount] = useState("");
  const [livingWithFamilyRentAmount, setLivingWithFamilyRentAmount] =
    useState("");
  const [showMortgageQuestions, setShowMortgageQuestions] = useState(false);
  const [showRentQuestions, setShowRentQuestions] = useState(false);
  const [showLivingWithFamilyQuestions, setShowLivingWithFamilyQuestions] =
    useState(false);
  const [notesSection3, setNotesSection3] = useState("");

  const [combinedMonthlyAmount, setCombinedMonthlyAmount] = useState("");

  // Section 4
  const [otherMortgages, setOtherMortgages] = useState([]);
  const [hasOtherMortgages, setHasOtherMortgages] = useState("No");
  const [hasOtherLoans, setHasOtherLoans] = useState("No");
  const [otherLoans, setOtherLoans] = useState([]);
  const addOtherLoan = () => {
    setOtherLoans([
      ...otherLoans,
      {
        loanType: "",
        loanAmountOutstanding: "",
        loanTermOutstanding: "",
        monthlyLoanPayment: "",
      },
    ]);
  };

  const removeOtherLoan = (loanId) => {
    setOtherLoans(otherLoans.filter((loan) => loan.id !== loanId));
  };

  // Add and remove mortgage functions
  const addMortgage = () => {
    setOtherMortgages([
      ...otherMortgages,
      {
        id: Date.now(),
        mortgageType2: "",
        mortgageAmountOutstanding: "",
        monthlyMortgageTerm: "",
        monthlyMortgagePayment2: "",
        mortgageLender: "",
        mortgageInterestRate: "",
        mortgageRateType: "",
        mortgageRateDealEnd: "",
      },
    ]);
  };

  const removeMortgage = (id) => {
    setOtherMortgages(otherMortgages.filter((mortgage) => mortgage.id !== id));
  };

  // Calculate combined monthly amount for Rent / Loans / Mortgages etc
  const updateCombinedMonthlyAmount = useCallback(() => {
    let mortgagePayment = parseFloat(monthlyMortgagePayment) || 0;
    let rentalAmount = parseFloat(monthlyRentalAmount) || 0;
    let familyRentAmount = parseFloat(livingWithFamilyRentAmount) || 0;
    let otherMortgagePayments = otherMortgages.reduce(
      (sum, mortgage) =>
        sum + (parseFloat(mortgage.monthlyMortgagePayment2) || 0),
      0
    );
    let otherLoanPayments = otherLoans.reduce(
      (sum, loan) => sum + (parseFloat(loan.monthlyLoanPayment) || 0),
      0
    );
    setCombinedMonthlyAmount(
      mortgagePayment +
        rentalAmount +
        familyRentAmount +
        otherMortgagePayments +
        otherLoanPayments
    );
  }, [
    monthlyMortgagePayment,
    monthlyRentalAmount,
    livingWithFamilyRentAmount,
    otherMortgages,
    otherLoans,
  ]);

  // Calculate Combined Monthly Amount
  useEffect(() => {
    updateCombinedMonthlyAmount();
  }, [
    monthlyMortgagePayment,
    monthlyRentalAmount,
    livingWithFamilyRentAmount,
    otherMortgages,
    otherLoans,
    updateCombinedMonthlyAmount,
  ]);

  //Section 5
  const [monthlyHouseholdIncome, setMonthlyHouseholdIncome] = useState("");
  const [monthlyMortgageRentLoanAmount, setMonthlyMortgageRentLoanAmount] =
    useState("");
  const [totalBillsPerMonth, setTotalBillsPerMonth] = useState("");
  const [monthlyLivingCost, setMonthlyLivingCost] = useState("");
  const [approxMonthlyDisposableIncome, setApproxMonthlyDisposableIncome] =
    useState("");
  
  const [howMuchInSavings, setHowMuchInSavings] = useState("");

  //Section 6
  const [hasExistingCover, setHasExistingCover] = useState("No");

  const [additionalCoverSections, setAdditionalCoverSections] = useState([
    {
      policyType: "",
      personCovered: "",
      coverAmount: "",
      coverTerm: "",
      insurer: "",
      whenSetUp: "",
      premium: "",
    },
  ]);

  const [section6details, setSection6Details] = useState("");

  //Section 7
  const [covidStatus, setCovidStatus] = useState("");
  const [partnerCovidStatus, setPartnerCovidStatus] = useState("");
  const [seriousConditions, setSeriousConditions] = useState("");
  const [partnerSeriousConditions, setPartnerSeriousConditions] = useState("");
  const [otherConditions, setOtherConditions] = useState("");
  const [partnerOtherConditions, setPartnerOtherConditions] = useState("");
  const [section7FreeText, setSection7FreeText] = useState("");
  const [section7FreeText2, setSection7FreeText2] = useState("");
  const [partnerSection7FreeText, setPartnerSection7FreeText] = useState("");
  const [partnerSection7FreeText2, setPartnerSection7FreeText2] = useState("");
  const [physicalConditions, setPhysicalConditions] = useState("");
  const [partnerPhysicalConditions, setPartnerPhysicalConditions] =
    useState("");
  const [mentalHealth, setMentalHealth] = useState("");
  const [partnerMentalHealth, setPartnerMentalHealth] = useState("");
  const [medication, setMedication] = useState("");
  const [partnerMedication, setPartnerMedication] = useState("");
  const [familyHistory, setFamilyHistory] = useState("");
  const [partnerFamilyHistory, setPartnerFamilyHistory] = useState("");
  const [hobbies, setHobbies] = useState("");
  const [partnerHobbies, setPartnerHobbies] = useState("");
  const [travelHistory, setTravelHistory] = useState("");
  const [partnerTravelHistory, setPartnerTravelHistory] = useState("");
  const [bmi, setBmi] = useState("");
  const [travelPast5Years, setTravelPast5Years] = useState("");
  const [partnerTravelPast5Years, setPartnerTravelPast5Years] = useState("");
  const [rideBikes, setRideBikes] = useState("");
  const [partnerRideBikes, setPartnerRideBikes] = useState("");
  const [currentSize, setCurrentSize] = useState("");
  const [partnerCurrentSize, setPartnerCurrentSize] = useState("");
  const [potentiallyVulnerable, setPotentiallyVulnerable] = useState("");
  const [partnerPotentiallyVulnerable, setPartnerPotentiallyVulnerable] =
    useState("");
  const [partnerBmi, setPartnerBmi] = useState("");
  const [caseId, setcaseId] = useState("");

  //Section 7 Free type Boxes
  const [covidStatusDetails, setCovidStatusDetails] = useState("");
  const [seriousConditionsDetails, setSeriousConditionsDetails] = useState("");
  const [otherConditionsDetails, setOtherConditionsDetails] = useState("");
  const [physicalConditionsDetails, setPhysicalConditionsDetails] =
    useState("");
  const [mentalHealthDetails, setMentalHealthDetails] = useState("");
  const [medicationDetails, setMedicationDetails] = useState("");
  const [familyHistoryDetails, setFamilyHistoryDetails] = useState("");
  const [hobbiesDetails, setHobbiesDetails] = useState("");
  const [travelHistoryDetails, setTravelHistoryDetails] = useState("");
  const [bmiDetails, setBmiDetails] = useState("");
  const [travelPast5YearsDetails, setTravelPast5YearsDetails] = useState("");
  const [rideBikesDetails, setRideBikesDetails] = useState("");
  const [potentiallyVulnerableDetails, setPotentiallyVulnerableDetails] =
    useState("");

  //Section 7 Partner Free type Boxes
  const [partnerCovidStatusDetails, setPartnerCovidStatusDetails] =
    useState("");
  const [partnerSeriousConditionsDetails, setPartnerSeriousConditionsDetails] =
    useState("");
  const [partnerOtherConditionsDetails, setPartnerOtherConditionsDetails] =
    useState("");
  const [
    partnerPhysicalConditionsDetails,
    setPartnerPhysicalConditionsDetails,
  ] = useState("");
  const [partnerMentalHealthDetails, setPartnerMentalHealthDetails] =
    useState("");
  const [partnerMedicationDetails, setPartnerMedicationDetails] = useState("");
  const [partnerFamilyHistoryDetails, setPartnerFamilyHistoryDetails] =
    useState("");
  const [partnerHobbiesDetails, setPartnerHobbiesDetails] = useState("");
  const [partnerTravelHistoryDetails, setPartnerTravelHistoryDetails] =
    useState("");
  const [partnerBmiDetails, setPartnerBmiDetails] = useState("");
  const [partnerTravelPast5YearsDetails, setPartnerTravelPast5YearsDetails] =
    useState("");
  const [partnerRideBikesDetails, setPartnerRideBikesDetails] = useState("");
  const [
    partnerPotentiallyVulnerableDetails,
    setPartnerPotentiallyVulnerableDetails,
  ] = useState("");

  const [section7details, setSection7Details] = useState("");

  //Section 1 handles
  const handleLookingToCoverChange = (event) => {
    setLookingToCover(event.target.value);
  };

  const handleLookingToCoverPartnerChange = (event) => {
    setLookingToCoverPartner(event.target.value);
  };

  const handleRegulatoryScriptReadChange = (event) => {
    setRegulatoryScriptRead(event.target.value);
  };

  const handleClient1TitleChange = (event) => {
    setClient1Title(event.target.value);
  };

  const handleClient1FirstNameChange = (event) => {
    setClient1FirstName(event.target.value);
  };

  const handleClient1SurnameChange = (event) => {
    setClient1Surname(event.target.value);
  };

  const handleClient1DateOfBirthChange = (event) => {
    setClient1DateOfBirth(event.target.value);
  };

  const handleClient1DateOfBirthBlur = (event) => {
    const formattedDate = formatDate(event.target.value);
    setClient1DateOfBirth(formattedDate);
    const birthDate = new Date(formattedDate);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      setClient1Age(age - 1);
    } else {
      setClient1Age(age);
    }
  };

  const handleClient1AgeChange = (event) => {
    setClient1Age(event.target.value);
  };

  const handleChildrenAgeOfFinancialIndependenceChange = (event) => {
    setChildrenAgeOfFinancialIndependence(event.target.value);
  };

  const handleClient1SmokerStatusChange = (event) => {
    setClient1SmokerStatus(event.target.value);
  };

  const handleClient1LastNicotineUseChange = (event) => {
    setClient1LastNicotineUse(event.target.value);
  };

  const handleClient1CigarettesPerDayChange = (event) => {
    setClient1CigarettesPerDay(event.target.value);
  };

  const handleClient1MaritalStatusChange = (event) => {
    setClient1MaritalStatus(event.target.value);
  };

  const handleClient2TitleChange = (event) => {
    setClient2Title(event.target.value);
  };

  const handleClient2FirstNameChange = (event) => {
    setClient2FirstName(event.target.value);
  };

  const handleClient2SurnameChange = (event) => {
    setClient2Surname(event.target.value);
  };

  const handleClient2DateOfBirthChange = (event) => {
    setClient2DateOfBirth(event.target.value);
  };

  const handleClient2DateOfBirthBlur = (event) => {
    const formattedDate = formatDate(event.target.value);
    setClient2DateOfBirth(formattedDate);
    const birthDate = new Date(formattedDate);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      setClient2Age(age - 1);
    } else {
      setClient2Age(age);
    }
  };

  const handleClient2AgeChange = (event) => {
    setClient2Age(event.target.value);
  };

  const handleClient2SmokerStatusChange = (event) => {
    setClient2SmokerStatus(event.target.value);
  };

  const handleClient2LastNicotineUseChange = (event) => {
    setClient2LastNicotineUse(event.target.value);
  };

  const handleClient2CigarettesPerDayChange = (event) => {
    setClient2CigarettesPerDay(event.target.value);
  };

  const handleHasChildrenChange = (event) => {
    setHasChildren(event.target.value);
  };

  const handleNumberOfChildrenChange = (event) => {
    setNumberOfChildren(event.target.value);
  };

  const handleChildAgeChange = (event, index) => {
    const newAges = [...childrenAges];
    newAges[index] = parseInt(event.target.value);
    setChildrenAges(newAges);
  };

  // Section 2 handles
  const handleClient1EmploymentStatusChange = (e) => {
    setClient1EmploymentStatus(e.target.value);
  };

  const handleClient1GrossAnnualIncomePAYEChange = (event) => {
    setClient1GrossAnnualIncomePAYE(event.target.value);
  };

  const handleClient1GrossAnnualIncomeDividendsChange = (event) => {
    setClient1GrossAnnualIncomeDividends(event.target.value);
  };

  const handleClient1CompanyTypeChange = (event) => {
    setClient1CompanyType(event.target.value);
  };

  const handleClient1YearsEstablishedChange = (event) => {
    setClient1YearsEstablished(event.target.value);
  };

  const handleClient1GrossAnnualIncomeChange = (e) => {
    setClient1GrossAnnualIncome(e.target.value);
  };

  const handleClient1MonthlyBenefitsChange = (e) => {
    setClient1MonthlyBenefits(e.target.value);
  };

  const handleClient1NetMonthlyIncomeChange = (e) => {
    setClient1NetMonthlyIncome(e.target.value);
  };

  const handleClient1JobTitleChange = (e) => {
    setClient1JobTitle(e.target.value);
  };

  const handleClient1SickPayChange = (e) => {
    setClient1SickPay(e.target.value);
  };

  const handleClient1DeathInServiceChange = (e) => {
    setClient1DeathInService(e.target.value);
  };

  const handleClient1YearsOfSalaryChange = (e) => {
    setClient1YearsOfSalary(e.target.value);
  };

  const handleClient1RetirementAgeChange = (e) => {
    setClient1RetirementAge(e.target.value);
  };

  const handleClient1OtherIncomeChange = (e) => {
    setClient1OtherIncome(e.target.value);
  };

  const handleClient1FullSickPayMonthsChange = (e) => {
    setClient1FullSickPayMonths(e.target.value);
  };

  const handleClient1HalfSickPayMonthsChange = (e) => {
    setClient1HalfSickPayMonths(e.target.value);
  };

  const handleAdditionalIncomeAmountChange = (event, index) => {
    const newIncomes = [...additionalIncomes];
    newIncomes[index].amount = event.target.value;
    setAdditionalIncomes(newIncomes);
  };

  const handleAdditionalIncomeSourceChange = (event, index) => {
    const newIncomes = [...additionalIncomes];
    newIncomes[index].source = event.target.value;
    setAdditionalIncomes(newIncomes);
  };

  const handleAddAdditionalIncome = () => {
    setAdditionalIncomes([
      ...additionalIncomes,
      {
        amount: "",
        source: "",
      },
    ]);
  };

  const handleRemoveAdditionalIncome = (index) => {
    const newIncomes = [...additionalIncomes];
    newIncomes.splice(index, 1);
    setAdditionalIncomes(newIncomes);
  };

  const handleClient2EmploymentStatusChange = (e) => {
    setClient2EmploymentStatus(e.target.value);
  };

  const handleClient2CompanyTypeChange = (event) => {
    setClient2CompanyType(event.target.value);
  };

  const handleClient2YearsEstablishedChange = (event) => {
    setClient2YearsEstablished(event.target.value);
  };

  const handleClient2GrossAnnualIncomePAYEChange = (e) => {
    setClient2GrossAnnualIncomePAYE(e.target.value);
  };

  const handleClient2GrossAnnualIncomeDividendsChange = (e) => {
    setClient2GrossAnnualIncomeDividends(e.target.value);
  };

  const handleClient2GrossAnnualIncomeChange = (e) => {
    setClient2GrossAnnualIncome(e.target.value);
  };

  const handleClient2MonthlyBenefitsChange = (e) => {
    setClient2MonthlyBenefits(e.target.value);
  };

  const handleClient2NetMonthlyIncomeChange = (e) => {
    setClient2NetMonthlyIncome(e.target.value);
  };

  const handleClient2JobTitleChange = (e) => {
    setClient2JobTitle(e.target.value);
  };

  const handleClient2SickPayChange = (e) => {
    setClient2SickPay(e.target.value);
  };

  const handleClient2DeathInServiceChange = (e) => {
    setClient2DeathInService(e.target.value);
  };

  const handleClient2YearsOfSalaryChange = (e) => {
    setClient2YearsOfSalary(e.target.value);
  };

  const handleClient2RetirementAgeChange = (e) => {
    setClient2RetirementAge(e.target.value);
  };

  const handleClient2FullSickPayMonthsChange = (e) => {
    setClient2FullSickPayMonths(e.target.value);
  };

  const handleClient2HalfSickPayMonthsChange = (e) => {
    setClient2HalfSickPayMonths(e.target.value);
  };

  const handleClient1NotesSection2Change = (e) => {
    setClient1NotesSection2(e.target.value);
  };

  const handleCleint2NotesSection2Change = (e) => {
    setClient2NotesSection2(e.target.value);
  };

  const handleClient2OtherIncomeChange = (event) => {
    setClient2OtherIncome(event.target.value);
  };

  const handleAdditionalIncomeAmountChangeClient2 = (event, index) => {
    const newIncomes = [...additionalIncomesClient2];
    newIncomes[index].amount = event.target.value;
    setAdditionalIncomesClient2(newIncomes);
  };

  const handleAdditionalIncomeSourceChangeClient2 = (event, index) => {
    const newIncomes = [...additionalIncomesClient2];
    newIncomes[index].source = event.target.value;
    setAdditionalIncomesClient2(newIncomes);
  };

  const handleAddAdditionalIncomeClient2 = () => {
    setAdditionalIncomesClient2([
      ...additionalIncomesClient2,
      {
        amount: "",
        source: "",
      },
    ]);
  };

  const handleRemoveAdditionalIncomeClient2 = (index) => {
    const newIncomes = [...additionalIncomesClient2];
    newIncomes.splice(index, 1);
    setAdditionalIncomesClient2(newIncomes);
  };

  //section 3
  const handleHousingStatusChange = (event) => {
    const value = event.target.value;
    console.log("Housing status selected: ", value);
    setHousingStatus(value);

    if (value === "Mortgage") {
      setShowMortgageQuestions(true);
      setShowRentQuestions(false);
      setShowLivingWithFamilyQuestions(false);
    } else if (value === "Renting") {
      setShowMortgageQuestions(false);
      setShowRentQuestions(true);
      setShowLivingWithFamilyQuestions(false);
    } else if (value === "Living with family") {
      setShowMortgageQuestions(false);
      setShowRentQuestions(false);
      setShowLivingWithFamilyQuestions(true);
    } else {
      setShowMortgageQuestions(false);
      setShowRentQuestions(false);
      setShowLivingWithFamilyQuestions(false);
    }

    console.log("showMortgageQuestions: ", showMortgageQuestions);
    console.log("showRentQuestions: ", showRentQuestions);
    console.log(
      "showLivingWithFamilyQuestions: ",
      showLivingWithFamilyQuestions
    );
  };

  const handleMonthlyMortgagePaymentChange = (e) => {
    setMonthlyMortgagePayment(e.target.value);
  };

  const handleMonthlyRentalAmountChange = (e) => {
    setMonthlyRentalAmount(e.target.value);
  };

  const handleLivingWithFamilyRentAmountChange = (e) => {
    setLivingWithFamilyRentAmount(e.target.value);
  };

  const handleMortgageTypeChange = (e) => {
    setMortgageType(e.target.value);
  };

  const handleOutstandingMortgageAmountChange = (e) => {
    setOutstandingMortgageAmount(e.target.value);
  };

  const handleOutstandingMortgageTermChange = (e) => {
    setOutstandingMortgageTerm(e.target.value);
  };

  const handleLenderChange = (e) => {
    setLender(e.target.value);
  };

  const handleInterestRateChange = (e) => {
    setInterestRate(e.target.value);
  };

  const handleRateTypeChange = (e) => {
    setRateType(e.target.value);
  };

  const handleRateDealEndDateChange = (e) => {
    setRateDealEndDate(e.target.value);
  };

  const handleNotesSection3Change = (e) => {
    setNotesSection3(e.target.value);
  };

  //Section 4

  const handleMortgageChange = (id, field, value) => {
    setOtherMortgages(
      otherMortgages.map((mortgage) =>
        mortgage.id === id ? { ...mortgage, [field]: value } : mortgage
      )
    );
  };

  const handleHasOtherMortgagesChange = (event) => {
    setHasOtherMortgages(event.target.value);
    if (event.target.value === "Yes" && otherMortgages.length === 0) {
      addMortgage();
    }
  };

  const handleOtherLoanChange = (loanId, field, value) => {
    setOtherLoans(
      otherLoans.map((loan) =>
        loan.id === loanId ? { ...loan, [field]: value } : loan
      )
    );
  };

  const handleHasOtherLoansChange = (event) => {
    setHasOtherLoans(event.target.value);
    if (event.target.value === "Yes" && otherLoans.length === 0) {
      addOtherLoan();
    }
  };

  //Section 5
  const handleMonthlyHouseholdIncomeChange = (e) => {
    setMonthlyHouseholdIncome(e.target.value);
  };

  const handleMonthlyMortgageRentLoanAmountChange = (e) => {
    setMonthlyMortgageRentLoanAmount(e.target.value);
  };

  const handleTotalBillsPerMonthChange = (e) => {
    setTotalBillsPerMonth(e.target.value);
  };

  const handleMonthlyLivingCostChange = (e) => {
    setMonthlyLivingCost(e.target.value);
  };

  useEffect(() => {
    const totalMonthlyHouseholdIncome =
      parseFloat(monthlyHouseholdIncome.replace(/£/g, "")) || 0;
    const monthlyMortgageRentLoan =
      parseFloat((combinedMonthlyAmount + "").replace(/£/g, "")) || 0; // Convert combinedMonthlyAmount to string before calling replace
    const totalBills = parseFloat(totalBillsPerMonth.replace(/£/g, "")) || 0;
    const livingCost = parseFloat(monthlyLivingCost.replace(/£/g, "")) || 0;
    const approxDisposableIncome =
      totalMonthlyHouseholdIncome -
      monthlyMortgageRentLoan -
      totalBills -
      livingCost;
    setApproxMonthlyDisposableIncome(`£${approxDisposableIncome.toFixed(2)}`);
  }, [
    monthlyHouseholdIncome,
    combinedMonthlyAmount, // Update the dependency to combinedMonthlyAmount
    totalBillsPerMonth,
    monthlyLivingCost,
  ]);

  const handleHowMuchInSavingsChange = (e) => {
    setHowMuchInSavings(e.target.value);
  };

  //Section 6

  const handleHasExistingCoverChange = (event) => {
    setHasExistingCover(event.target.value);
  };

  const handlePolicyTypeChange = (event, index) => {
    const newSections = [...additionalCoverSections];
    newSections[index].policyType = event.target.value;
    setAdditionalCoverSections(newSections);
  };

  const handlePersonCoveredChange = (event, index) => {
    const newSections = [...additionalCoverSections];
    newSections[index].personCovered = event.target.value;
    setAdditionalCoverSections(newSections);
  };

  const handleCoverAmountChange = (event, index) => {
    const newSections = [...additionalCoverSections];
    newSections[index].coverAmount = event.target.value;
    setAdditionalCoverSections(newSections);
  };

  const handleCoverTermChange = (event, index) => {
    const newSections = [...additionalCoverSections];
    newSections[index].coverTerm = event.target.value;
    setAdditionalCoverSections(newSections);
  };

  const handleInsurerChange = (event, index) => {
    const newSections = [...additionalCoverSections];
    newSections[index].insurer = event.target.value;
    setAdditionalCoverSections(newSections);
  };

  const handlePremiumChange = (event, index) => {
    const newSections = [...additionalCoverSections];
    newSections[index].premium = event.target.value;
    setAdditionalCoverSections(newSections);
  };

  const handleWhenSetUpChange = (event, index) => {
    const newSections = [...additionalCoverSections];
    newSections[index].whenSetUp = event.target.value;
    setAdditionalCoverSections(newSections);
  };

  const handleRemoveSection = (index) => {
    const newSections = [...additionalCoverSections];
    newSections.splice(index, 1);
    setAdditionalCoverSections(newSections);
  };

  const handleAddSection = () => {
    setAdditionalCoverSections([
      ...additionalCoverSections,
      {
        policyType: "",
        personCovered: "",
        coverAmount: "",
        coverTerm: "",
        insurer: "",
        whenSetUp: "",
        premium: "",
      },
    ]);
  };

  const handleSection6DetailsChange = (event) => {
    setSection6Details(event.target.value);
  };

  //Section 7
  const handleCovidStatusChange = (event) => {
    setCovidStatus(event.target.value);
  };

  const handlePartnerCovidStatusChange = (event) => {
    setPartnerCovidStatus(event.target.value);
  };

  const handleSeriousConditionsChange = (event) => {
    setSeriousConditions(event.target.value);
  };

  const handlePartnerSeriousConditionsChange = (event) => {
    setPartnerSeriousConditions(event.target.value);
  };

  const handleOtherConditionsChange = (event) => {
    setOtherConditions(event.target.value);
  };
  const handlePartnerOtherConditionsChange = (event) => {
    setPartnerOtherConditions(event.target.value);
  };

  const handleSection7FreeTextChange = (event) => {
    setSection7FreeText(event.target.value);
  };

  const handleSection7FreeText2Change = (event) => {
    setSection7FreeText2(event.target.value);
  };

  const handlePartnerSection7FreeTextChange = (event) => {
    setPartnerSection7FreeText(event.target.value);
  };

  const handlePartnerSection7FreeText2Change = (event) => {
    setPartnerSection7FreeText2(event.target.value);
  };

  const handlePhysicalConditionsChange = (event) => {
    setPhysicalConditions(event.target.value);
  };

  const handlePartnerPhysicalConditionsChange = (event) => {
    setPartnerPhysicalConditions(event.target.value);
  };

  const handleMentalHealthChange = (event) => {
    setMentalHealth(event.target.value);
  };

  const handlePartnerMentalHealthChange = (event) => {
    setPartnerMentalHealth(event.target.value);
  };

  const handleMedicationChange = (event) => {
    setMedication(event.target.value);
  };

  const handlePartnerMedicationChange = (event) => {
    setPartnerMedication(event.target.value);
  };

  const handleFamilyHistoryChange = (event) => {
    setFamilyHistory(event.target.value);
  };

  const handlePartnerFamilyHistoryChange = (event) => {
    setPartnerFamilyHistory(event.target.value);
  };

  const handleHobbiesChange = (event) => {
    setHobbies(event.target.value);
  };

  const handlePartnerHobbiesChange = (event) => {
    setPartnerHobbies(event.target.value);
  };

  const handleTravelHistoryChange = (event) => {
    setTravelHistory(event.target.value);
  };

  const handlePartnerTravelHistoryChange = (event) => {
    setPartnerTravelHistory(event.target.value);
  };

  const handleBmiChange = (event) => {
    setBmi(event.target.value);
  };

  const handleTravelPast5YearsChange = (event) => {
    setTravelPast5Years(event.target.value);
  };

  const handlePartnerTravelPast5YearsChange = (event) => {
    setPartnerTravelPast5Years(event.target.value);
  };

  const handleRideBikesChange = (event) => {
    setRideBikes(event.target.value);
  };

  const handlePartnerRideBikesChange = (event) => {
    setPartnerRideBikes(event.target.value);
  };

  const handleCurrentSizeChange = (event) => {
    setCurrentSize(event.target.value);
  };

  const handlePartnerCurrentSizeChange = (event) => {
    setPartnerCurrentSize(event.target.value);
  };

  const handlePotentiallyVulnerableChange = (event) => {
    setPotentiallyVulnerable(event.target.value);
  };

  const handlePartnerPotentiallyVulnerableChange = (event) => {
    setPartnerPotentiallyVulnerable(event.target.value);
  };

  const handlePartnerBmiChange = (event) => {
    setPartnerBmi(event.target.value);
  };

  //Section 7 Free type Boxes
  const handleCovidStatusDetailsChange = (event) => {
    setCovidStatusDetails(event.target.value);
  };

  const handleSeriousConditionsDetailsChange = (event) => {
    setSeriousConditionsDetails(event.target.value);
  };

  const handleOtherConditionsDetailsChange = (event) => {
    setOtherConditionsDetails(event.target.value);
  };

  const handlePhysicalConditionsDetailsChange = (event) => {
    setPhysicalConditionsDetails(event.target.value);
  };

  const handleMentalHealthDetailsChange = (event) => {
    setMentalHealthDetails(event.target.value);
  };

  const handleMedicationDetailsChange = (event) => {
    setMedicationDetails(event.target.value);
  };

  const handleFamilyHistoryDetailsChange = (event) => {
    setFamilyHistoryDetails(event.target.value);
  };

  const handleHobbiesDetailsChange = (event) => {
    setHobbiesDetails(event.target.value);
  };

  const handleTravelHistoryDetailsChange = (event) => {
    setTravelHistoryDetails(event.target.value);
  };

  const handleBmiDetailsChange = (event) => {
    setBmiDetails(event.target.value);
  };

  const handleTravelPast5YearsDetailsChange = (event) => {
    setTravelPast5YearsDetails(event.target.value);
  };

  const handleRideBikesDetailsChange = (event) => {
    setRideBikesDetails(event.target.value);
  };

  const handlePotentiallyVulnerableDetailsChange = (event) => {
    setPotentiallyVulnerableDetails(event.target.value);
  };

  //Partner Section 7 Free type Boxes
  const handlePartnerCovidStatusDetailsChange = (event) => {
    setPartnerCovidStatusDetails(event.target.value);
  };

  const handlePartnerSeriousConditionsDetailsChange = (event) => {
    setPartnerSeriousConditionsDetails(event.target.value);
  };

  const handlePartnerOtherConditionsDetailsChange = (event) => {
    setPartnerOtherConditionsDetails(event.target.value);
  };

  const handlePartnerPhysicalConditionsDetailsChange = (event) => {
    setPartnerPhysicalConditionsDetails(event.target.value);
  };

  const handlePartnerMentalHealthDetailsChange = (event) => {
    setPartnerMentalHealthDetails(event.target.value);
  };

  const handlePartnerMedicationDetailsChange = (event) => {
    setPartnerMedicationDetails(event.target.value);
  };

  const handlePartnerFamilyHistoryDetailsChange = (event) => {
    setPartnerFamilyHistoryDetails(event.target.value);
  };

  const handlePartnerHobbiesDetailsChange = (event) => {
    setPartnerHobbiesDetails(event.target.value);
  };

  const handlePartnerTravelHistoryDetailsChange = (event) => {
    setPartnerTravelHistoryDetails(event.target.value);
  };

  const handlePartnerBmiDetailsChange = (event) => {
    setPartnerBmiDetails(event.target.value);
  };

  const handlePartnerTravelPast5YearsDetailsChange = (event) => {
    setPartnerTravelPast5YearsDetails(event.target.value);
  };

  const handlePartnerRideBikesDetailsChange = (event) => {
    setPartnerRideBikesDetails(event.target.value);
  };

  const handlePartnerPotentiallyVulnerableDetailsChange = (event) => {
    setPartnerPotentiallyVulnerableDetails(event.target.value);
  };

  const handleSection7DetailsChange = (event) => {
    setSection7Details(event.target.value);
  };

  const handlecaseId = (event) => {
    setcaseId(event.target.value);
  };

  async function factfinderExists(caseId) {
    try {
      const response = await fetch(
        `https://fact-finder.herokuapp.com/api/check-caseid/${caseId}`
      );
      const data = await response.json();
      return data.exists;
    } catch (error) {
      console.error("Error checking for factfinder existence:", error.message);
      return false;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!showConfirmPopup) {
      setShowConfirmPopup(true);
      return;
    }

    const exists = await factfinderExists(caseId);

    if (exists) {
      alert(
        "A factfinder with the same CaseID already exists. Please use a different CaseID."
      );
      return;
    }
    console.log("Fact Data Initiated!");

    // Preparing the body data to be reused in the fetch request
    const bodyData = JSON.stringify({
      caseId,
      lookingToCover,
      regulatoryScriptRead,
      factFindDate,
      lookingToCoverPartner,
      client1Title,
      client1FirstName,
      client1Surname,
      client1DateOfBirth,
      client1Age,
      client1SmokerStatus,
      client1LastNicotineUse,
      client1CigarettesPerDay,
      client1MaritalStatus,
      client2Title,
      client2FirstName,
      client2Surname,
      client2DateOfBirth,
      client2Age,
      childrenAgeOfFinancialIndependence,
      client2SmokerStatus,
      client2LastNicotineUse,
      client2CigarettesPerDay,
      hasChildren,
      numberOfChildren,
      childrenAges,
      client1EmploymentStatus,
      client1GrossAnnualIncomePAYE,
      client1GrossAnnualIncomeDividends,
      client1CompanyType,
      client1YearsEstablished,
      client1GrossAnnualIncome,
      client1MonthlyBenefits,
      client1NetMonthlyIncome,
      client1JobTitle,
      client1SickPay,
      client1FullSickPayMonths,
      client1HalfSickPayMonths,
      client1DeathInService,
      client1YearsOfSalary,
      client1RetirementAge,
      client1OtherIncome,
      client1AdditionalIncomes: additionalIncomes.map((income) => ({
        amount: income.amount,
        source: income.source,
      })),
      client1NotesSection2,
      client2EmploymentStatus,
      client2CompanyType,
      client2YearsEstablished,
      client2GrossAnnualIncomePAYE,
      client2GrossAnnualIncomeDividends,
      client2GrossAnnualIncome,
      client2MonthlyBenefits,
      client2NetMonthlyIncome,
      client2JobTitle,
      client2SickPay,
      client2FullSickPayMonths,
      client2HalfSickPayMonths,
      client2DeathInService,
      client2YearsOfSalary,
      client2RetirementAge,
      client2OtherIncome,
      client2AdditionalIncomes: additionalIncomesClient2.map((income) => ({
        amount: income.amount,
        source: income.source,
      })),
      client2NotesSection2,
      housingStatus,
      mortgageType,
      outstandingMortgageAmount,
      outstandingMortgageTerm,
      monthlyMortgagePayment,
      lender,
      interestRate,
      rateType,
      rateDealEndDate,
      monthlyRentalAmount,
      livingWithFamilyRentAmount,
      showMortgageQuestions,
      showRentQuestions,
      showLivingWithFamilyQuestions,
      notesSection3,
      hasOtherMortgages,
      otherMortgages: otherMortgages.map((mortgage) => ({
        mortgageType2: mortgage.mortgageType2,
        mortgageAmountOutstanding: mortgage.mortgageAmountOutstanding,
        monthlyMortgageTerm: mortgage.monthlyMortgageTerm,
        monthlyMortgagePayment2: mortgage.monthlyMortgagePayment2,
        mortgageLender: mortgage.mortgageLender,
        mortgageInterestRate: mortgage.mortgageInterestRate,
        mortgageRateType: mortgage.mortgageRateType,
        mortgageRateDealEnd: mortgage.mortgageRateDealEnd,
      })),
      hasOtherLoans,
      otherLoans: otherLoans.map((loan) => ({
        loanType: loan.loanType,
        loanAmountOutstanding: loan.loanAmountOutstanding,
        loanTermOutstanding: loan.loanTermOutstanding,
        monthlyLoanPayment: loan.monthlyLoanPayment,
      })),
      monthlyHouseholdIncome,
      monthlyMortgageRentLoanAmount,
      totalBillsPerMonth,
      monthlyLivingCost,
      approxMonthlyDisposableIncome,
      howMuchInSavings,
      hasExistingCover,
      additionalCoverSections: additionalCoverSections.map((section) => ({
        policyType: section.policyType,
        personCovered: section.personCovered,
        coverAmount: section.coverAmount,
        coverTerm: section.coverTerm,
        insurer: section.insurer,
        whenSetUp: section.whenSetUp,
        premium: section.premium,
      })),
      section6details,
      covidStatus,
      covidStatusDetails,
      partnerCovidStatus,
      partnerCovidStatusDetails,
      seriousConditions,
      seriousConditionsDetails,
      partnerSeriousConditions,
      partnerSeriousConditionsDetails,
      otherConditions,
      otherConditionsDetails,
      partnerOtherConditions,
      partnerOtherConditionsDetails,
      physicalConditions,
      physicalConditionsDetails,
      partnerPhysicalConditions,
      partnerPhysicalConditionsDetails,
      mentalHealth,
      mentalHealthDetails,
      partnerMentalHealth,
      partnerMentalHealthDetails,
      medication,
      medicationDetails,
      partnerMedication,
      partnerMedicationDetails,
      familyHistory,
      familyHistoryDetails,
      partnerFamilyHistory,
      partnerFamilyHistoryDetails,
      hobbies,
      hobbiesDetails,
      partnerHobbies,
      partnerHobbiesDetails,
      travelHistory,
      travelHistoryDetails,
      partnerTravelHistory,
      partnerTravelHistoryDetails,
      bmi,
      bmiDetails,
      partnerBmi,
      partnerBmiDetails,
      travelPast5Years,
      travelPast5YearsDetails,
      partnerTravelPast5Years,
      partnerTravelPast5YearsDetails,
      rideBikes,
      rideBikesDetails,
      partnerRideBikes,
      partnerRideBikesDetails,
      currentSize,
      partnerCurrentSize,
      potentiallyVulnerable,
      potentiallyVulnerableDetails,
      partnerPotentiallyVulnerable,
      partnerPotentiallyVulnerableDetails,
      section7details,
    });

    // POST request to the server to save the data
    fetch(`https://fact-finder.herokuapp.com/api/save-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data saved:", data);

        setModalMessage("Factfinder saved successfully!");
      })
      .catch((error) => {
        console.error("Error saving data:", error.message);
        setModalMessage(
          "Error saving FactFinder. Please check if you have CaseID, Looking to Cover and Looking to Cover Partner!"
        );
      });
  };

  const handleCancel = () => {
    setShowConfirmPopup(false);
  };

  const closeModal = () => {
    setModalMessage("");
  };

  const handleSave = () => {
    handleSubmit(new Event("submit"));
    setShowConfirmPopup(false);
  };

  return (
    <>
      <Header isLoggedIn={isLoggedIn} />
      <div className="App">
        <form onSubmit={handleSubmit} ref={componentRef}>
          <div>
            <div>
              {/*  <==Section 1 code==> */}
              <div className="form-section">
                <div>
                  <label htmlFor="caseid">Case Id?</label>
                  <input
                    type="text"
                    name="caseid"
                    id="caseid"
                    value={caseId}
                    onChange={handlecaseId}
                  />
                </div>
                <div>
                  <label htmlFor="regulatoryScriptRead">
                    Was regulatory script read to client?
                    <InfoIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                      data-tip
                      data-for="regulatoryScriptRead"
                    />
                    <ReactTooltip
                      id="regulatoryScriptRead"
                      className="custom-tooltip"
                      effect="solid"
                      multiline={true}
                    >
                      <ul>
                        <p>
                          When you made your enquiry, you were sent a link to
                          our Privacy Policy and Terms of Business. I’m not sure
                          if you’ve managed to have a look at these, so just in
                          case you haven’t I’ll make you aware of a few
                          important things!
                        </p>
                        <li>
                          As explained, we are Blue Light Insurance Solutions, a
                          trading name of Lifetime Financial Management who are
                          authorised and regulated by the Financial Conduct
                          Authority.
                        </li>
                        <li>
                          We specialise in helping NHS, Emergency Services,
                          Civil Service and Public Sector staff, and are
                          regulated to provide you with insurance advice.
                        </li>
                        <li>
                          This means we’re not just a call centre that gives you
                          quotes and then calls you ten times a day, we’re a
                          dedicated team of advisers who take the time to
                          understand your personal circumstances and then
                          provide bespoke insurance recommendations based on
                          that.
                        </li>
                        <li>
                          {" "}
                          We do not charge fees for our service, however if you
                          do decide to take out cover through us, we are paid
                          commission by the insurer.
                        </li>
                        <li>
                          {" "}
                          We offer products from a very large number of
                          insurers, and unlike many of our competitors we are
                          not tied to offering cover from one insurer. This
                          means we’ll always recommend the insurer that is best
                          for YOU!
                        </li>
                        <li>
                          Lastly, I hope this won’t be the case, but if you ever
                          did want to make a complaint about our service, then
                          the information you need to do this can be found on
                          our terms of business document.
                        </li>
                        <p>
                          Does that all make sense, or do you have any
                          questions?
                        </p>
                      </ul>
                    </ReactTooltip>
                  </label>
                  <select
                    id="regulatoryScriptRead"
                    value={regulatoryScriptRead}
                    onChange={handleRegulatoryScriptReadChange}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="factFindDate">
                    Fact Find Date
                  </label>
                  <input
                    type="date"
                    id="factFindDate"
                    value={factFindDate}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-section">
                <h2>Section 1 - Basic Info / Family</h2>
                <div>
                  <label htmlFor="lookingToCover">
                    What are you looking to cover?
                  </label>
                  {!isPrinting ? (
                    <ResizableTextarea
                      type="text"
                      name="lookingtocover"
                      id="lookingToCover"
                      value={lookingToCover}
                      onChange={handleLookingToCoverChange}
                    />
                  ) : (
                    <div>
                      <ReactMarkdown>{lookingToCover}</ReactMarkdown>
                    </div>
                  )}
                </div>
                <div>
                  <label htmlFor="lookingToCoverPartner">
                    Looking to cover partner?
                  </label>
                  <select
                    id="lookingToCoverPartner"
                    value={lookingToCoverPartner}
                    onChange={handleLookingToCoverPartnerChange}
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>
                <div
                  className={
                    lookingToCoverPartner === "Yes" ? "client-info" : ""
                  }
                >
                  <h3>Client 1</h3>
                  <div>
                    <label htmlFor="client1Title">Title</label>
                    <select
                      id="client1Title"
                      value={client1Title}
                      onChange={handleClient1TitleChange}
                    >
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                      <option value="Ms">Ms</option>
                      <option value="Dr">Dr</option>
                      <option value="Prof">Prof</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="client1FirstName">First Name</label>
                    <input
                      type="text"
                      id="client1FirstName"
                      value={client1FirstName}
                      onChange={handleClient1FirstNameChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="client1Surname">Surname</label>
                    <input
                      type="text"
                      id="client1Surname"
                      value={client1Surname}
                      onChange={handleClient1SurnameChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="client1DateOfBirth">Date of Birth</label>
                    <input
                      type="date"
                      id="client1DateOfBirth"
                      value={client1DateOfBirth}
                      onChange={handleClient1DateOfBirthChange}
                      onBlur={handleClient1DateOfBirthBlur}
                    />
                  </div>
                  <div>
                    <label htmlFor="client1Age">Age</label>
                    <input
                      type="number"
                      id="client1Age"
                      value={client1Age}
                      onChange={handleClient1AgeChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="client1SmokerStatus">Smoker Status</label>
                    <select
                      id="client1SmokerStatus"
                      value={client1SmokerStatus}
                      onChange={handleClient1SmokerStatusChange}
                    >
                      <option value="Smoker">Smoker</option>
                      <option value="Non Smoker">Non Smoker</option>
                      <option value="Ex Smoker">Ex Smoker</option>
                      <option value="Vaper">Vaper</option>
                    </select>
                  </div>
                  {client1SmokerStatus === "Ex Smoker" && (
                    <div>
                      <label htmlFor="client1LastNicotineUse">
                        When did you last use nicotine?
                      </label>
                      <input
                        type="date"
                        id="client1LastNicotineUse"
                        value={client1LastNicotineUse}
                        onChange={handleClient1LastNicotineUseChange}
                      />
                    </div>
                  )}
                  {client1SmokerStatus === "Smoker" && (
                    <div>
                      <label htmlFor="client1CigarettesPerDay">
                        How many cigarettes per day?
                      </label>
                      <input
                        type="text"
                        id="client1CigarettesPerDay"
                        value={client1CigarettesPerDay}
                        onChange={handleClient1CigarettesPerDayChange}
                      />
                    </div>
                  )}
                  <div>
                    <label htmlFor="client1MaritalStatus">Marital Status</label>
                    <select
                      id="client1MaritalStatus"
                      value={client1MaritalStatus}
                      onChange={handleClient1MaritalStatusChange}
                    >
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Cohabiting">Cohabiting</option>
                      <option value="Civil Partnership">
                        Civil Partnership
                      </option>
                      <option value="Divorced">Divorced</option>
                      <option value="Separated">Separated</option>
                      <option value="Engaged">Engaged</option>
                      <option value="Widowed">Widowed</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                {lookingToCoverPartner === "Yes" && (
                  <div className="client-info">
                    <h3>Client 2</h3>
                    <div>
                      <label htmlFor="client2Title">Title</label>
                      <select
                        id="client2Title"
                        value={client2Title}
                        onChange={handleClient2TitleChange}
                      >
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Miss">Miss</option>
                        <option value="Ms">Ms</option>
                        <option value="Dr">Dr</option>
                        <option value="Prof">Prof</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="client2FirstName">First Name</label>
                      <input
                        type="text"
                        id="client2FirstName"
                        value={client2FirstName}
                        onChange={handleClient2FirstNameChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="client2Surname">Surname</label>
                      <input
                        type="text"
                        id="client2Surname"
                        value={client2Surname}
                        onChange={handleClient2SurnameChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="client2DateOfBirth">Date of Birth</label>
                      <input
                        type="date"
                        id="client2DateOfBirth"
                        value={client2DateOfBirth}
                        onChange={handleClient2DateOfBirthChange}
                        onBlur={handleClient2DateOfBirthBlur}
                      />
                    </div>
                    <div>
                      <label htmlFor="client2Age">Age</label>
                      <input
                        type="number"
                        id="client2Age"
                        value={client2Age}
                        onChange={handleClient2AgeChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="client2SmokerStatus">Smoker Status</label>
                      <select
                        id="client2SmokerStatus"
                        value={client2SmokerStatus}
                        onChange={handleClient2SmokerStatusChange}
                      >
                        <option value="Smoker">Smoker</option>
                        <option value="Non Smoker">Non Smoker</option>
                        <option value="Ex Smoker">Ex Smoker</option>
                        <option value="Vaper">Vaper</option>
                      </select>
                    </div>
                    {client2SmokerStatus === "Ex Smoker" && (
                      <div>
                        <label htmlFor="client2LastNicotineUse">
                          When did you last use nicotine?
                        </label>
                        <input
                          type="date"
                          id="client2LastNicotineUse"
                          value={client2LastNicotineUse}
                          onChange={handleClient2LastNicotineUseChange}
                        />
                      </div>
                    )}
                    {client2SmokerStatus === "Smoker" && (
                      <div>
                        <label htmlFor="client2CigarettesPerDay">
                          How many cigarettes per day?
                        </label>
                        <input
                          type="text"
                          id="client2CigarettesPerDay"
                          value={client2CigarettesPerDay}
                          onChange={handleClient2CigarettesPerDayChange}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={
                    lookingToCoverPartner === "Yes" ? "client-info" : ""
                  }
                >
                  <div>
                    <label htmlFor="hasChildren">Do you have children?</label>
                    <select
                      id="hasChildren"
                      value={hasChildren}
                      onChange={handleHasChildrenChange}
                    >
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                      <option value="Expecting">Expecting</option>
                    </select>
                  </div>
                  {hasChildren === "Yes" && (
                    <div>
                      <label htmlFor="numberOfChildren">How many?</label>
                      <select
                        value={numberOfChildren}
                        onChange={handleNumberOfChildrenChange}
                      >
                        <option value="0">No</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                      {Array.from(
                        { length: parseInt(numberOfChildren) },
                        (_, index) => (
                          <div key={`child-${index + 1}`}>
                            <label htmlFor={`childAge${index + 1}`}>
                              Child {index + 1} Age:
                            </label>
                            <input
                              type="number"
                              id={`childAge${index + 1}`}
                              name={`childAge${index + 1}`}
                              value={childrenAges[index] || ""}
                              min="0"
                              max="99"
                              onChange={(event) =>
                                handleChildAgeChange(event, index)
                              }
                              required
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                  {(hasChildren === "Yes" || hasChildren === "Expecting") && (
                    <div>
                      <label htmlFor="childrenAgeOfFinancialIndependence">
                        Children age of financial independence
                      </label>
                      <input
                        type="text"
                        id="childrenAgeOfFinancialIndependence"
                        value={childrenAgeOfFinancialIndependence}
                        onChange={
                          handleChildrenAgeOfFinancialIndependenceChange
                        }
                        required
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              {/*  <==Section 2 code==> */}
              <div className="form-section">
                <h2>Section 2 - Job / Income</h2>
                <div
                  className={
                    lookingToCoverPartner === "Yes" ? "client-info" : ""
                  }
                >
                  <h3>Client 1</h3>
                  <div>
                    <label htmlFor="client1JobTitle">Job Title</label>
                    <input
                      type="text"
                      id="client1JobTitle"
                      value={client1JobTitle}
                      onChange={handleClient1JobTitleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="client1EmploymentStatus">
                      Employment Status
                    </label>
                    <select
                      id="client1EmploymentStatus"
                      value={client1EmploymentStatus}
                      onChange={handleClient1EmploymentStatusChange}
                    >
                      <option value="Employed (Full Time)">
                        Employed (Full Time)
                      </option>
                      <option value="Employed (Part Time)">
                        Employed (Part Time)
                      </option>
                      <option value="Self Employed">Self Employed</option>
                      <option value="Employed (Own Business)">
                        Employed (Own Business)
                      </option>
                      <option value="Unemployed">Unemployed</option>
                      <option value="Retired">Retired</option>
                      <option value="Medically Retired">
                        Medically Retired
                      </option>
                    </select>
                  </div>
                  {client1EmploymentStatus === "Employed (Full Time)" ||
                  client1EmploymentStatus === "Employed (Part Time)" ||
                  client1EmploymentStatus === "Self Employed" ||
                  client1EmploymentStatus === "Employed (Own Business)" ? (
                    <>
                      {client1EmploymentStatus === "Self Employed" && (
                        <>
                          <div>
                            <label htmlFor="client1CompanyType">
                              Ltd company or sole trader?
                            </label>
                            <input
                              type="text"
                              id="client1CompanyType"
                              value={client1CompanyType}
                              onChange={handleClient1CompanyTypeChange}
                            />
                          </div>
                          <div>
                            <label htmlFor="client1yearsEstablished">
                              How many years established
                            </label>
                            <input
                              type="number"
                              id="client1YearsEstablished"
                              value={client1YearsEstablished}
                              onChange={handleClient1YearsEstablishedChange}
                            />
                          </div>
                        </>
                      )}
                      {client1EmploymentStatus !==
                        "Employed (Own Business)" && (
                        <div>
                          <label htmlFor="client1GrossAnnualIncome">
                            {client1EmploymentStatus === "Self Employed"
                              ? "Gross Annual Income (per tax return)"
                              : "Gross Annual Income"}
                          </label>
                          <input
                            type="number"
                            id="client1GrossAnnualIncome"
                            value={client1GrossAnnualIncome}
                            onChange={handleClient1GrossAnnualIncomeChange}
                          />
                        </div>
                      )}

                      {client1EmploymentStatus ===
                        "Employed (Own Business)" && (
                        <>
                          <div>
                            <label htmlFor="client1GrossAnnualIncomePAYE">
                              Gross Annual Income (PAYE)
                            </label>
                            <input
                              type="number"
                              id="client1GrossAnnualIncomePAYE"
                              value={client1GrossAnnualIncomePAYE}
                              onChange={
                                handleClient1GrossAnnualIncomePAYEChange
                              }
                            />
                          </div>
                          <div>
                            <label htmlFor="client1GrossAnnualIncomeDividends">
                              Gross Annual Income (Dividends)
                            </label>
                            <input
                              type="number"
                              id="client1GrossAnnualIncomeDividends"
                              value={client1GrossAnnualIncomeDividends}
                              onChange={
                                handleClient1GrossAnnualIncomeDividendsChange
                              }
                            />
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div>
                      <label htmlFor="client1MonthlyBenefits">
                        Approx. monthly benefits
                      </label>
                      <input
                        type="number"
                        id="client1MonthlyBenefits"
                        value={client1MonthlyBenefits}
                        onChange={handleClient1MonthlyBenefitsChange}
                      />
                    </div>
                  )}

                  <div>
                    <label htmlFor="client1NetMonthlyIncome">
                      Net Monthly Income
                    </label>
                    <input
                      type="number"
                      id="client1NetMonthlyIncome"
                      value={client1NetMonthlyIncome}
                      onChange={handleClient1NetMonthlyIncomeChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="client1SickPay">Do you get sick pay?</label>
                    <select
                      id="client1SickPay"
                      value={client1SickPay}
                      onChange={handleClient1SickPayChange}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Not Sure">Not Sure</option>
                    </select>
                    {client1SickPay === "Yes" && (
                      <>
                        <div>
                          <label htmlFor="client1FullSickPayMonths">
                            How many months of full sick pay do you get?
                          </label>
                          <input
                            type="number"
                            id="client1FullSickPayMonths"
                            value={client1FullSickPayMonths}
                            onChange={handleClient1FullSickPayMonthsChange}
                          />
                        </div>
                        <div>
                          <label htmlFor="client1HalfSickPayMonths">
                            How many months of half sick pay do you get?
                          </label>
                          <input
                            type="number"
                            id="client1HalfSickPayMonths"
                            value={client1HalfSickPayMonths}
                            onChange={handleClient1HalfSickPayMonthsChange}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <label htmlFor="client1DeathInService">
                      Do you get death in service?
                    </label>
                    <select
                      id="client1DeathInService"
                      value={client1DeathInService}
                      onChange={handleClient1DeathInServiceChange}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Not Sure">Not Sure</option>
                    </select>
                  </div>
                  {client1DeathInService === "Yes" && (
                    <div>
                      <label htmlFor="client1YearsOfSalary">
                        How many years of salary?
                      </label>
                      <input
                        type="number"
                        id="client1YearsOfSalary"
                        value={client1YearsOfSalary}
                        onChange={handleClient1YearsOfSalaryChange}
                      />
                    </div>
                  )}
                  <div>
                    <label htmlFor="client1RetirementAge">
                      Intended retirement age?
                    </label>
                    <input
                      type="number"
                      id="client1RetirementAge"
                      value={client1RetirementAge}
                      onChange={handleClient1RetirementAgeChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="client1OtherIncome">
                      Do you earn income from anywhere else?
                    </label>
                    <select
                      id="client1OtherIncome"
                      value={client1OtherIncome}
                      onChange={handleClient1OtherIncomeChange}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Not Sure">Not Sure</option>
                    </select>
                    {client1OtherIncome === "Yes" && (
                      <div>
                        {additionalIncomes.map((income, index) => (
                          <div key={index}>
                            <h3>Additional Income {index + 1}</h3>

                            <div className="input-group">
                              <label>Enter amount</label>
                              <input
                                type="number"
                                value={income.amount}
                                onChange={(event) =>
                                  handleAdditionalIncomeAmountChange(
                                    event,
                                    index
                                  )
                                }
                                placeholder="£"
                              />
                            </div>
                            <div className="input-group">
                              <label>What is the source?</label>
                              <input
                                type="text"
                                value={income.source}
                                onChange={(event) =>
                                  handleAdditionalIncomeSourceChange(
                                    event,
                                    index
                                  )
                                }
                              />
                            </div>

                            {index === additionalIncomes.length - 1 && (
                              <button onClick={handleAddAdditionalIncome}>
                                Add another
                              </button>
                            )}

                            {index !== 0 && (
                              <button
                                onClick={() =>
                                  handleRemoveAdditionalIncome(index)
                                }
                              >
                                Remove
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="input-group">
                    <label>Notes </label>
                    <ResizableTextarea
                      id="client1NotesSection2"
                      value={client1NotesSection2}
                      onChange={handleClient1NotesSection2Change}
                    />
                  </div>
                </div>

                {lookingToCoverPartner === "Yes" && (
                  <div className="client-info">
                    <h3>Client 2</h3>
                    {/* Client 2 questions */}
                    <div>
                      <div>
                        <label htmlFor="client2JobTitle">Job Title</label>
                        <input
                          type="text"
                          id="client2JobTitle"
                          value={client2JobTitle}
                          onChange={handleClient2JobTitleChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="client2EmploymentStatus">
                          Employment Status
                        </label>
                        <select
                          id="client2EmploymentStatus"
                          value={client2EmploymentStatus}
                          onChange={handleClient2EmploymentStatusChange}
                        >
                          <option value="Employed (Full Time)">
                            Employed (Full Time)
                          </option>
                          <option value="Employed (Part Time)">
                            Employed (Part Time)
                          </option>
                          <option value="Self Employed">Self Employed</option>
                          <option value="Employed (Own Business)">
                            Employed (Own Business)
                          </option>
                          <option value="Unemployed">Unemployed</option>
                          <option value="Retired">Retired</option>
                          <option value="Medically Retired">
                            Medically Retired
                          </option>
                        </select>
                      </div>
                      {client2EmploymentStatus === "Employed (Full Time)" ||
                      client2EmploymentStatus === "Employed (Part Time)" ||
                      client2EmploymentStatus === "Self Employed" ||
                      client2EmploymentStatus === "Employed (Own Business)" ? (
                        <>
                          {client2EmploymentStatus === "Self Employed" && (
                            <>
                              <div>
                                <label htmlFor="client2CompanyType">
                                  Ltd company or sole trader?
                                </label>
                                <input
                                  type="text"
                                  id="client2CompanyType"
                                  value={client2CompanyType}
                                  onChange={handleClient2CompanyTypeChange}
                                />
                              </div>
                              <div>
                                <label htmlFor="client2yearsEstablished">
                                  How many years established?
                                </label>
                                <input
                                  type="number"
                                  id="client2yearsEstablished"
                                  value={client2YearsEstablished}
                                  onChange={handleClient2YearsEstablishedChange}
                                />
                              </div>
                            </>
                          )}
                          {client2EmploymentStatus !==
                            "Employed (Own Business)" && (
                            <div>
                              <label htmlFor="client2GrossAnnualIncome">
                                {client2EmploymentStatus === "Self Employed"
                                  ? "Gross Annual Income (per tax return)"
                                  : "Gross Annual Income"}
                              </label>
                              <input
                                type="number"
                                id="client2GrossAnnualIncome"
                                value={client2GrossAnnualIncome}
                                onChange={handleClient2GrossAnnualIncomeChange}
                              />
                            </div>
                          )}

                          {client2EmploymentStatus ===
                            "Employed (Own Business)" && (
                            <>
                              <div>
                                <label htmlFor="client2GrossAnnualIncomePAYE">
                                  Gross Annual Income (PAYE)
                                </label>
                                <input
                                  type="number"
                                  id="client2GrossAnnualIncomePAYE"
                                  value={client2GrossAnnualIncomePAYE}
                                  onChange={
                                    handleClient2GrossAnnualIncomePAYEChange
                                  }
                                />
                              </div>
                              <div>
                                <label htmlFor="client2GrossAnnualIncomeDividends">
                                  Gross Annual Income (Dividends)
                                </label>
                                <input
                                  type="number"
                                  id="client2GrossAnnualIncomeDividends"
                                  value={client2GrossAnnualIncomeDividends}
                                  onChange={
                                    handleClient2GrossAnnualIncomeDividendsChange
                                  }
                                />
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <div>
                          <label htmlFor="client2MonthlyBenefits">
                            Approx. monthly benefits
                          </label>
                          <input
                            type="number"
                            id="client2MonthlyBenefits"
                            value={client2MonthlyBenefits}
                            onChange={handleClient2MonthlyBenefitsChange}
                          />
                        </div>
                      )}
                      <div>
                        <label htmlFor="client2NetMonthlyIncome">
                          Net Monthly Income
                        </label>
                        <input
                          type="number"
                          id="client2NetMonthlyIncome"
                          value={client2NetMonthlyIncome}
                          onChange={handleClient2NetMonthlyIncomeChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="client2SickPay">
                          Do you get sick pay?
                        </label>
                        <select
                          id="client2SickPay"
                          value={client2SickPay}
                          onChange={handleClient2SickPayChange}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="Not Sure">Not Sure</option>
                        </select>
                        {client2SickPay === "Yes" && (
                          <>
                            <div>
                              <label htmlFor="client2FullSickPayMonths">
                                How many months of full sick pay do you get?
                              </label>
                              <input
                                type="number"
                                id="client2FullSickPayMonths"
                                value={client2FullSickPayMonths}
                                onChange={handleClient2FullSickPayMonthsChange}
                              />
                            </div>
                            <div>
                              <label htmlFor="client2HalfSickPayMonths">
                                How many months of half sick pay do you get?
                              </label>
                              <input
                                type="number"
                                id="client2HalfSickPayMonths"
                                value={client2HalfSickPayMonths}
                                onChange={handleClient2HalfSickPayMonthsChange}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div>
                        <label htmlFor="client2DeathInService">
                          Do you get death in service?
                        </label>
                        <select
                          id="client2DeathInService"
                          value={client2DeathInService}
                          onChange={handleClient2DeathInServiceChange}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="Not Sure">Not Sure</option>
                        </select>
                      </div>
                      {client2DeathInService === "Yes" && (
                        <div>
                          <label htmlFor="client2YearsOfSalary">
                            How many years of salary?
                          </label>
                          <input
                            type="number"
                            id="client2YearsOfSalary"
                            value={client2YearsOfSalary}
                            onChange={handleClient2YearsOfSalaryChange}
                          />
                        </div>
                      )}
                      <div>
                        <label htmlFor="client2RetirementAge">
                          Intended retirement age?
                        </label>
                        <input
                          type="number"
                          id="client2RetirementAge"
                          value={client2RetirementAge}
                          onChange={handleClient2RetirementAgeChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="client2OtherIncome">
                          Do you earn income from anywhere else?
                        </label>
                        <select
                          id="client2OtherIncome"
                          value={client2OtherIncome}
                          onChange={handleClient2OtherIncomeChange}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="Not Sure">Not Sure</option>
                        </select>
                        {client2OtherIncome === "Yes" && (
                          <div>
                            {additionalIncomesClient2.map((income, index) => (
                              <div key={index}>
                                <h3>Additional Income {index + 1}</h3>

                                <div className="input-group">
                                  <label>Enter amount</label>
                                  <input
                                    type="number"
                                    value={income.amount}
                                    onChange={(event) =>
                                      handleAdditionalIncomeAmountChangeClient2(
                                        event,
                                        index
                                      )
                                    }
                                    placeholder="£"
                                  />
                                </div>
                                <div className="input-group">
                                  <label>What is the source?</label>
                                  <input
                                    type="text"
                                    value={income.source}
                                    onChange={(event) =>
                                      handleAdditionalIncomeSourceChangeClient2(
                                        event,
                                        index
                                      )
                                    }
                                  />
                                </div>

                                {index ===
                                  additionalIncomesClient2.length - 1 && (
                                  <button
                                    onClick={handleAddAdditionalIncomeClient2}
                                  >
                                    Add another
                                  </button>
                                )}

                                {index !== 0 && (
                                  <button
                                    onClick={() =>
                                      handleRemoveAdditionalIncomeClient2(index)
                                    }
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="input-group">
                      <label>Notes </label>
                      <ResizableTextarea
                        id="client2NotesSection2"
                        value={client2NotesSection2}
                        onChange={handleCleint2NotesSection2Change}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/*  <==Section 3 code==> */}
            <div>
              <div className="form-section">
                <h2>Section 3 - Rent / Mortgage</h2>
                <div className="input-group">
                  <label htmlFor="housingStatus">
                    Do you own a home or rent?
                  </label>
                  <select
                    id="housingStatus"
                    value={housingStatus}
                    onChange={handleHousingStatusChange}
                  >
                    <option value="">Please select</option>
                    <option value="Mortgage">Mortgage</option>
                    <option value="Renting">Renting</option>
                    <option value="Own Outright">Own Outright</option>
                    <option value="Living with family">
                      Living with family
                    </option>
                  </select>
                </div>
                {showMortgageQuestions && (
                  <div className="mortgage-questions">
                    <div className="input-group">
                      <label htmlFor="mortgageType">Mortgage type</label>
                      <select
                        id="mortgageType"
                        value={mortgageType}
                        onChange={handleMortgageTypeChange}
                      >
                        <option value="">Please select</option>
                        <option value="Repayment">Repayment</option>
                        <option value="Interest only">Interest only</option>
                        <option value="help to buy">Help to buy</option>
                      </select>
                    </div>
                    <div className="input-group">
                      <label htmlFor="outstandingMortgageAmount">
                        Outstanding Mortgage Amount
                      </label>
                      <input
                        id="outstandingMortgageAmount"
                        type="number"
                        placeholder="£"
                        value={outstandingMortgageAmount}
                        onChange={handleOutstandingMortgageAmountChange}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="outstandingMortgageTerm">
                        Outstanding Mortgage Term
                      </label>
                      <input
                        id="outstandingMortgageTerm"
                        type="number"
                        placeholder="years"
                        value={outstandingMortgageTerm}
                        onChange={handleOutstandingMortgageTermChange}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="monthlyMortgagePayment">
                        Monthly Mortgage Payment
                      </label>
                      <input
                        id="monthlyMortgagePayment"
                        type="number"
                        value={monthlyMortgagePayment}
                        onChange={handleMonthlyMortgagePaymentChange}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="lender">Lender</label>
                      <input
                        id="lender"
                        type="text"
                        value={lender}
                        onChange={handleLenderChange}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="interestRate">Interest Rate</label>
                      <input
                        id="interestRate"
                        type="number"
                        value={interestRate}
                        onChange={handleInterestRateChange}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="rateType">Rate type</label>
                      <select
                        id="rateType"
                        value={rateType}
                        onChange={handleRateTypeChange}
                      >
                        <option value="">Please select</option>
                        <option value="Fixed">Fixed</option>
                        <option value="Variable">Variable</option>
                      </select>
                    </div>
                    <div className="input-group">
                      <label htmlFor="rateDealEndDate">
                        When does rate-deal end?
                      </label>
                      <input
                        id="rateDealEndDate"
                        type="date"
                        value={rateDealEndDate}
                        onChange={handleRateDealEndDateChange}
                      />
                    </div>
                  </div>
                )}
                {showRentQuestions && (
                  <div className="rent-questions">
                    <div className="input-group">
                      <label htmlFor="monthlyRentalAmount">
                        Monthly Rental Amount
                      </label>
                      <input
                        id="monthlyRentalAmount"
                        type="number"
                        value={monthlyRentalAmount}
                        onChange={handleMonthlyRentalAmountChange}
                      />
                      <span className="pound-sign">£</span>
                    </div>
                  </div>
                )}
                {showLivingWithFamilyQuestions && (
                  <div className="living-with-family-questions">
                    <div className="input-group">
                      <label htmlFor="livingWithFamilyRentAmount">
                        Do you pay rent? How much?
                      </label>
                      <input
                        id="livingWithFamilyRentAmount"
                        type="number"
                        value={livingWithFamilyRentAmount}
                        onChange={handleLivingWithFamilyRentAmountChange}
                      />
                      <span className="pound-sign">£</span>
                    </div>
                  </div>
                )}
                <div className="input-group">
                  <label>Notes </label>
                  <ResizableTextarea
                    id="notesSection3"
                    value={notesSection3}
                    onChange={handleNotesSection3Change}
                  />
                </div>
              </div>
            </div>
            <div>
              {/* Section 4 */}
              <div className="form-section">
                <h2>Section 4 - Other Financial liabilities</h2>
                <div>
                  <div className="input-group">
                    <label>
                      Do you have any other mortgages (e.g. buy to let)?
                    </label>
                    <select
                      value={hasOtherMortgages}
                      onChange={handleHasOtherMortgagesChange}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Not Sure">Not Sure</option>
                    </select>
                  </div>
                  {hasOtherMortgages === "Yes" && (
                    <div>
                      {otherMortgages.map((mortgage, index) => (
                        <div key={mortgage.id}>
                          <h3>Mortgage {index + 1}</h3>
                          <div className="input-group">
                            <label>Mortgage Type</label>
                            <select
                              value={mortgage.mortgageType2}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "mortgageType2",
                                  e.target.value
                                )
                              }
                            >
                              <option value="Repayment">Repayment</option>
                              <option value="Interest Only">
                                Interest Only
                              </option>
                            </select>
                          </div>
                          <div className="input-group">
                            <label>Mortgage Amount Outstanding</label>
                            <input
                              type="number"
                              value={mortgage.mortgageAmountOutstanding}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "mortgageAmountOutstanding",
                                  e.target.value
                                )
                              }
                              placeholder="£"
                            />
                          </div>
                          <div className="input-group">
                            <label>Monthly Mortgage Term</label>
                            <input
                              type="number"
                              value={mortgage.monthlyMortgageTerm}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "monthlyMortgageTerm",
                                  e.target.value
                                )
                              }
                              placeholder="years"
                            />
                          </div>
                          <div className="input-group">
                            <label>Monthly Mortgage Payment</label>
                            <input
                              type="number"
                              value={mortgage.monthlyMortgagePayment2}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "monthlyMortgagePayment2",
                                  e.target.value
                                )
                              }
                              placeholder="£"
                            />
                          </div>
                          <div className="input-group">
                            <label>Lender</label>
                            <input
                              type="text"
                              value={mortgage.mortgageLender}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "mortgageLender",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="input-group">
                            <label>Interest rate</label>
                            <input
                              type="number"
                              value={mortgage.mortgageInterestRate}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "mortgageInterestRate",
                                  e.target.value
                                )
                              }
                              placeholder="%"
                            />
                          </div>
                          <div className="input-group">
                            <label>Rate type</label>
                            <select
                              value={mortgage.mortgageRateType}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "mortgageRateType",
                                  e.target.value
                                )
                              }
                            >
                              <option value="Fixed">Fixed</option>
                              <option value="Variable">Variable</option>
                            </select>
                          </div>
                          <div className="input-group">
                            <label>When does rate-deal end?</label>
                            <input
                              type="date"
                              value={mortgage.mortgageRateDealEnd}
                              onChange={(e) =>
                                handleMortgageChange(
                                  mortgage.id,
                                  "mortgageRateDealEnd",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          {/* Moved the Remove Mortgage button inside the map function */}
                          <button
                            type="button"
                            onClick={() => removeMortgage(mortgage.id)}
                          >
                            Remove Mortgage
                          </button>
                        </div>
                      ))}
                      <button type="button" onClick={addMortgage}>
                        Add Mortgage
                      </button>
                    </div>
                  )}
                  <div className="input-group">
                    <label>Do you have any other loans?</label>
                    <select
                      value={hasOtherLoans}
                      onChange={handleHasOtherLoansChange}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Not Sure">Not Sure</option>
                    </select>
                  </div>
                  {hasOtherLoans === "Yes" && (
                    <div>
                      {otherLoans.map((loan, index) => (
                        <div key={loan.id}>
                          <h3>Other Loan {index + 1}</h3>
                          <div className="input-group">
                            <label>Loan Type</label>
                            <input
                              type="text"
                              value={loan.loanType}
                              onChange={(e) =>
                                handleOtherLoanChange(
                                  loan.id,
                                  "loanType",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="input-group">
                            <label>Loan Amount Outstanding</label>
                            <input
                              type="number"
                              value={loan.loanAmountOutstanding}
                              onChange={(e) =>
                                handleOtherLoanChange(
                                  loan.id,
                                  "loanAmountOutstanding",
                                  e.target.value
                                )
                              }
                              placeholder="£"
                            />
                          </div>
                          <div className="input-group">
                            <label>Loan Term Outstanding</label>
                            <input
                              type="number"
                              value={loan.loanTermOutstanding}
                              onChange={(e) =>
                                handleOtherLoanChange(
                                  loan.id,
                                  "loanTermOutstanding",
                                  e.target.value
                                )
                              }
                              placeholder="years"
                            />
                          </div>
                          <div className="input-group">
                            <label>Monthly Loan Payment</label>
                            <input
                              type="number"
                              value={loan.monthlyLoanPayment}
                              onChange={(e) =>
                                handleOtherLoanChange(
                                  loan.id,
                                  "monthlyLoanPayment",
                                  e.target.value
                                )
                              }
                              placeholder="£"
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => removeOtherLoan(loan.id)}
                          >
                            Remove Loan
                          </button>
                        </div>
                      ))}
                      <button type="button" onClick={addOtherLoan}>
                        Add Loan
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Section 5 */}
            <div>
              <div className="form-section">
                <h2>Section 5 - Monthly Outgoing Calculations</h2>
                <div className="input-group">
                  <label htmlFor="monthlyHouseholdIncome">
                    Total Monthly Household Income
                  </label>
                  <input
                    type="text"
                    id="monthlyHouseholdIncome"
                    value={monthlyHouseholdIncome}
                    onChange={handleMonthlyHouseholdIncomeChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="monthlyMortgageRentLoans">
                    Monthly Mortgage/Rent Amount/Loans
                  </label>
                  <input
                    id="monthlyMortgageRentLoans"
                    type="number"
                    value={combinedMonthlyAmount}
                    placeholder="£"
                    readOnly
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="totalBillsPerMonth">
                    Approx Total Bills per Month
                  </label>
                  <input
                    type="text"
                    id="totalBillsPerMonth"
                    value={totalBillsPerMonth}
                    onChange={handleTotalBillsPerMonthChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="monthlyLivingCost">
                    Monthly Living Costs (e.g. Food, Fuel, Travel etc)
                  </label>
                  <input
                    type="text"
                    id="monthlyLivingCost"
                    value={monthlyLivingCost}
                    onChange={handleMonthlyLivingCostChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="approxMonthlyDisposableIncome">
                    Approx Monthly Disposable Income
                  </label>
                  <input
                    type="text"
                    id="approxMonthlyDisposableIncome"
                    value={approxMonthlyDisposableIncome}
                    disabled
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="howMuchInSavings">
                    How much do you have in savings?
                  </label>
                  <input 
                    type="text"
                    id="howMuchInSavings"
                    value={howMuchInSavings}
                    onChange={handleHowMuchInSavingsChange}
                  />
                  </div>
              </div>
            </div>
            {/* Section 6 */}
            <div>
              <div className="form-section">
                <h2>Section 6 - Existing Cover</h2>
                <div></div>
                <div className="input-group">
                  <label>
                    Do you have any existing Life Insurance, Critical Illness
                    Cover, or Income Protection?
                  </label>
                  <select
                    value={hasExistingCover}
                    onChange={handleHasExistingCoverChange}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Sure">Not Sure</option>
                  </select>
                </div>

                {hasExistingCover === "Yes" && (
                  <div>
                    {additionalCoverSections.map((section, index) => (
                      <div key={index}>
                        <h4>Additional Cover {index + 1}</h4>

                        <div className="input-group">
                          <label>Policy type</label>
                          <input
                            type="text"
                            value={section.policyType}
                            onChange={(event) =>
                              handlePolicyTypeChange(event, index)
                            }
                          />
                        </div>
                        <div className="input-group">
                          <label>Person Covered</label>
                          <input
                            type="text"
                            value={section.personCovered}
                            onChange={(event) =>
                              handlePersonCoveredChange(event, index)
                            }
                          />
                        </div>

                        <div className="input-group">
                          <label>Cover Amount</label>
                          <input
                            type="number"
                            value={section.coverAmount}
                            onChange={(event) =>
                              handleCoverAmountChange(event, index)
                            }
                            placeholder="£"
                          />
                        </div>
                        <div className="input-group">
                          <label>Cover term</label>
                          <input
                            type="number"
                            value={section.coverTerm}
                            onChange={(event) =>
                              handleCoverTermChange(event, index)
                            }
                            placeholder="years"
                          />
                        </div>
                        <div className="input-group">
                          <label>Insurer</label>
                          <input
                            type="text"
                            value={section.insurer}
                            onChange={(event) =>
                              handleInsurerChange(event, index)
                            }
                          />
                        </div>
                        <div className="input-group">
                          <label>When set up</label>
                          <input
                            type="date"
                            value={section.whenSetUp}
                            onChange={(event) =>
                              handleWhenSetUpChange(event, index)
                            }
                          />
                        </div>
                        <div className="input-group">
                          <label>Premium</label>
                          <input
                            type="number"
                            value={section.premium}
                            onChange={(event) =>
                              handlePremiumChange(event, index)
                            }
                            placeholder="£"
                          />
                        </div>
                        {index === additionalCoverSections.length - 1 && (
                          <button onClick={handleAddSection}>
                            Add another
                          </button>
                        )}

                        {index !== 0 && (
                          <button onClick={() => handleRemoveSection(index)}>
                            Remove
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="form-section-details">
                <ResizableTextarea
                  value={section6details}
                  onChange={handleSection6DetailsChange}
                  placeholder="Enter info here..."
                />
              </div>
            </div>
            {/* Section 7 */}
            <div>
              <div className="form-section">
                <h2>Section 7 - Health Questions</h2>
                <div
                  className={`client-info-wrapper ${
                    lookingToCoverPartner === "No" ? "single-client" : ""
                  }`}
                >
                  <div
                    className={`client ${
                      lookingToCoverPartner === "No" ? "full-width-client" : ""
                    }`}
                  >
                    <h3>Client 1</h3>
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Have you been diagnosed with COVID-19, or suffered
                        symptoms, or had to self-isolate in last 30 days?
                      </label>
                      <select
                        value={covidStatus}
                        onChange={handleCovidStatusChange}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    {covidStatus === "Yes" && (
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <ResizableTextarea
                          value={covidStatusDetails}
                          onChange={handleCovidStatusDetailsChange}
                          placeholder="Enter info here..."
                        />
                      </div>
                    )}
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Ever had, cancer, strokes, heart conditions or diabetes?
                      </label>
                      <select
                        value={seriousConditions}
                        onChange={handleSeriousConditionsChange}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    {seriousConditions === "Yes" && (
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <ResizableTextarea
                          value={seriousConditionsDetails}
                          onChange={handleSeriousConditionsDetailsChange}
                          placeholder="Enter info here..."
                        />
                      </div>
                    )}
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Ever had raised blood pressure, or raised cholesterol?
                      </label>
                      <select
                        value={otherConditions}
                        onChange={handleOtherConditionsChange}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    {otherConditions === "Yes" && (
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <ResizableTextarea
                          value={otherConditionsDetails}
                          onChange={handleOtherConditionsDetailsChange}
                          placeholder="Enter info here..."
                        />
                      </div>
                    )}
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Ever had anaemia, blood clots, blood conditions, thyroid
                        problems, or liver or kidney problems?
                      </label>
                      <select
                        value={physicalConditions}
                        onChange={handlePhysicalConditionsChange}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    {physicalConditions === "Yes" && (
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <ResizableTextarea
                          value={physicalConditionsDetails}
                          onChange={handlePhysicalConditionsDetailsChange}
                          placeholder="Enter info here..."
                        />
                      </div>
                    )}
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Ever had Crohn’, IBS, or other digestive issues?
                      </label>
                      <select
                        value={mentalHealth}
                        onChange={handleMentalHealthChange}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    {mentalHealth === "Yes" && (
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <ResizableTextarea
                          value={mentalHealthDetails}
                          onChange={handleMentalHealthDetailsChange}
                          placeholder="Enter info here..."
                        />
                      </div>
                    )}
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Ever had gout, arthritis, asthma, or sleep apnoea?
                      </label>
                      <select
                        value={medication}
                        onChange={handleMedicationChange}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    {medication === "Yes" && (
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <ResizableTextarea
                          value={medicationDetails}
                          onChange={handleMedicationDetailsChange}
                          placeholder="Enter info here..."
                        />
                      </div>
                    )}
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Ever had depression, stress, anxiety or other mental
                        health issue (regardless of whether you saw a doctor)
                      </label>
                      <select
                        value={familyHistory}
                        onChange={handleFamilyHistoryChange}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    {familyHistory === "Yes" && (
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <ResizableTextarea
                          value={familyHistoryDetails}
                          onChange={handleFamilyHistoryDetailsChange}
                          placeholder="Enter info here..."
                        />
                      </div>
                    )}
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Any outstanding medical tests or investigations? (e.g.
                        doctor visits, referrals, consultations, operations)
                      </label>
                      <select value={hobbies} onChange={handleHobbiesChange}>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    {hobbies === "Yes" && (
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <ResizableTextarea
                          value={hobbiesDetails}
                          onChange={handleHobbiesDetailsChange}
                          placeholder="Enter info here..."
                        />
                      </div>
                    )}
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Apart from what you’ve already mentioned, any other
                        health issues, or anything else that requires medication
                        or treatment?
                      </label>
                      <select
                        value={travelHistory}
                        onChange={handleTravelHistoryChange}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    {travelHistory === "Yes" && (
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <ResizableTextarea
                          value={travelHistoryDetails}
                          onChange={handleTravelHistoryDetailsChange}
                          placeholder="Enter info here..."
                        />
                      </div>
                    )}
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Mum, Dad, Brothers & Sisters before age 65. Had any
                        heart attacks, angina, cardiomyopathy, cancer, multiple
                        sclerosis, motor neurone disease etc?
                      </label>
                      <select value={bmi} onChange={handleBmiChange}>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    {bmi === "Yes" && (
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <ResizableTextarea
                          value={bmiDetails}
                          onChange={handleBmiDetailsChange}
                          placeholder="Enter info here..."
                        />
                      </div>
                    )}
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Have you lived worked or travelled outside of the EU or
                        USA in the last 5 years? (not including holidays up to
                        30 days)?
                      </label>
                      <select
                        value={travelPast5Years}
                        onChange={handleTravelPast5YearsChange}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    {travelPast5Years === "Yes" && (
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <ResizableTextarea
                          value={travelPast5YearsDetails}
                          onChange={handleTravelPast5YearsDetailsChange}
                          placeholder="Enter info here..."
                        />
                      </div>
                    )}
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Ride a motorbike on the road? Do any other dangerous
                        hobbies such as motorsport, diving, private flying etc?
                      </label>
                      <select
                        value={rideBikes}
                        onChange={handleRideBikesChange}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    {rideBikes === "Yes" && (
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <ResizableTextarea
                          value={rideBikesDetails}
                          onChange={handleRideBikesDetailsChange}
                          placeholder="Enter info here..."
                        />
                      </div>
                    )}
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Current height, weight and trouser/dress size?
                      </label>
                      <div style={{ width: "350px" }}>
                        <ResizableTextarea
                          value={currentSize}
                          onChange={handleCurrentSizeChange}
                        />
                      </div>
                    </div>
                    <div
                      className={`client-row ${
                        lookingToCoverPartner === "No"
                          ? "full-width-client"
                          : ""
                      }`}
                    >
                      <label>
                        Is the client potentially vulnerable (do not ask this
                        question)
                      </label>
                      <select
                        value={potentiallyVulnerable}
                        onChange={handlePotentiallyVulnerableChange}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    {potentiallyVulnerable === "Yes" && (
                      <div
                        className={`client-row ${
                          lookingToCoverPartner === "No"
                            ? "full-width-client"
                            : ""
                        }`}
                      >
                        <ResizableTextarea
                          value={potentiallyVulnerableDetails}
                          onChange={handlePotentiallyVulnerableDetailsChange}
                          placeholder="Enter info here..."
                        />
                      </div>
                    )}
                  </div>
                  {lookingToCoverPartner === "Yes" && (
                    <div className="client1">
                      <h3>Client 2</h3>
                      <div className="client-row1">
                        <label>
                          Have you been diagnosed with COVID-19, or suffered
                          symptoms, or had to self-isolate in last 30 days?
                        </label>
                        <select
                          value={partnerCovidStatus}
                          onChange={handlePartnerCovidStatusChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {partnerCovidStatus === "Yes" && (
                        <div className="client-row1">
                          <ResizableTextarea
                            value={partnerCovidStatusDetails}
                            onChange={handlePartnerCovidStatusDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div className="client-row1">
                        <label>
                          Ever had, cancer, strokes, heart conditions or
                          diabetes?
                        </label>
                        <select
                          value={partnerSeriousConditions}
                          onChange={handlePartnerSeriousConditionsChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {partnerSeriousConditions === "Yes" && (
                        <div className="client-row1">
                          <ResizableTextarea
                            value={partnerSeriousConditionsDetails}
                            onChange={
                              handlePartnerSeriousConditionsDetailsChange
                            }
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div className="client-row1">
                        <label>
                          {" "}
                          Ever had raised blood pressure, or raised cholesterol?{" "}
                        </label>
                        <select
                          value={partnerOtherConditions}
                          onChange={handlePartnerOtherConditionsChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {partnerOtherConditions === "Yes" && (
                        <div className="client-row1">
                          <ResizableTextarea
                            value={partnerOtherConditionsDetails}
                            onChange={handlePartnerOtherConditionsDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div className="client-row1">
                        <label>
                          {" "}
                          Ever had anaemia, blood clots, blood conditions,
                          thyroid problems, or liver or kidney problems?
                        </label>
                        <select
                          value={partnerPhysicalConditions}
                          onChange={handlePartnerPhysicalConditionsChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {partnerPhysicalConditions === "Yes" && (
                        <div className="client-row1">
                          <ResizableTextarea
                            value={partnerPhysicalConditionsDetails}
                            onChange={
                              handlePartnerPhysicalConditionsDetailsChange
                            }
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div className="client-row1">
                        <label>
                          Ever had Crohn’, IBS, or other digestive issues?{" "}
                        </label>
                        <select
                          value={partnerMentalHealth}
                          onChange={handlePartnerMentalHealthChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {partnerMentalHealth === "Yes" && (
                        <div className="client-row1">
                          <ResizableTextarea
                            value={partnerMentalHealthDetails}
                            onChange={handlePartnerMentalHealthDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div className="client-row1">
                        <label>
                          Ever had gout, arthritis, asthma, or sleep apnoea?
                        </label>
                        <select
                          value={partnerMedication}
                          onChange={handlePartnerMedicationChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {partnerMedication === "Yes" && (
                        <div className="client-row1">
                          <ResizableTextarea
                            value={partnerMedicationDetails}
                            onChange={handlePartnerMedicationDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}

                      <div className="client-row1">
                        <label>
                          Ever had depression, stress, anxiety or other mental
                          health issue (regardless of whether you saw a doctor)
                        </label>
                        <select
                          value={partnerFamilyHistory}
                          onChange={handlePartnerFamilyHistoryChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {partnerFamilyHistory === "Yes" && (
                        <div className="client-row1">
                          <ResizableTextarea
                            value={partnerFamilyHistoryDetails}
                            onChange={handlePartnerFamilyHistoryDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}

                      <div className="client-row1">
                        <label>
                          {" "}
                          Any outstanding medical tests or investigations? (e.g.
                          doctor visits, referrals, consultations, operations)
                        </label>
                        <select
                          value={partnerHobbies}
                          onChange={handlePartnerHobbiesChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {partnerHobbies === "Yes" && (
                        <div className="client-row1">
                          <ResizableTextarea
                            value={partnerHobbiesDetails}
                            onChange={handlePartnerHobbiesDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div className="client-row1">
                        <label>
                          Apart from what you’ve already mentioned, any other
                          health issues, or anything else that requires
                          medication or treatment?{" "}
                        </label>
                        <select
                          value={partnerTravelHistory}
                          onChange={handlePartnerTravelHistoryChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {partnerTravelHistory === "Yes" && (
                        <div className="client-row1">
                          <ResizableTextarea
                            value={partnerTravelHistoryDetails}
                            onChange={handlePartnerTravelHistoryDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div className="client-row1">
                        <label>
                          Mum, Dad, Brothers & Sisters before age 65. Had any
                          heart attacks, angina, cardiomyopathy, cancer,
                          multiple sclerosis, motor neurone disease etc?{" "}
                        </label>
                        <select
                          value={partnerBmi}
                          onChange={handlePartnerBmiChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {partnerBmi === "Yes" && (
                        <div className="client-row1">
                          <ResizableTextarea
                            value={partnerBmiDetails}
                            onChange={handlePartnerBmiDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div className="client-row1">
                        <label>
                          Have you lived worked or travelled outside of the EU
                          or USA in the last 5 years? (not including holidays up
                          to 30 days)?
                        </label>
                        <select
                          value={partnerTravelPast5Years}
                          onChange={handlePartnerTravelPast5YearsChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {partnerTravelPast5Years === "Yes" && (
                        <div className="client-row1">
                          <ResizableTextarea
                            value={partnerTravelPast5YearsDetails}
                            onChange={
                              handlePartnerTravelPast5YearsDetailsChange
                            }
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div className="client-row1">
                        <label>
                          {" "}
                          Ride a motorbike on the road? Do any other dangerous
                          hobbies such as motorsport, diving, private flying
                          etc?
                        </label>
                        <select
                          value={partnerRideBikes}
                          onChange={handlePartnerRideBikesChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {partnerRideBikes === "Yes" && (
                        <div className="client-row1">
                          <ResizableTextarea
                            value={partnerRideBikesDetails}
                            onChange={handlePartnerRideBikesDetailsChange}
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                      <div className="client-row1">
                        <label>
                          Current height, weight and trouser/dress size?
                        </label>
                        <div style={{ width: "350px" }}>
                          <ResizableTextarea
                            value={partnerCurrentSize}
                            onChange={handlePartnerCurrentSizeChange}
                          />
                        </div>
                      </div>
                      <div className="client-row1">
                        <label>
                          Is the client potentially vulnerable (do not ask this
                          question)
                        </label>
                        <select
                          value={partnerPotentiallyVulnerable}
                          onChange={handlePartnerPotentiallyVulnerableChange}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                      {partnerPotentiallyVulnerable === "Yes" && (
                        <div className="client-row1">
                          <ResizableTextarea
                            value={partnerPotentiallyVulnerableDetails}
                            onChange={
                              handlePartnerPotentiallyVulnerableDetailsChange
                            }
                            placeholder="Enter info here..."
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Section 7 Details */}
            <div>
              <div className="form-section-details">
                <h2>Notes & Quotes</h2>
                <div className="section-details">
                  <ResizableTextarea
                    style={{ width: "100%" }}
                    value={section7details}
                    onChange={handleSection7DetailsChange}
                    placeholder="Enter info here..."
                  />
                </div>
              </div>
            </div>
            <button type="submit">Submit</button>
          </div>
        </form>
        {/* <button type="button1" onClick={handleClick}>Generate PDF</button> */}
        <button type="button1" onClick={() => window.print()}>
          Browser PDF
        </button>
        <button type="button1" onClick={createAndDownloadPDF}>
          PDF
        </button>
        {modalMessage && <Popup message={modalMessage} onClose={closeModal} />}
        {showConfirmPopup && (
          <Popup
            type="confirm"
            message="Are you sure you want to save the FactFinder?"
            onConfirm={handleSave}
            onCancel={handleCancel}
          />
        )}
      </div>

      {isLoading && <div className="loading-screen">Generating PDF...</div>}
    </>
  );
}

export default FactFinder;
